import {
    ADD_CLUB_ADMIN_USER_START,
    ADD_CLUB_ADMIN_USER_SUCCESS,
    ADD_CLUB_ADMIN_USER_FAIL,
    RELEASE_CHANNEL_DETAILS_START,
    RELEASE_CHANNEL_DETAILS_SUCCESS,
    RELEASE_CHANNEL_DETAILS_FAIL,

} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
    isAddUserSuccess: false,
    response: null,
    errorMsg: null
};

export default (state = INITIAL_STATE, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
        case ADD_CLUB_ADMIN_USER_START:
            currentState.isAddUserSuccess = false;
            break;
        case ADD_CLUB_ADMIN_USER_SUCCESS:
            currentState.isAddUserSuccess = true;
            currentState.response = action.payload;
            break;
        case ADD_CLUB_ADMIN_USER_FAIL:
            currentState.isAddUserSuccess = false;
            currentState.errorMsg = action.payload;
            break;
        case RELEASE_CHANNEL_DETAILS_START:
            currentState.releaseChannelResponse = null;
            currentState.releaseChannelResponseError = false;
            currentState.releaseChannelResponseErrorMsg = null;
            break;
        case RELEASE_CHANNEL_DETAILS_SUCCESS:
            currentState.releaseChannelResponse = action.payload;
            currentState.releaseChannelResponseError = false;
            currentState.releaseChannelResponseErrorMsg = null;
            break;
        case RELEASE_CHANNEL_DETAILS_FAIL:
            currentState.releaseChannelResponse = [];
            currentState.releaseChannelResponseError = true;
            currentState.releaseChannelResponseErrorMsg = action.payload;
            break;
        default:
            return currentState;
    }
    return currentState;
};
