import {
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  SET_PASSWORD_SUCCESS,
  SIGNOUT,
  UNAUTHORIZED_USER,
  FORGOT_PASSWORD_SUCCESS,
  ADD_MEMBER_REGISTRATION_START,
  ADD_MEMBER_REGISTRATION_SUCCESS,
  ADD_MEMBER_REGISTRATION_FAIL,
  ACTIVE_MEMBER_REGISTRATION_START,
  ACTIVE_MEMBER_REGISTRATION_SUCCESS,
  ACTIVE_MEMBER_REGISTRATION_FAIL,
  CLUB_MEMBER_ASSOCIATIONS_START,
  CLUB_MEMBER_ASSOCIATIONS_SUCCESS,
  CLUB_MEMBER_ASSOCIATIONS_FAIL,
  EMAIL_VERIFICATION_START,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_FAIL,
  OTP_VERIFICATION_START,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_FAIL,
} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
  isLoggedIn: false,
  logged_in_user: null,
  loggin_error: null,
  memberRegisterSuccess:null,
  memberRegisterError:null,
  memberActiveRes:null,
  memberAssociationsRes:null,
  memberAssociationsError:null

};

export default (state = INITIAL_STATE, action) => {
  let currentState = cloneDeep(state);
  switch (action.type) {
    case USER_LOGIN_START:
      currentState.isLoggedIn = false;
      currentState.logged_in_user = {};
      break;
    case USER_LOGIN_SUCCESS:
      currentState.isLoggedIn = true;
      currentState.logged_in_user = action.payload.logged_in_user;
      break;
    case USER_LOGIN_FAIL:
      currentState.isLoggedIn = false;
      currentState.logged_in_user = {};
      console.log("Fail=" + JSON.stringify(action.payload))
      currentState.loggin_error = action.payload;
      break;
    case SET_PASSWORD_SUCCESS:
      currentState.isLoggedIn = false;
      currentState.logged_in_user = {};
      break;
    case SIGNOUT:
    case FORGOT_PASSWORD_SUCCESS:
      currentState.isLoggedIn = false;
      currentState.logged_in_user = {};
      break;
    case UNAUTHORIZED_USER:
      currentState.isLoggedIn = false;
      currentState.logged_in_user = {};
      break;
    case ADD_MEMBER_REGISTRATION_START:
      currentState.memberRegisterError = null;
      currentState.memberRegisterSuccess = null;
      break;  
    case ADD_MEMBER_REGISTRATION_SUCCESS:
      currentState.memberRegisterError = null;
      currentState.memberRegisterSuccess = action.payload;
      break;  
    case ADD_MEMBER_REGISTRATION_FAIL:
    currentState.memberRegisterError = action.payload;
    currentState.memberRegisterSuccess = null;
    break; 
    case ACTIVE_MEMBER_REGISTRATION_SUCCESS:
    currentState.memberActiveRes = action.payload;
    break;  
    case ACTIVE_MEMBER_REGISTRATION_FAIL:
    currentState.memberActiveRes = action.payload;
    break;
    case CLUB_MEMBER_ASSOCIATIONS_START:
      currentState.memberAssociationsRes = null;
      currentState.memberAssociationsError = null;
      break;  
    case CLUB_MEMBER_ASSOCIATIONS_SUCCESS:
      currentState.memberAssociationsError = null;
      currentState.memberAssociationsRes = action.payload;
      break;  
    case CLUB_MEMBER_ASSOCIATIONS_FAIL:
    currentState.memberAssociationsError = action.payload;
    currentState.memberAssociationsRes = null;
    break; 

    case EMAIL_VERIFICATION_START:
      currentState.emailVeirficationError = null;
      currentState.emailVeirficationSuccess = null;
      break;
    case EMAIL_VERIFICATION_SUCCESS:
      currentState.emailVeirficationError = null;
      currentState.emailVeirficationSuccess = action.payload;
      break;
    case EMAIL_VERIFICATION_FAIL:
      currentState.emailVeirficationError = action.payload;
      currentState.emailVeirficationSuccess = null;
      break;
    case OTP_VERIFICATION_START:
      currentState.otpVeirficationError = null;
      currentState.otpVeirficationSuccess = null;
      break;
    case OTP_VERIFICATION_SUCCESS:
      currentState.otpVeirficationError = null;
      currentState.otpVeirficationSuccess = action.payload;
      break;
    case OTP_VERIFICATION_FAIL:
      currentState.otpVeirficationError = action.payload;
      currentState.otpVeirficationSuccess = null;
      break; 

    default:
      return currentState;
  }
  return currentState;
};
