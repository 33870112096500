import {
    CLUB_LIST_START,
    CLUB_LIST_SUCCESS,
    CLUB_LIST_FAIL,
    GET_CLUB_SUBSCRIPTION_LIST_START,
    GET_CLUB_SUBSCRIPTION_LIST_SUCCESS,
    GET_CLUB_SUBSCRIPTION_LIST_FAIL,
    GET_MEMBER_SUBSCRIPTION_LIST_START,
    GET_MEMBER_SUBSCRIPTION_LIST_SUCCESS,
    GET_MEMBER_SUBSCRIPTION_LIST_FAIL,
    GET_PLATFORM_SUBSCRIPTION_LIST_START,
    GET_PLATFORM_SUBSCRIPTION_LIST_SUCCESS,
    GET_PLATFORM_SUBSCRIPTION_LIST_FAIL
} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
    isClubListSuccess: false,
    response: null,
    clubListErrorMsg: null,
    subscriptionClubList: [],
    subscriptionMemberList: [],
    subscriptionPlatformList: [],
};

export default (state = INITIAL_STATE, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
        case CLUB_LIST_START:
            currentState.isClubListSuccess = false;
            break;
        case CLUB_LIST_SUCCESS:
            currentState.isClubListSuccess = true;
            currentState.response = action.payload;
            break;
        case CLUB_LIST_FAIL:
            currentState.isClubListSuccess = false;
            currentState.clubListErrorMsg = action.payload;
            break;
        case GET_CLUB_SUBSCRIPTION_LIST_START:
            currentState.isClubListSuccess = false;
            currentState.subscriptionClubList = [];
            break;
        case GET_CLUB_SUBSCRIPTION_LIST_SUCCESS:
            currentState.isClubListSuccess = true;
            currentState.subscriptionClubList = action.payload;
            break;
        case GET_CLUB_SUBSCRIPTION_LIST_FAIL:
            currentState.isClubListSuccess = false;
            currentState.clubListErrorMsg = action.payload;
            break;
        case GET_MEMBER_SUBSCRIPTION_LIST_START:
            currentState.isClubListSuccess = false;
            currentState.subscriptionMemberList = [];
            break;
        case GET_MEMBER_SUBSCRIPTION_LIST_SUCCESS:
            currentState.isClubListSuccess = true;
            currentState.subscriptionMemberList = action.payload;
            break;
        case GET_MEMBER_SUBSCRIPTION_LIST_FAIL:
            currentState.isClubListSuccess = false;
            currentState.clubListErrorMsg = action.payload;
            break;
        case GET_PLATFORM_SUBSCRIPTION_LIST_START:
            currentState.isClubListSuccess = false;
            currentState.subscriptionPlatformList = [];
            break;
        case GET_PLATFORM_SUBSCRIPTION_LIST_SUCCESS:
            currentState.isClubListSuccess = true;
            currentState.subscriptionPlatformList = action.payload;
            break;
        case GET_PLATFORM_SUBSCRIPTION_LIST_FAIL:
            currentState.isClubListSuccess = false;
            currentState.clubListErrorMsg = action.payload;
            break;
        default:
            return currentState;
    }
    return currentState;
};
