import JSEncrypt from "jsencrypt";
import constant from '../../constants/constant'
import moment from 'moment-timezone'
import config from "../../constants/config";
import API from "../utils/api";
import { toast } from 'react-toastify';

class UtilServiceData {
  /**
   * encrypt the provided input text
   * @param textToConvert value to encrypt
   */
  encryptText(textToConvert) {
    // Encrypt with the public key...
    var encrypt = new JSEncrypt();
    encrypt.setKey(
      "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDlOJu6TyygqxfWT7eLtGDwajtNFOb9I5XRb6khyfD1Yt3YiCgQWMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76xFxdU6jE0NQ+Z+zEdhUTooNRaY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4gwQco1KRMDSmXSMkDwIDAQAB"
    );
    return encrypt.encrypt(textToConvert);
    //return CryptoJS.AES.encrypt(textToConvert, this.key).toString();
  }

  /**
   * decrypt the provided input text
   * @param textToConvert value to decrypt
   */
  decryptText(textToConvert) {
    var decrypt = new JSEncrypt();
    decrypt.setPrivateKey(
      "MIICXQIBAAKBgQDlOJu6TyygqxfWT7eLtGDwajtNFOb9I5XRb6khyfD1Yt3YiCgQWMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76xFxdU6jE0NQ+Z+zEdhUTooNRaY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4gwQco1KRMDSmXSMkDwIDAQABAoGAfY9LpnuWK5Bs50UVep5c93SJdUi82u7yMx4iHFMc/Z2hfenfYEzu+57fI4fvxTQ//5DbzRR/XKb8ulNv6+CHyPF31xk7YOBfkGI8qjLoq06V+FyBfDSwL8KbLyeHm7KUZnLNQbk8yGLzB3iYKkRHlmUanQGaNMIJziWOkN+N9dECQQD0ONYRNZeuM8zd8XJTSdcIX4a3gy3GGCJxOzv16XHxD03GW6UNLmfPwenKu+cdrQeaqEixrCejXdAFz/7+BSMpAkEA8EaSOeP5Xr3ZrbiKzi6TGMwHMvC7HdJxaBJbVRfApFrE0/mPwmP5rN7QwjrMY+0+AbXcm8mRQyQ1+IGEembsdwJBAN6az8Rv7QnD/YBvi52POIlRSSIMV7SwWvSK4WSMnGb1ZBbhgdg57DXaspcwHsFV7hByQ5BvMtIduHcT14ECfcECQATeaTgjFnqE/lQ22Rk0eGaYO80cc643BXVGafNfd9fcvwBMnk0iGX0XRsOozVt5AzilpsLBYuApa66NcVHJpCECQQDTjI2AQhFc1yRnCU/YgDnSpJVm1nASoRUnU8Jfm3Ozuku7JUXcVpt08DFSceCEX9unCuMcT72rAQlLpdZir876"
    );
    return decrypt.decrypt(textToConvert);
  }


  /* The below function hide the sidebar on mobile. 
  */
  hideSideBar() {
    let activeClass = document.querySelector('.tab-menu-bar');
    let sidebarClass = document.querySelector('.sidebar');
    let calssArr = activeClass.classList.contains("active");
    let sidebarArr = sidebarClass.classList.contains("active");

    if (calssArr && sidebarArr) {
      activeClass.classList.remove('active');
      sidebarClass.classList.remove('active');
    } else {
      activeClass.classList.add('active');
      sidebarClass.classList.add('active');
    }
  }

  isStrongPassword(passwordValue) {
    var isStrongPassword = false;
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(passwordValue)) {
      isStrongPassword = true;
    }
    return isStrongPassword;
  }

  isShowView(isShow) {
    if (isShow) {
      return "d-inline-block";
    } else return "d-none";
  }

  getUserDetail(keys) {
    //get data from login data 
    const userProfile = JSON.parse(localStorage.getItem(constant.CURRENT_USER))
    if (userProfile) {
      return userProfile[keys]
    } else {
      return
    }

  }
  /* The below function set data in to localStorage or sessionStorage, only pass some values
  *  type = sessionStorage/localStorage
  *  keyName = write the keyName which you want to define
  *  value = data which you are getting from api
  */
  setInfoInStorage(type, keyName, value) {
    //localStorage.removeItem('logout-event'); 
    if (value == null) {
      return;
    }
    // Store the details in session/local storage
    const storage = type === 'session' ? sessionStorage : localStorage;
    if (storage.getItem(keyName)) {
      storage.removeItem(keyName);
    }
    storage.setItem(keyName, JSON.stringify(value));
  }

  //this is used to update the existing local storage
  updateLocalStorage(localStorageItemKey, objectKey, objectValue) {
    var userProfile = JSON.parse(localStorage.getItem(localStorageItemKey))
    userProfile[objectKey] = objectValue
    if (localStorage.getItem(localStorageItemKey)) {
      localStorage.setItem(localStorageItemKey, JSON.stringify(userProfile))
    }
  }

  /* The below function Get data in to localStorage or sessionStorage, only pass some values
  *  type = sessionStorage/localStorage
  *  keyName = write the keyName which you want to define
  */
  getInfoFromStorage(type, keyName) {
    const storage = type === 'session' ? sessionStorage : localStorage;
    if (!storage.getItem(keyName)) {
      return null;
    }

    return JSON.parse(storage.getItem(keyName));
  }

  /* The below function remove data in to localStorage or sessionStorage, only pass some values
  *  type = sessionStorage/localStorage
  *  keyName = write the keyName which already given in storage.
  */
  removeFromStorage(type, keyName) {
    const storage = type === 'session' ? sessionStorage : localStorage;
    storage.removeItem(keyName);
  }

  /* The below function convert date into timestamp.
     */
  getDateIntoTimeStampInMillSec(date) {
    return date.getTime()
  }

  getDateFromMillsec(dateIntoMillSec) {
    //var date = '1475235770601';
    var d = new Date(parseInt(dateIntoMillSec, 10));
    var ds = d.toString('MM/dd/yy HH:mm:ss');
    return ds
  }

  getDateFromMillSecMMDDYYYY(dateIntoMillSec) {
    //var date = '1475235770601';
    var d = new Date(parseInt(dateIntoMillSec, 10));
    var ds = d.toString('DD-MM-YYYY');
    return ds
  }

  isValidImageExtension(fileName) {
    var imageExtensionArray = ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG']
    const regex = new RegExp('[^.]+$');
    var extension = fileName.match(regex);
    const value = imageExtensionArray.some(item => `${extension}` === item)
    return value
  }

  alphabetsValidation(e) {
    const re = /^[a-zA-Z ]*$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  //We get countryId when we pass country array data and selected country
  getCountryId(selectedCountry) {
    let countryArray = JSON.parse(this.getInfoFromStorage('localStorage', constant.COUNTRY_LIST));
    if (selectedCountry !== undefined) {
      var matchValue = countryArray.countries.filter(function (countries) {
        return countries.countryName === selectedCountry
      })
      return matchValue[0]?.countryId //return countryId like IN,US
    } else
      return ""

  }

  //We get countryName when we pass country array data and selected countryId 
  getCountryName(selectedCountryId) {
    let countryArray = JSON.parse(this.getInfoFromStorage('localStorage', constant.COUNTRY_LIST));
    if (selectedCountryId !== "") {
      if (countryArray !== null && selectedCountryId !== null) {
        var matchValue = countryArray.countries.filter(function (countries) {
          return countries.countryId === selectedCountryId
        })
        return (matchValue && matchValue.length > 0 ? matchValue[0].countryName : '') //return countryName like India
      } else {
        return selectedCountryId
      }
    } else
      return ''

  }

  //Restrict number and special char when user paste on input box
  onPastRestriction(e) {
    const str = e.clipboardData.getData('Text');
    const regres = /^[a-zA-Z ]*$/;
    const newStr = str.replace(regres, '');
    if (str === newStr) {
      toast.error("Alphanumeric & special characters are not allowed.");
      e.preventDefault()
      toast.error("Alphanumeric & special characters are not allowed.");
    }
  }

  validateEmail(email) {
    email = email.trim();
    // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var re = /^[a-zA-Z0-9\+\.\_\%\-\+]{1,256}\@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(\.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+$/
    return re.test(String(email).toLowerCase());
  }

  locaStorageClear() {
    let isRememberPass = this.getInfoFromStorage('localStorage', constant.IS_PASSWORD_REMEMBER)
    if (isRememberPass) {
      this.removeFromStorage('localStorage', constant.CURRENT_USER)
      this.removeFromStorage('localStorage', constant.AUTH_TOKEN)
      this.removeFromStorage('localStorage', constant.CLUBS)
    } else
      localStorage.clear()


  }

  getCurrentRouteName() {
    return window.location.pathname
  }

  getStatusType(statusId) {
    const textType = statusId === 1 ? 'text-success' :
      statusId === 2 ? 'text-warning' :
        statusId === 3 ? 'text-danger' :
          statusId === 6 ? 'text-primary' :
            statusId === 7 ? 'text-info' :
              statusId === 9 ? 'text-warning' :
                statusId === 10 ? 'text-primary' :
                 statusId ? 'text-danger' : ""

    const textName = statusId === 1 ? constant.ACTIVE :
      statusId === 2 ? constant.SUSPENDED :
        statusId === 3 ? constant.EXPIRED :
          statusId === 4 ? constant.PASSWORD_NOT_SET :
            statusId === 6 ? constant.COMPLETED :
              statusId === 7 ? constant.LIVE :
                statusId === 9 ? constant.EXPIRING_SOON :
                  statusId === 10 ? constant.NOT_STARTED : ""

    return { "statusType": textType, "statusName": textName }

  }

  /*Below function return Date into April,05,2019 this formate we need to
  pass only date into millSec with in function parameter*/
  getDateInFormattedForm(dateInMillSec) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[new Date(dateInMillSec).getMonth()] + ' ' + new Date(dateInMillSec).getDate() + ', ' + new Date(dateInMillSec).getFullYear()
  }

  /*Below function return Date into April,05,2019 this formate we need to
  pass only date into millSec with in function parameter*/
  getTimeForm(dateInMillSec) {
    return new Date(dateInMillSec).getHours() + ':' + new Date(dateInMillSec).getMinutes() + ':' + new Date(dateInMillSec).getSeconds()
  }

  isPabsaAdmin() {
    var roleId = this.getUserDetail(constant.ROLE_ID)
    if (roleId === constant.PABSA_SUPER_ADMIN_VALUE || roleId === constant.PABSA_ADMIN_VALUE)
      return true
    else
      return false
  }

  isClubSuperAdmin() {
    var roleId = this.getUserDetail(constant.ROLE_ID)
    if (roleId === constant.CLUB_SUPER_ADMIN_VALUE || roleId === constant.CLUB_ADMIN)
      return true
    else
      return false
  }

  /*
  below function is use to pass date into this formate MM/DD/YYYY in string and get date into millsec 
  */
  getDateIntoMillSecfromStringDateMMDDYYY(date) {
    var split = date.split("/")
    if (split.length > 2) {
      if (split[2].length > 3) {
        //console.log("DateNow=" + date)
        var from = moment(date).format('MM/DD/YYYY')
        var numbers = from.match(/\d+/g);
        if (numbers !== null) {
          if (numbers[2] !== null) {
            var date = new Date(numbers[2], numbers[0] - 1, numbers[1]);
            //console.log("SearchDate=" + date + "dateMillsec=" + this.getDateIntoTimeStampInMillSec(date))
            return this.getDateIntoTimeStampInMillSec(date)
          } else
            return null
        }

        // return { isVaildDate: true, dateInMillSec: this.getDateIntoTimeStampInMillSec(date) }
      } else
        return null
      //return { isVaildDate: false, dateInMillSec: null }

    }
  }

  getFirstIdxClubsData = (data,req) => {
    let clubsListArr = this.getInfoFromStorage('localStorage', constant.CLUBS);
    let clubIdFromStorage
    if (clubsListArr && clubsListArr.length > 0 && data === 'clubId') {
      if(req) {
        let filt = clubsListArr.filter((fil) => fil.clubName === req)
        clubIdFromStorage = filt[0].clubId
      } else {
        clubIdFromStorage = clubsListArr[0].clubId
      }
    } else if (clubsListArr && clubsListArr.length > 0 && data === 'clubName') {
      clubIdFromStorage = clubsListArr[0].clubName
    }
    return clubIdFromStorage
  }

  /*Below function is use to prevent writting space into inputField
  */
  AvoidSpace(event) {
    var k = event ? event.which : window.event.keyCode;
    if (k === 32) event.preventDefault();
  }

  //Restrict space paste on input box
  onPastAvoidSpace(e) {
    const str = e.clipboardData.getData('Text');
    const regres = /\s/g;
    if (regres.test(str)) {
      e.preventDefault()
    }
  }
  /*
  * The below function return a key value form localStorage userDetails.
  */
  getUserSingleDataFromLoaclStorage = (data) => {
    let userDetails = this.getInfoFromStorage('localStorage', constant.CURRENT_USER);
    let rtnValue
    for (var key in userDetails) {
      if (key === data) {
        rtnValue = userDetails[key]
      }
    }
    return rtnValue
  }

  getCurrencyList = async () => {
    try {
      return await API.getRequestWithoutAuthToken(`${config.BASE_URL}/currency`);
    } catch (error) {
      console.error(error);
    }
  }

  getCountryList = () =>{
    let countryData = this.getInfoFromStorage('localStorage', constant.COUNTRY_LIST);
    if(countryData){
      return countryData;
    }else{
      return null;
    }
  }

  getZonedDateTime = (time, timeZone) => {
    if (time && timeZone) {
      return moment.tz(time, timeZone).format('DD-MMM-YYYY');
    } else if (time) {
      return moment(time).format('DD-MMM-YYYY');
    }
  }

  getZonedTime = (time, timeZone) => {
    if (time && timeZone) {
      return moment.tz(time, timeZone).format('DD-MMM-YYYY HH:mm a');
    } else if (time) {
      return moment(time).format('DD-MMM-YYYY HH:mm a');
    }
  }

  getCurrentTZ = () => {
    let timeZone = moment.tz.guess()
    return timeZone;
  }
  
}


const UtilService = new UtilServiceData();
export default UtilService;
