import {
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL
} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
  isPasswordChanged: false,
  passwordChangedMessage: null,
  passwordChangedErrorMsg: null
};

export default (state = INITIAL_STATE, action) => {
  let currentState = cloneDeep(state);
  switch (action.type) {
    case CHANGE_PASSWORD_START:
      currentState.isPasswordChanged = false;
      break;
    case CHANGE_PASSWORD_SUCCESS:
      currentState.isPasswordChanged = true;
      currentState.passwordChangedMessage = action.payload;
      break;
    case CHANGE_PASSWORD_FAIL:
      currentState.isPasswordChanged = false;
      currentState.passwordChangedErrorMsg = action.payload;
      break;
    default:
      return currentState;
  }
  return currentState;
};
