import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import UtilsService from "../utils/utils";
import constant from "../../constants/constant";
import SignOut from "../signout";
class MyProfileSidebar extends Component {
    constructor(props) {
        super(props);
        this.hideSideBar = this.hideSideBar.bind(this)
    }

    hideSideBar = () => {
        UtilsService.hideSideBar();
    }

    render() {
        let roleId = UtilsService.getUserSingleDataFromLoaclStorage('roleId');
        let clubDetails = JSON.parse(localStorage.getItem('clubs'));
        return (
            <aside className="my-account-sidebar">
                <div className="profile-details">
                    <figure>
                        <img className="rounded-circle" src={`${UtilsService.getUserDetail(constant.PROFILE_IMAGE)}`} key={UtilsService.getUserDetail(constant.PROFILE_IMAGE)} alt="" />
                    </figure>
                     <strong className="d-block profile-user-name text-center mb-2">{UtilsService.getUserDetail(constant.FULL_NAME)}</strong>
                    {/*<span className="d-block text-center mb-2">{UtilsService.getUserDetail(constant.EMAIL)}</span>
                    <span className="d-block text-center">{`${UtilsService.getUserDetail(constant.COUNTRY_CODE)?UtilsService.getUserDetail(constant.COUNTRY_CODE):""} ${UtilsService.getUserDetail(constant.MOBILE)}`} </span> */}
                </div>
                <ul className="profile-nav">
                    
                    <li>
                        <NavLink className="d-flex align-items-center" exact to="/">
                            <svg className="mr-3" width="25.038" height="26" viewBox="0 0 25.038 26">
                                <g id="prefix__noun_Home_website_3013714" data-name="noun_Home website_3013714" transform="translate(-1.455 -1.186)">
                                    <g id="prefix__Group_6730" data-name="Group 6730" transform="translate(1.455 1.186)">
                                        <path id="prefix__Path_5399" d="M10.374 288.406a.472.472 0 0 0-.471.471v4.056l-2.225 2.225a.472.472 0 0 0 .335.806H9.9v5.2a.473.473 0 0 0 .471.471h2.835a.473.473 0 0 0 .474-.471v-3.31h1.89v3.31a.473.473 0 0 0 .474.471h2.832a.473.473 0 0 0 .474-.471v-5.2h1.89a.472.472 0 0 0 .335-.806l-6.616-6.612a.473.473 0 0 0-.666 0l-1.555 1.554v-1.221a.473.473 0 0 0-.474-.471zm.474.945h.945v1.89a.473.473 0 0 0 .806.331l2.026-2.026 5.472 5.472h-1.22a.472.472 0 0 0-.471.474v5.2h-1.89v-3.306a.473.473 0 0 0-.471-.474h-2.836a.472.472 0 0 0-.471.474v3.306h-1.89v-5.2a.472.472 0 0 0-.474-.474H9.157l1.552-1.555a.472.472 0 0 0 .14-.331z" fill="black" data-name="Path 5399" transform="translate(3.325 -275.634)" />
                                        <path id="prefix__Path_5400" d="M13.266 281.253a11.811 11.811 0 0 0 0 23.622.472.472 0 0 0 0-.945c-1.31 0-2.6-1.093-3.586-3.059a14.732 14.732 0 0 1-1.18-3.553h4.771a.472.472 0 0 0 0-.945H8.322a21.8 21.8 0 0 1 0-6.615h9.888a21.388 21.388 0 0 1 .249 2.917.472.472 0 0 0 .945 0 22.468 22.468 0 0 0-.239-2.917h4.453a10.909 10.909 0 0 1 0 6.615.472.472 0 0 0 .334.579.478.478 0 0 0 .581-.339 11.819 11.819 0 0 0-11.268-15.359zm0 .945c1.31 0 2.6 1.093 3.585 3.059a14.733 14.733 0 0 1 1.186 3.554H8.5a14.734 14.734 0 0 1 1.185-3.554c.979-1.966 2.271-3.057 3.581-3.057zm2.86.383a10.877 10.877 0 0 1 7.142 6.23H19a15.821 15.821 0 0 0-1.3-3.976 8.662 8.662 0 0 0-1.569-2.254zm-5.725 0a8.65 8.65 0 0 0-1.569 2.253 15.785 15.785 0 0 0-1.3 3.976H3.264a10.877 10.877 0 0 1 7.138-6.229zm-7.488 7.175h4.453a22.832 22.832 0 0 0 0 6.615H2.913a10.909 10.909 0 0 1 0-6.615zm.349 7.561h4.269a15.782 15.782 0 0 0 1.3 3.975 8.651 8.651 0 0 0 1.569 2.253 10.874 10.874 0 0 1-7.139-6.229z" fill="black" data-name="Path 5400" transform="translate(-1.455 -281.253)" />
                                    </g>
                                </g>
                            </svg>
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="d-flex align-items-center" exact to="/profile/my-profile" onClick={this.hideSideBar}>
                            <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="26.346" height="29.938" viewBox="0 0 26.346 29.938">
                                <g id="prefix__ic_profile" transform="translate(-4151.418 -488.5)">
                                    <g id="prefix__Group_6554" data-name="Group 6554" transform="translate(4151.918 489)">
                                        <g id="prefix__Group_6553" data-name="Group 6553">
                                            <path id="prefix__Path_5205" d="M3638.776 3894.882l2.44 2.439a.437.437 0 010 .614l-6.928 6.927a.412.412 0 01-.206.114l-3.651 1.049a.439.439 0 01-.538-.3.435.435 0 01.016-.288l1.217-3.472a.418.418 0 01.1-.163l6.927-6.927a.436.436 0 01.614 0zm1.521 2.749l-1.826-1.825-6.552 6.552-.913 2.608 2.75-.793z" data-name="Path 5205" transform="translate(-3616.053 -3877.125)" fill="#333" stroke="#333" />
                                            <path id="prefix__Path_5206" d="M4714.876 4312.491a.434.434 0 01.614-.614l2.439 2.439a.434.434 0 01-.614.614z" data-name="Path 5206" transform="translate(-4695.034 -4291.859)" fill="#333" stroke="#333" />
                                            <path id="prefix__Path_5207" d="M3862.935 5170.322a.434.434 0 11-.614.614l-2.445-2.446a.434.434 0 01.614-.614z" data-name="Path 5207" transform="translate(-3844.679 -5143.208)" fill="#333" stroke="#333" />
                                            <path id="prefix__Path_5208" d="M1872.346 853a6.344 6.344 0 11-4.488 1.858 6.327 6.327 0 014.488-1.858zm3.874 2.472a5.483 5.483 0 101.6 3.874 5.457 5.457 0 00-1.6-3.874z" data-name="Path 5208" transform="translate(-1861.762 -853)" fill="#333" stroke="#333" />
                                            <path id="prefix__Path_5209" d="M1106.517 3295.265a9.126 9.126 0 00-1.587-2.212 12.764 12.764 0 00-4.673-2.108 6.228 6.228 0 01-6.846.005 16.032 16.032 0 00-4.444 2 4.366 4.366 0 00-1.733 2.434 18.512 18.512 0 00-.424 4.287c-.005.125-.005.25-.011.375h14.849a5.077 5.077 0 00.1.869h-15.414a.428.428 0 01-.418-.446c.011-.272.016-.538.022-.815a19.08 19.08 0 01.456-4.5 5.15 5.15 0 012.059-2.9 17.124 17.124 0 014.874-2.184.441.441 0 01.407.054 5.38 5.38 0 004.722.733 5.274 5.274 0 001.472-.739.429.429 0 01.364-.065 13.933 13.933 0 015.189 2.336 8.054 8.054 0 011.891 2.619c-2.07-.065 1.576-.087-.853.255z" data-name="Path 5209" transform="translate(-1085.918 -3275.704)" fill="#333" stroke="#333" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            My Profile
                        </NavLink>
                    </li>
                    {/* {roleId == 5 && 
                    <React.Fragment>
                     <li>
                        <NavLink className="d-flex align-items-center" exact to="/profile/playerStats" onClick={this.hideSideBar}>
                        <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M507.8 37.24C513.8 43.78 513.3 53.89 506.8 59.84L330.8 219.8C324.4 225.6 314.7 225.4 308.7 219.3L190.4 100.1L25.41 220.9C18.26 226.1 8.258 224.6 3.061 217.4C-2.137 210.3-.5565 200.3 6.59 195.1L182.6 67.06C188.1 62.43 197.7 63.12 203.3 68.69L320.5 185.9L485.2 36.16C491.8 30.22 501.9 30.7 507.8 37.24H507.8zM112 368V432C112 458.5 90.51 480 64 480C37.49 480 16 458.5 16 432V368C16 341.5 37.49 319.1 64 319.1C90.51 319.1 112 341.5 112 368zM80 432V368C80 359.2 72.84 352 64 352C55.16 352 48 359.2 48 368V432C48 440.8 55.16 448 64 448C72.84 448 80 440.8 80 432zM144 271.1C144 245.5 165.5 223.1 192 223.1C218.5 223.1 240 245.5 240 271.1V432C240 458.5 218.5 480 192 480C165.5 480 144 458.5 144 432V271.1zM192 255.1C183.2 255.1 176 263.2 176 271.1V432C176 440.8 183.2 448 192 448C200.8 448 208 440.8 208 432V271.1C208 263.2 200.8 255.1 192 255.1zM368 336V432C368 458.5 346.5 480 320 480C293.5 480 272 458.5 272 432V336C272 309.5 293.5 287.1 320 287.1C346.5 287.1 368 309.5 368 336zM336 432V336C336 327.2 328.8 319.1 320 319.1C311.2 319.1 304 327.2 304 336V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432zM400 271.1C400 245.5 421.5 223.1 448 223.1C474.5 223.1 496 245.5 496 271.1V432C496 458.5 474.5 480 448 480C421.5 480 400 458.5 400 432V271.1zM448 255.1C439.2 255.1 432 263.2 432 271.1V432C432 440.8 439.2 448 448 448C456.8 448 464 440.8 464 432V271.1C464 263.2 456.8 255.1 448 255.1z"/></svg> 
                                Statistics
                        </NavLink>
                    </li> 
                    </React.Fragment>
                        } */}
                    {(roleId === constant.PLAYER_VALUE || roleId === constant.CLUB_SUPER_ADMIN_VALUE) && (
                        <React.Fragment>
                            <li>
                                <NavLink className="d-flex align-items-center"exact  to="/profile/payment" onClick={this.hideSideBar}>
                                    <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="26.346" height="29.938" viewBox="0 0 26.346 29.938">
                                        <g id="prefix__ic_profile" transform="translate(-4151.418 -488.5)">
                                            <g id="prefix__Group_6554" data-name="Group 6554" transform="translate(4151.918 489)">
                                                <g id="prefix__Group_6553" data-name="Group 6553">
                                                    <path id="prefix__Path_5205" d="M3638.776 3894.882l2.44 2.439a.437.437 0 010 .614l-6.928 6.927a.412.412 0 01-.206.114l-3.651 1.049a.439.439 0 01-.538-.3.435.435 0 01.016-.288l1.217-3.472a.418.418 0 01.1-.163l6.927-6.927a.436.436 0 01.614 0zm1.521 2.749l-1.826-1.825-6.552 6.552-.913 2.608 2.75-.793z" data-name="Path 5205" transform="translate(-3616.053 -3877.125)" fill="#333" stroke="#333" />
                                                    <path id="prefix__Path_5206" d="M4714.876 4312.491a.434.434 0 01.614-.614l2.439 2.439a.434.434 0 01-.614.614z" data-name="Path 5206" transform="translate(-4695.034 -4291.859)" fill="#333" stroke="#333" />
                                                    <path id="prefix__Path_5207" d="M3862.935 5170.322a.434.434 0 11-.614.614l-2.445-2.446a.434.434 0 01.614-.614z" data-name="Path 5207" transform="translate(-3844.679 -5143.208)" fill="#333" stroke="#333" />
                                                    <path id="prefix__Path_5208" d="M1872.346 853a6.344 6.344 0 11-4.488 1.858 6.327 6.327 0 014.488-1.858zm3.874 2.472a5.483 5.483 0 101.6 3.874 5.457 5.457 0 00-1.6-3.874z" data-name="Path 5208" transform="translate(-1861.762 -853)" fill="#333" stroke="#333" />
                                                    <path id="prefix__Path_5209" d="M1106.517 3295.265a9.126 9.126 0 00-1.587-2.212 12.764 12.764 0 00-4.673-2.108 6.228 6.228 0 01-6.846.005 16.032 16.032 0 00-4.444 2 4.366 4.366 0 00-1.733 2.434 18.512 18.512 0 00-.424 4.287c-.005.125-.005.25-.011.375h14.849a5.077 5.077 0 00.1.869h-15.414a.428.428 0 01-.418-.446c.011-.272.016-.538.022-.815a19.08 19.08 0 01.456-4.5 5.15 5.15 0 012.059-2.9 17.124 17.124 0 014.874-2.184.441.441 0 01.407.054 5.38 5.38 0 004.722.733 5.274 5.274 0 001.472-.739.429.429 0 01.364-.065 13.933 13.933 0 015.189 2.336 8.054 8.054 0 011.891 2.619c-2.07-.065 1.576-.087-.853.255z" data-name="Path 5209" transform="translate(-1085.918 -3275.704)" fill="#333" stroke="#333" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    Payment
                                </NavLink>
                            </li>
                        </React.Fragment>
                    )}
                    <li>
                        <NavLink className="d-flex align-items-center" exact to="/profile/change-password" onClick={this.hideSideBar}>
                            <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="35.143" height="33.184" viewBox="0 0 35.143 33.184">
                                <g id="prefix__round-person-24px" transform="translate(3.215 9.184)">
                                    <g id="prefix__noun_change_password_2068751" data-name="noun_change password_2068751" transform="rotate(-45 -6.51 19.884)">
                                        <path id="prefix__Path_5230" d="M39.366 26.606h-11.75a8.039 8.039 0 00-3.569-4.178.8.8 0 10-.787 1.4 6.407 6.407 0 01-2.423 11.957l.748-.774A.8.8 0 1020.43 33.9l-2.02 2.091-.006.008c-.013.014-.024.031-.036.046a.8.8 0 00-.058.076c-.013.02-.022.043-.033.065a.8.8 0 00-.037.074c-.008.022-.013.046-.02.069a.8.8 0 00-.022.085v.079c0 .023-.006.046-.006.07v.01a.779.779 0 00.01.089c0 .022 0 .045.01.067a.789.789 0 00.027.084c.008.021.013.043.023.064a.8.8 0 00.04.075c.012.02.022.04.035.059a.806.806 0 00.062.073.481.481 0 00.035.04l.009.009 2.146 2.07a.8.8 0 101.111-1.158l-.689-.666a8.032 8.032 0 007.031-6.711 7.931 7.931 0 000-2.458h11.324a.8.8 0 01.8.8v3.609a.8.8 0 01-1.6 0v-1.2a.8.8 0 00-.8-.8h-2.41a.8.8 0 00-.8.8v1.2a.8.8 0 01-1.6 0v-1.2a.8.8 0 00-.8-.8h-2.415a.8.8 0 100 1.6h1.6v.4a2.406 2.406 0 004.813 0v-.4h.8v.4a2.406 2.406 0 004.813 0v-3.606a2.409 2.409 0 00-2.401-2.407z" data-name="Path 5230" transform="translate(-9.097 -4.364)" fill="#333333" />
                                        <path id="prefix__Path_5231" d="M14 34.609A3.609 3.609 0 1017.609 31 3.609 3.609 0 0014 34.609zm5.615 0a2.005 2.005 0 11-2.006-2.009 2.005 2.005 0 012.006 2.009z" className="prefix__cls-2" data-name="Path 5231" transform="translate(-6.591 -9.56)" fill="#333333" />
                                        <path id="prefix__Path_5232" d="M7.088 32.034a.8.8 0 10.787-1.4A6.407 6.407 0 0110.3 18.679l-.747.774a.8.8 0 101.147 1.114l2.02-2.091a.8.8 0 00.069-.087c.009-.012.02-.023.028-.036a.8.8 0 00.045-.087c.008-.018.019-.035.026-.054s.014-.049.021-.074a.8.8 0 00.021-.08.014.014 0 010-.01v-.069c0-.023.006-.046.006-.07v-.01a.778.778 0 00-.01-.089c0-.022 0-.045-.01-.067a.789.789 0 00-.027-.084c-.008-.021-.013-.043-.023-.064a.794.794 0 00-.04-.075c-.012-.02-.022-.04-.035-.059a.806.806 0 00-.062-.073.481.481 0 00-.035-.04l-.009-.009-2.145-2.069a.8.8 0 10-1.114 1.154l.692.668a8.01 8.01 0 00-3.03 14.942z" data-name="Path 5232" fill="#333333" />
                                    </g>
                                </g>
                            </svg>
                            Change Password
                        </NavLink>
                    </li>
                    {(roleId === 3) && (
                        <React.Fragment>
                            <li>
                                <NavLink className="d-flex align-items-center" exact to="/profile/onboard" onClick={this.hideSideBar}>
                                <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="26.346" height="29.938" viewBox="0 0 26.346 29.938">
                                        <g id="prefix__ic_profile" transform="translate(-4151.418 -488.5)">
                                            <g id="prefix__Group_6554" data-name="Group 6554" transform="translate(4151.918 489)">
                                                <g id="prefix__Group_6553" data-name="Group 6553">
                                                    <path id="prefix__Path_5205" d="M3638.776 3894.882l2.44 2.439a.437.437 0 010 .614l-6.928 6.927a.412.412 0 01-.206.114l-3.651 1.049a.439.439 0 01-.538-.3.435.435 0 01.016-.288l1.217-3.472a.418.418 0 01.1-.163l6.927-6.927a.436.436 0 01.614 0zm1.521 2.749l-1.826-1.825-6.552 6.552-.913 2.608 2.75-.793z" data-name="Path 5205" transform="translate(-3616.053 -3877.125)" fill="#333" stroke="#333" />
                                                    <path id="prefix__Path_5206" d="M4714.876 4312.491a.434.434 0 01.614-.614l2.439 2.439a.434.434 0 01-.614.614z" data-name="Path 5206" transform="translate(-4695.034 -4291.859)" fill="#333" stroke="#333" />
                                                    <path id="prefix__Path_5207" d="M3862.935 5170.322a.434.434 0 11-.614.614l-2.445-2.446a.434.434 0 01.614-.614z" data-name="Path 5207" transform="translate(-3844.679 -5143.208)" fill="#333" stroke="#333" />
                                                    <path id="prefix__Path_5208" d="M1872.346 853a6.344 6.344 0 11-4.488 1.858 6.327 6.327 0 014.488-1.858zm3.874 2.472a5.483 5.483 0 101.6 3.874 5.457 5.457 0 00-1.6-3.874z" data-name="Path 5208" transform="translate(-1861.762 -853)" fill="#333" stroke="#333" />
                                                    <path id="prefix__Path_5209" d="M1106.517 3295.265a9.126 9.126 0 00-1.587-2.212 12.764 12.764 0 00-4.673-2.108 6.228 6.228 0 01-6.846.005 16.032 16.032 0 00-4.444 2 4.366 4.366 0 00-1.733 2.434 18.512 18.512 0 00-.424 4.287c-.005.125-.005.25-.011.375h14.849a5.077 5.077 0 00.1.869h-15.414a.428.428 0 01-.418-.446c.011-.272.016-.538.022-.815a19.08 19.08 0 01.456-4.5 5.15 5.15 0 012.059-2.9 17.124 17.124 0 014.874-2.184.441.441 0 01.407.054 5.38 5.38 0 004.722.733 5.274 5.274 0 001.472-.739.429.429 0 01.364-.065 13.933 13.933 0 015.189 2.336 8.054 8.054 0 011.891 2.619c-2.07-.065 1.576-.087-.853.255z" data-name="Path 5209" transform="translate(-1085.918 -3275.704)" fill="#333" stroke="#333" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    Stripe Onboard
                                </NavLink>
                            </li>
                        </React.Fragment>
                    )}
                    <li>
                        <SignOut />
                    </li>
                </ul>
            </aside>
        );
    }
}
const mapStateToProps = state => {
    return {
        myProfile: state.myProfileReducer,
        ui: state.ui
    };
};

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
});
export default connect(mapStateToProps, mapDispatchToProps)(MyProfileSidebar);
