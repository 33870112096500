import myApi from '../container/utils/api';
import config from "../constants/config";
import {
    SEND_MAIL_FAIL,
    SEND_MAIL_SUCCESS,
    SEND_MAIL_START,
} from "../constants/type";

export const sendMailAction = emailId => {
    return function (dispatch) {
        dispatch({ type: SEND_MAIL_START });
        const data = {
            email: emailId
        };
        myApi
            .putRequest(`${config.BASE_URL}/user/setPassword`, data)
            .then(response => {
                dispatch({ type: SEND_MAIL_SUCCESS, payload: response.message });
            })
            .catch(error => {
                //console.log(error)
                dispatch({ type: SEND_MAIL_FAIL, payload: error });
            });
    };
};
