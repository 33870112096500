import API from "../utils/api";
import {
    API_START,
    API_SUCCESS,
    API_FAIL,
    CLUB_NAME_LIST_FAIL,
    CLUB_NAME_LIST_START,
    CLUB_NAME_LIST_SUCCESS,
    EDIT_DETAILS_START,
    EDIT_DETAILS_FAIL,
    EDIT_DETAILS_SUCCESS,
    EDIT_PLAYER_DETAILS_SUCCESS,
    API_SUCCESS_OTHER,
    API_FAIL_OTHER,
    ADD_MEMBER_REGISTRATION_START,
    ADD_MEMBER_REGISTRATION_SUCCESS,
    ADD_MEMBER_REGISTRATION_FAIL,
    ACTIVE_MEMBER_REGISTRATION_START,
    ACTIVE_MEMBER_REGISTRATION_SUCCESS,
    ACTIVE_MEMBER_REGISTRATION_FAIL,
    GOOGLE_REGISTRATION_START,//GOOGLE
    GOOGLE_REGISTRATION_SUCCESS,
    GOOGLE_REGISTRATION_FAIL,
    FACEBOOK_REGISTRATION_START,
    FACEBOOK_REGISTRATION_SUCCESS,
    FACEBOOK_REGISTRATION_FAIL,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    DELETE_OTHER_MEMBER_START,
    DELETE_OTHER_MEMBER_SUCCESS,
    DELETE_OTHER_MEMBER_FAIL,
    CLUB_MEMBER_ASSOCIATIONS_START,
    CLUB_MEMBER_ASSOCIATIONS_SUCCESS,
    CLUB_MEMBER_ASSOCIATIONS_FAIL,
    EMAIL_VERIFICATION_START,
    EMAIL_VERIFICATION_SUCCESS,
    EMAIL_VERIFICATION_FAIL,
    OTP_VERIFICATION_START,
    OTP_VERIFICATION_SUCCESS,
    OTP_VERIFICATION_FAIL
} from "../../constants/type";
import config from "../../constants/config";
import UtilService from "../utils/utils";
// import * as moment from 'moment'
import constant from "../../constants/constant";
import { toast } from 'react-toastify';
import {signIn} from '../login/loginAction';

export const playerAction = (requestPayload, isUpdateAction, isRegister) => {
    return async function (dispatch) {
        dispatch({ type: API_START });
        var addUpdatePlayerResponse
        if (isUpdateAction) {
            var dataPayload
            let urlPath
            let clubs = requestPayload.clubs && requestPayload.clubs.length > 0 ? requestPayload.clubs : []
            if ( clubs && clubs.length > 0 && clubs[0].hasOwnProperty('membershipType')) {
                const clubListId = clubs.map(clubList => clubList.clubId)
                clubs = clubListId
            }
            if (UtilService.isPabsaAdmin() ) {//|| UtilService.isClubSuperAdmin()
               
                //Pabsa Admin player association
                // if(UtilService.isClubSuperAdmin()) {
                //     await API
                //         .getRequest(`${config.BASE_URL}/user/findAssociatedClub?userId=${requestPayload.playerId}`)
                //         .then(response=>{
                //             response.map((res)=>clubs.push(res.clubId))
                //         })
                // }
                urlPath = '/player/associations'
                dataPayload = {
                    clubs: clubs,
                    membershipType: requestPayload.membershipType,
                    playerId: requestPayload.playerId,
                    discount:requestPayload.discount,
                    isReferee:requestPayload.isReferee
                }
            } else {
                //Not PabsaAdmin player association
                let clubid
                if (requestPayload.clubs.length > 0) {
                    clubid = requestPayload.clubs[0]
                } else
                    clubid = null
                dataPayload = {
                    clubId: clubid,
                    playerId: requestPayload.playerId,
                    membershipType: requestPayload.membershipType,
                    discount:requestPayload.discount,
                    isReferee:requestPayload.isReferee
                }
                urlPath = '/player/association'

            }
            console.log("dataPayload",dataPayload)

            addUpdatePlayerResponse = API.putRequest(`${config.BASE_URL}${urlPath}`, JSON.stringify(dataPayload))

        }
        else {
            let playerAPIPath = isRegister ? '/player/register' : '/player'
            addUpdatePlayerResponse = API.postRequest(`${config.BASE_URL}${playerAPIPath}`, requestPayload)
        }

        addUpdatePlayerResponse.then(response => {
            dispatch({ type: API_SUCCESS, payload: response.message });
        })
            .catch(error => {
                if (error.response && (error.response.status === 500 || error.response.status === 400)) {
                    return dispatch({
                        type: API_FAIL,
                        payload: error.response.data
                    });
                }
                else
                    dispatch({
                        type: API_FAIL,
                        payload: error
                    });
            });
    }
}
export const getClubList = () => {

    return async dispatch => {
        dispatch({ type: CLUB_NAME_LIST_START });
        await API
            .getRequestWithAsyn(`${config.BASE_URL}/club/listAllActiveClubs`)
            .then(response => {
                return dispatch({ type: CLUB_NAME_LIST_SUCCESS, payload: JSON.stringify(response) });
            })
            .catch(error => {
                return dispatch({ type: CLUB_NAME_LIST_FAIL, payload: (error.response && error.response.data) ? error.response.data.message : "" });
            });

    }

};

export const getMatchesLists = (data) => {
    return function (dispatch) {

        dispatch({ type: API_START });
        let requestPayload
        let requestPayloadPast
        const { searchBy, clubId, searchByPastMatch } = data

        // console.log("/////////////////////", data)

        if (UtilService.getUserDetail(constant.ROLE_ID) === constant.PLAYER_VALUE) {
            //player user
            //console.log("Player Login Action")
            requestPayload = config.PAGE_SIZE + `&pageNumber=` + data.activePage + `&playerId=` + UtilService.getUserDetail(constant.USERID) + `&address=` + searchBy.venueDetails + `&matchName=` + searchBy.matchName + `&playerName=` + searchBy.playerName + `&date=` + searchBy.date + `&statusId=` + constant.UPCOMING_MATCH_ID
            requestPayloadPast = config.PAGE_SIZE + `&pageNumber=` + data.activePagePast + `&playerId=` + UtilService.getUserDetail(constant.USERID) + `&address=` + searchByPastMatch.venueDetails + `&matchName=` + searchByPastMatch.matchName + `&playerName=` + searchByPastMatch.playerName + `&date=` + searchByPastMatch.date + `&statusId=` + constant.COMPLETED_MATCH_ID
        } else {
            //other user
            //console.log("Other Login Action")
            requestPayload = config.PAGE_SIZE + `&pageNumber=` + data.activePage + `&address=` + searchBy.venueDetails + `&matchName=` + searchBy.matchName + `&playerName=` + searchBy.playerName + `&date=` + searchBy.date + `&statusId=` + constant.UPCOMING_MATCH_ID + `&clubId=` + clubId
            requestPayloadPast = config.PAGE_SIZE + `&pageNumber=` + data.activePagePast + `&address=` + searchByPastMatch.venueDetails + `&matchName=` + searchByPastMatch.matchName + `&playerName=` + searchByPastMatch.playerName + `&date=` + searchByPastMatch.date + `&statusId=` + constant.COMPLETED_MATCH_ID + `&clubId=` + clubId

        }
        API
            .getRequest(`${config.BASE_URL}/match?limit=` + requestPayload)
            .then(response => {
                return dispatch({ type: API_SUCCESS, payload: response });
            })
            .catch(error => {
                return dispatch({ type: API_FAIL, payload: error.response });
            });

        API
            .getRequest(`${config.BASE_URL}/match?limit=` + requestPayloadPast)
            .then(response => {
                return dispatch({ type: API_SUCCESS_OTHER, payload: response });
            })
            .catch(error => {
                return dispatch({ type: API_FAIL_OTHER, payload: error.response });
            });
    };
};

export const getSingleMatchDetailsAction = (type, history, matchId, pageName) => {
    return function (dispatch) {
        dispatch({ type: EDIT_DETAILS_START });
        //console.log("----------------------------", clubId)

        API.getRequest(`${config.BASE_URL}/match/${matchId}`)
            .then(response => {
                //console.log("playerDetails*-*-*-*-*-*-*-*-*-*-*-", response)
                if (type === constant.VIEW) {
                    dispatch({ type: EDIT_DETAILS_SUCCESS, payload: response });
                } else if (type === constant.EDIT) {
                    dispatch({ type: EDIT_PLAYER_DETAILS_SUCCESS, payload: response });
                    history.push({ pathname: '/create-match', state: { detail: response, pageName: pageName } })
                }

            })
            .catch(error => {
                dispatch({ type: EDIT_DETAILS_FAIL, payload: error });
            });
    };
}

export const playerFirstLogin = (user, history) => {
    return function (dispatch) {
        dispatch({ type: API_SUCCESS });
        API.putRequest(`${config.BASE_URL}/user/update/eula?token=${user.token}`)
            .then(response => {
                dispatch({
                    type: API_SUCCESS,
                    payload: response
                })
            })
            .catch(error => {
                dispatch({ type: API_FAIL, payload: error });
            });
    };
}

export const addPlayerRegistration = (data) => {
  const reqPayload = {
    "card": "",
    "currency": data.currency,
    "email": data.email,
    "firstName": data.firstName,
    "lastName": data.lastName,
    "middleName": data.middleName,
    "mobile": `${data.mobile}`,
    "password": data.password,
    "userPin": data.pin,
    "countryCode": data.countryCode,
    "clubs": data.clubs,
    "isEulaAccepted": data.isEulaAccepted,
    "isReferee": data.isReferee,
  }

  return function (dispatch) {
    dispatch({ type: ADD_MEMBER_REGISTRATION_START });
    API
      .postRequest(`${config.BASE_URL}/player/register`, reqPayload)
      .then((response) => {
        dispatch({ type: ADD_MEMBER_REGISTRATION_SUCCESS, payload: response });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message ? error.response.data.message : "Something went wrong");
        dispatch({ type: ADD_MEMBER_REGISTRATION_FAIL, payload: error.response.data });
      });
  };
};

  export const activePlayerRegistration = () => {
    return function (dispatch) {
      dispatch({ type: ACTIVE_MEMBER_REGISTRATION_START });
      API
        .putRequest(`${config.BASE_URL}/user/activate`)
        .then((response) => {
            // toast.success(response?.message ?response?.message: "Player registered successfully.");
          dispatch({ type: ACTIVE_MEMBER_REGISTRATION_SUCCESS, payload: response });
          // return response
        })
        .catch((error) => {
          // toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
          dispatch({ type: ACTIVE_MEMBER_REGISTRATION_FAIL, payload: error.response.data });
        });
    };
  };

  export const memberClubAssociation = (params) => {
    return function (dispatch) {
      dispatch({ type: CLUB_MEMBER_ASSOCIATIONS_START });
      API
        .putRequestWithCustomAuth(`${config.BASE_URL}/player/association/confirm?input=${params?.input}`, null, params?.token)
        .then((response) => {
            // toast.success(response?.message ?response?.message: "Player registered successfully.");
          dispatch({ type: CLUB_MEMBER_ASSOCIATIONS_SUCCESS, payload: response });
          // return response
        })
        .catch((error) => {
          toast.error(error.response.data.message?error.response.data.message: "Something went wrong");
          dispatch({ type: CLUB_MEMBER_ASSOCIATIONS_FAIL, payload: error.response.data });
        });
    };
  };

  export const googleLogin = (reqPayload) => {
    return function (dispatch) {
      dispatch({ type: GOOGLE_REGISTRATION_START });
      API
        .postRequest(`${config.BASE_URL}/google/login?idTokenString=`+reqPayload.idTokenString)
        .then((response) => {
            toast.success(response?.message ?response?.message: "Player registered successfully.");
            if (!response.userInfo) {
                return dispatch({ type: USER_LOGIN_FAIL, payload: response });
      
              }
      
              UtilService.setInfoInStorage('localStorage', constant.CURRENT_USER, response.userInfo)
              UtilService.setInfoInStorage('localStorage', constant.AUTH_TOKEN, response.token)
      
      
              let getUserProfileImage = UtilService.getInfoFromStorage('localStorage', constant.CURRENT_USER).profileImage;
              if (!getUserProfileImage) {
                // UtilService.updateLocalStorage(constant.CURRENT_USER, 'profileImage', avatar)
              }
            //   UtilService.setInfoInStorage('localStorage', constant.PASSWORD, encryptPassword)
      
              //store clubId into localstorage
              if (response.hasOwnProperty('clubs')) {
                UtilService.setInfoInStorage('localStorage', constant.CLUBS, response.clubs)
              }
               dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: { logged_in_user: response }
              });
          dispatch({ type: GOOGLE_REGISTRATION_SUCCESS, payload: response });
          return response
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
          dispatch({ type: GOOGLE_REGISTRATION_FAIL, payload: error.response.data });
        });
    };
  };

  
  export const facebookLogin = (reqPayload) => {
    return function (dispatch) {
      dispatch({ type: FACEBOOK_REGISTRATION_START });
      API
        .postRequest(`${config.BASE_URL}/facebook/login?accessToken=`+reqPayload.accessToken)
        .then((response) => {
            toast.success(response?.message ?response?.message: "Player registered successfully.");
            if (!response.userInfo) {
                return dispatch({ type: USER_LOGIN_FAIL, payload: response });
      
              }
      
              UtilService.setInfoInStorage('localStorage', constant.CURRENT_USER, response.userInfo)
              UtilService.setInfoInStorage('localStorage', constant.AUTH_TOKEN, response.token)
      
      
              let getUserProfileImage = UtilService.getInfoFromStorage('localStorage', constant.CURRENT_USER).profileImage;
              if (!getUserProfileImage) {
                // UtilService.updateLocalStorage(constant.CURRENT_USER, 'profileImage', avatar)
              }
            //   UtilService.setInfoInStorage('localStorage', constant.PASSWORD, encryptPassword)
      
              //store clubId into localstorage
              if (response.hasOwnProperty('clubs')) {
                UtilService.setInfoInStorage('localStorage', constant.CLUBS, response.clubs)
              }
               dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: { logged_in_user: response }
              });
          dispatch({ type: FACEBOOK_REGISTRATION_SUCCESS, payload: response });
          return response
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
          dispatch({ type: FACEBOOK_REGISTRATION_FAIL, payload: error.response.data });
        });
    };
  };

  export const resetFormData = () => {
    return function (dispatch) {
      dispatch({ type: ADD_MEMBER_REGISTRATION_START });
    };
  };


  export const deleteMemberAction = (data) => {
    console.log(data)
    return async function (dispatch) {
        dispatch({ type: DELETE_OTHER_MEMBER_START });
        await API.deleteRequest(`${config.BASE_URL}/user/${data.memberId}`, )
            .then(response => {
                dispatch({
                    type: DELETE_OTHER_MEMBER_SUCCESS,
                    payload: response
                });
            })
            .catch(error => {
                if (error.response) {
                    return dispatch({ type: DELETE_OTHER_MEMBER_FAIL, payload: error.response });
                } else {
                    return dispatch({ type: DELETE_OTHER_MEMBER_FAIL, payload: error });
                }
            });
    };    
};

export const emailVerification = (reqPayload) => {
  return async function (dispatch) {
    dispatch({ type: EMAIL_VERIFICATION_START });
    await API
      .postRequest(`${config.BASE_URL}/player/verify/email`, reqPayload)
      .then((response) => {
        dispatch({ type: EMAIL_VERIFICATION_SUCCESS, payload: response });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
        dispatch({ type: EMAIL_VERIFICATION_FAIL, payload: error.response.data });
      });
  };
};


export const otpVerification = (email, otp) => {
  const reqPayload = {
      "email": email,
      "code" :otp
    }
    
  return async function (dispatch) {
    dispatch({ type: OTP_VERIFICATION_START });
    await API
      .postRequest(`${config.BASE_URL}/player/verify/code`, reqPayload)
      .then((response) => {
        dispatch({ type: OTP_VERIFICATION_SUCCESS, payload: response });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
        dispatch({ type: OTP_VERIFICATION_FAIL, payload: error.response.data });
      });
  };
};