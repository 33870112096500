import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import loadable from "react-loadable";
import SideBar from "./container/sidebar";
import LoadingView from "./container/view/LoadingView";
import MyProfileSidebar from "./container/my-profile/myProfileSidebar";
import AuthenticatedRoute from "./container/AuthenticatedRoute";
import AddClub from "./container/admin/clubManagement/addClub";
import history from './container/utils/history';
import TournamentInfo from "./container/tournament/tournamentInfo";
import faq from "./container/faq";
import Header from "./container/header";
import constant from "./constants/constant";
import PageNotFound from "./container/404-page";

const SetPassword = loadable({
  loader: () => import("./container/setpassword/set-password"),
  loading: () => <LoadingView />
});

const ClubManagement = loadable({
  loader: () => import("./container/admin/clubManagement/club-management"),
  loading: () => <LoadingView />
});

const ScheduleTable = loadable({
  loader: () => import("./container/TableCalendar/scheduleTable"),
  loading: () => <LoadingView />
});

const ClubChannelList = loadable({
  loader: () => import("./container/admin/clubManagement/clubChannelList"),
  loading: () => <LoadingView />
});


const UserManagement = loadable({
  loader: () => import("./container/admin/user-management"),
  loading: () => <LoadingView />
});

const MyProfile = loadable({
  loader: () => import("./container/my-profile/myProfile"),
  loading: () => <LoadingView />
});

const ChangePassword = loadable({
  loader: () => import("./container/my-profile/changePassword"),
  loading: () => <LoadingView />
});

const Home = loadable({
  loader: () => import("./container/home/Home"),
  loading: () => <LoadingView />
});
// const Header = loadable({
//   loader: () => import("./container/header"),
//   loading: () => <LoadingView />
// });
const AddPlayer = loadable({
  loader: () => import("./container/player/addPlayer"),
  loading: () => <LoadingView />
});
const AddMember = loadable({
  loader: () => import("./container/player/activateMemberPage"),
  loading: () => <LoadingView />
});
const ClubAdmin = loadable({
  loader: () => import("./container/clubSuperAdmin/clubAdmin"),
  loading: () => <LoadingView />
});
const ManageClubTable = loadable({
  loader: () => import("./container/clubSuperAdmin/manageClubTable"),
  loading: () => <LoadingView />
});
const FoodBeverage = loadable({
  loader: () => import("./container/clubSuperAdmin/foodBeverage"),
  loading: () => <LoadingView />
});
const ManagePlayer = loadable({
  loader: () => import("./container/player/managePlayer"),
  loading: () => <LoadingView />
});
const ManageMatch = loadable({
  loader: () => import("./container/match/manageMatch"),
  loading: () => <LoadingView />
});
const CreateMatch = loadable({
  loader: () => import("./container/match/createMatch"),
  loading: () => <LoadingView />
});
const ManageTournament = loadable({
  loader: () => import("./container/tournament/manageTournament"),
  loading: () => <LoadingView />
});
const CreateTournament = loadable({
  loader: () => import("./container/tournament/createTournament"),
  loading: () => <LoadingView />
});
const PlayerMatchesLists = loadable({
  loader: () => import("./container/player/player-matches/playerMatchesLists"),
  loading: () => <LoadingView />
});
const Payment = loadable({
  loader: () => import("./container/payment/payment"),
  loading: () => <LoadingView />
});
const ConnectOnboard = loadable({
  loader: () => import("./container/connectOnboard/connectOnboard"),
  loading: () => <LoadingView />
});
const OnboardPage = loadable({
  loader: () => import("./container/connectOnboard/onboardPage"),
  loading: () => <LoadingView />
});
const TermsConditions = loadable({
  loader: () => import("./container/termsConditions"),
  loading: () => <LoadingView />
});

const TermsConditionsEula = loadable({
  loader: () => import("./container/termsWithoutCheckConditions"),
  loading: () => <LoadingView />
});

const PaymentMembers = loadable({
  loader: () => import("./container/tournament/paymentsMember"),
  loading: () => <LoadingView />
});

const PaymentsMemberCssa = loadable({
  loader: () => import("./container/payment/paymentsMemberCssa"),
  loading: () => <LoadingView />
});

const PaymentsMemberClub = loadable({
  loader: () => import("./container/payment/paymentMemberClub"),
  loading: () => <LoadingView />
});

const PaymentMembersPlatform = loadable({
  loader: () => import("./container/payment/paymentsMemberPlatform"),
  loading: () => <LoadingView />
});

const PaymentsClubs = loadable({
  loader: () => import("./container/payment/paymentsClubs"),
  loading: () => <LoadingView />
});

const ManageProduct = loadable({
  loader: () => import("./container/product/manageProduct"),
  loading: () => <LoadingView />
});
const ProductPrice = loadable({
  loader: () => import("./container/product/productPrice"),
  loading: () => <LoadingView />
});

const AllTransactions = loadable({
  loader: () => import("./container/payment/allTransactions"),
  loading: () => <LoadingView />
});

const MyTransactions = loadable({
  loader: () => import("./container/payment/myTransactions"),
  loading: () => <LoadingView />
});

const TransactionSuccess = loadable({
  loader: () => import("./container/payment/transactionSuccess"),
  loading: () => <LoadingView />
});

const TransactionFailed = loadable({
  loader: () => import("./container/payment/transactionFailed"),
  loading: () => <LoadingView />
});

const OverLay = loadable({
  loader: () => import("./container/overLay"),
  loading: () => <LoadingView />
});

const LiveScoring = loadable({
  loader: () => import("./container/home/live-scoring"),
  loading: () => <LoadingView />
});

const AddTable = loadable({
  loader: () => import("./container/clubSuperAdmin/addTable"),
  loading: () => <LoadingView />
});

const PrivacyPolicy = loadable({
  loader: () => import("./container/privacyPolicy"),
  loading: () => <LoadingView />
});

const AppPrivacyPolicy = loadable({
  loader: () => import("./container/appPrivacyPolicy"),
  loading: () => <LoadingView />
});

const SponsoredTransactions = loadable({
  loader: () => import("./container/payment/sponsoredTransactions"),
  loading: () => <LoadingView />
});

const Reports = loadable({
  loader: () => import("./container/payment/reports"),
  loading: () => <LoadingView />
});

const PaymentReport = loadable({
  loader: () => import("./container/payment/paymentReport"),
  loading: () => <LoadingView />
});

const AboutUs = loadable({
  loader: () => import("./container/aboutus"),
  loading: () => <LoadingView />
});

const ManageTableSchedule = loadable({
  loader: () => import("./container/clubSuperAdmin/manageTableSchedule"),
  loading: () => <LoadingView />
});

const AddPlayerInTournament = loadable({
  loader: () => import("./container/tournament/addPlayerInTournament"),
  loading: () => <LoadingView />
});
const ManageRanking = loadable({
  loader: () => import("./container/admin/manageRanking"),
  loading: () => <LoadingView />
});
const ManageNews = loadable({
  loader: () => import("./container/news/manageNews"),
  loading: () => <LoadingView />
});
const ManageNewsDetail = loadable({
  loader: () => import("./container/news/newsDetail"),
  loading: () => <LoadingView />
});

const PlayerStatistics = loadable({
  loader: () => import("./container/my-profile/playerStats"),
  loading: () => <LoadingView />
});

const ClubAssociation = loadable({
  loader: () => import("./container/player/clubAssociationPage"),
  loading: () => <LoadingView />
});

const ManageRating = loadable({
  loader: () => import("./container/clubSuperAdmin/manageRating"),
  loading: () => <LoadingView />
});

const TournamentCard = loadable({
  loader: () => import("./container/home/tournamentCard"),
  loading: () => <LoadingView />
});

const LiveScoringIfram = loadable({
  loader: () => import("./container/home/live-scoring-iframe"),
  loading: () => <LoadingView />
});


class AppRouting extends Component {
  render() {
    const {
      PABSA_SUPER_ADMIN_VALUE,
      PABSA_ADMIN_VALUE,
      CLUB_SUPER_ADMIN_VALUE,
      CLUB_ADMIN_VALUE,
      PLAYER_VALUE
    } = constant

    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/set-password" exact component={SetPassword} />
          <Route path="/activateMember" exact component={AddMember} />
          <Route path="/playerClubAssociation" exact component={ClubAssociation} />
          <Route path="/home" exact component={Home} />
          <Route exact path="/live-scoring" component={LiveScoring} />
          <Route exact path="/home/:matchId" component={Home} />
          <Route exact path="/tournamentInfo/:tournamentId" component={TournamentInfo} />
          <Route exact path="/termsConditions" component={TermsConditions} />
          <Route exact path="/eulaTermsConditions" component={TermsConditionsEula} />
          <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/appPrivacyPolicy" component={AppPrivacyPolicy} />          
          {/* <Route exact path="/aboutus" component={AboutUs} /> */}
          <Route exact path="/overlay" component={OverLay} />
          <Route exact path="/overlay-streaming" component={OverLay} />
          <Route exact path="/clubtable" component={ManageClubTable} />
          <Route exact path="/all-news" component={ManageNews} />
          <Route exact path="/faq" component={faq} />
          <Route exact path="/all-news/view" component={ManageNewsDetail} />
          <Route exact path="/view-news/:id" component={ManageNewsDetail} />
          <Route path="/tournament-details" exact component={TournamentCard} />
          <Route path="/live-scoring-iframe" component={LiveScoringIfram} />
          <Route path="/pageNotFound" component={PageNotFound} />
          <AuthenticatedRoute exact path="/onboardPage" component={OnboardPage} />
          <Route>
            <AuthenticatedRoute exact component={Header} />
            <div className="main-container b_g_color">
              <SideBar />
              <AuthenticatedRoute
                path="/news"
                exact
                component={ManageNews}
              />
              <AuthenticatedRoute
                path="/club-management"
                exact
                component={ClubManagement}
                role={[PABSA_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/clubChannelList"
                exact
                component={ClubChannelList}
                role={[PABSA_SUPER_ADMIN_VALUE, CLUB_SUPER_ADMIN_VALUE]}
              />
              {/* <AuthenticatedRoute
                path="/user-management"
                exact
                component={UserManagement}
              /> */}
              <AuthenticatedRoute path="/profile">
                <div className="my-account-wrapper d-md-flex flex-md-column h-100">
                  <h3 className="mb-3">My Account</h3>
                  <div className="my-account clearfix">
                    <div className=" d-none d-lg-block float-left">
                      <AuthenticatedRoute component={MyProfileSidebar} />
                    </div>
                    <div className="sidebar-details">
                      <AuthenticatedRoute
                        path="/profile/change-password"
                        exact
                        component={ChangePassword}
                      />
                      <AuthenticatedRoute
                        path="/profile/my-profile"
                        exact
                        component={MyProfile}
                      />
                      <AuthenticatedRoute
                        path="/profile/payment"
                        exact
                        component={Payment}
                        role={[CLUB_SUPER_ADMIN_VALUE, PLAYER_VALUE]}
                      />
                      <AuthenticatedRoute
                        path="/profile/onboard"
                        exact
                        component={ConnectOnboard}
                        role={[CLUB_SUPER_ADMIN_VALUE]}
                      />
                    </div>
                  </div>
                </div>
              </AuthenticatedRoute>
              <AuthenticatedRoute
                path="/add-club"
                exact
                component={AddClub}
                role={[PABSA_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/add-player"
                exact
                component={AddPlayer}
                role={[PABSA_SUPER_ADMIN_VALUE,
                  PABSA_ADMIN_VALUE,
                  CLUB_SUPER_ADMIN_VALUE,
                  CLUB_ADMIN_VALUE
                ]}
              />
              <AuthenticatedRoute
                path="/club-admin"
                exact
                component={ClubAdmin}
                role={[CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/club-table"
                exact
                component={ManageClubTable}
                role={[CLUB_SUPER_ADMIN_VALUE]}
              />
                 <AuthenticatedRoute
                path="/club-rating"
                exact
                component={ManageRating}
                role={[CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/statistics"
                exact
                component={PlayerStatistics}
                role={[PLAYER_VALUE]}
              />
              <AuthenticatedRoute
                path="/food-beverage"
                exact
                component={FoodBeverage}
                role={[CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/add-table"
                exact
                component={AddTable}
                role={[CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/addPlayer-tournament"
                exact
                component={AddPlayerInTournament}
                role={[PLAYER_VALUE]}
                isNonMemberAllowed={false}
              />
              <AuthenticatedRoute
                path="/player"
                exact
                component={ManagePlayer}
                role={[
                  PABSA_SUPER_ADMIN_VALUE,
                  PABSA_ADMIN_VALUE,
                  CLUB_SUPER_ADMIN_VALUE,
                  CLUB_ADMIN_VALUE,
                ]}
              />
              <AuthenticatedRoute
                path="/match"
                exact
                component={ManageMatch}
              />
              <AuthenticatedRoute
                path="/create-match"
                exact
                component={CreateMatch}
              />
              <AuthenticatedRoute
                path="/tournament"
                exact
                component={ManageTournament}
                role={[PABSA_SUPER_ADMIN_VALUE,
                  PABSA_ADMIN_VALUE,
                  CLUB_SUPER_ADMIN_VALUE,
                  CLUB_ADMIN_VALUE
                ]}
              />
              <AuthenticatedRoute
                path="/create-tournament"
                exact
                component={CreateTournament}
                role={[PABSA_SUPER_ADMIN_VALUE,
                  PABSA_ADMIN_VALUE,
                  CLUB_SUPER_ADMIN_VALUE,
                  CLUB_ADMIN_VALUE
                ]}
              />
              {/* <AuthenticatedRoute
                path="/my-matches-lists"
                exact
                component={PlayerMatchesLists}
              /> */}
              <AuthenticatedRoute
                path="/pabsa-admin"
                exact
                component={ClubAdmin}
                role={[PABSA_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/payments-members"
                exact
                component={PaymentMembers}
                role={[CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/payments-members-Cs"
                exact
                component={PaymentsMemberCssa}
                role={[PABSA_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/payments-members-club"
                exact
                component={PaymentsMemberClub}
                role={[PABSA_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/payments-members-platform"
                exact
                component={PaymentMembersPlatform}
                role={[PABSA_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/payments-clubs"
                exact
                component={PaymentsClubs}
                role={[PABSA_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/manageProduct"
                exact
                component={ManageProduct}
                role={[PABSA_SUPER_ADMIN_VALUE, CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/productPrice"
                exact
                component={ProductPrice}
                role={[PABSA_SUPER_ADMIN_VALUE, CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/productPrice/:id/:type"
                exact
                component={ProductPrice}
                role={[PABSA_SUPER_ADMIN_VALUE, CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/news/:id"
                exact
                component={ManageNewsDetail}
              />
              <AuthenticatedRoute
                path="/sponsored-transactions"
                exact
                component={SponsoredTransactions}
                role={[CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/all-transactions"
                exact
                component={AllTransactions}
                role={[PABSA_SUPER_ADMIN_VALUE, CLUB_SUPER_ADMIN_VALUE, PLAYER_VALUE]}
              />
              <AuthenticatedRoute
                path="/my-transactions"
                exact
                component={MyTransactions}
                role={[CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/transactionSuccess"
                exact
                component={TransactionSuccess}
                role={[PABSA_SUPER_ADMIN_VALUE, CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/transactionFailed"
                exact
                component={TransactionFailed}
                role={[PABSA_SUPER_ADMIN_VALUE, CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/reports"
                exact
                component={Reports}
                role={[PABSA_SUPER_ADMIN_VALUE, CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/payment-report"
                exact
                component={PaymentReport}
                role={[PABSA_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/manage-ranking"
                exact
                component={ManageRanking}
                role={[CLUB_SUPER_ADMIN_VALUE]}
              />
              <AuthenticatedRoute
                path="/schedule-table"
                exact
                component={ScheduleTable}
                role={[CLUB_SUPER_ADMIN_VALUE, PLAYER_VALUE]}
                isNonMemberAllowed={false}
              />
            </div>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default AppRouting;
