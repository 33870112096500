import {
  USER_LOGIN_START,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS
} from "../../constants/type";
import API from "../utils/api";
import UtilService from "../utils/utils";
import config from "../../constants/config";
import constant from "../../constants/constant";
import avatar from "../../assets/images/avatar-profile.png";
import UtilsService from "../../container/utils/utils";
import { data } from "jquery";

export const signIn = (userId, password, isRememberPassword, history,path, isTournamentEntry = false) => {
  return async function (dispatch) {
    dispatch({ type: USER_LOGIN_START });
    let encryptPassword = UtilService.encryptText(password);
    let dataParams = {
      email: userId,
      password: password,
      shouldRemember: isRememberPassword
    };

   await API.postRequest(`${config.BASE_URL}/login`, dataParams)
      .then(response => {
        UtilService.setInfoInStorage('localStorage', constant.EULA_ACCEPT, response.eulaNotAccepted)
        if (response.eulaNotAccepted) {
          dataParams.token = response.token
          UtilService.setInfoInStorage('localStorage', constant.CURR_USER, dataParams)
          history.push("/termsConditions")
          return 
        }
        UtilService.setInfoInStorage('localStorage', constant.IS_PASSWORD_REMEMBER, isRememberPassword)
        UtilService.setInfoInStorage('localStorage', constant.CURRENT_USER, response.userInfo)
        UtilService.setInfoInStorage('localStorage', constant.AUTH_TOKEN, response.token)

        let rollId = UtilsService.getUserSingleDataFromLoaclStorage('roleId')

        const getLocalUser = UtilService.getInfoFromStorage('localStorage', constant.CURRENT_USER) //localStorage.getItem("currentUser");
         //store clubId into localstorage
         if (response.hasOwnProperty('clubs')) {
          UtilService.setInfoInStorage('localStorage', constant.CLUBS, response.clubs)
        }
        if(!isTournamentEntry){
          if(path === 'first') {
            history.push({ pathname: '/profile/payment' })
            window.scrollTo(0, 0);
          }
          else if (getLocalUser.firstLogin === true) {
            history.push("/termsConditions")
          } else if (rollId === constant.PABSA_SUPER_ADMIN_VALUE) {
            history.push("/club-management")
          }
          else if (rollId === constant.PABSA_ADMIN_VALUE) {
            history.push("/player")
          }
          else if (rollId === constant.CLUB_SUPER_ADMIN_VALUE) {
            history.push("/club-table")
          }
          else if (rollId === constant.CLUB_ADMIN_VALUE) {
            history.push("/player")
          }
          else if (rollId === constant.PLAYER_VALUE) {
            history.push("/match")
          } else {
            history.push("/")
          }
        }
       

        if (isRememberPassword) {
          UtilService.setInfoInStorage('localStorage', constant.PASSWORD, encryptPassword)
          UtilService.setInfoInStorage('localStorage', constant.USER_ID, userId)
        }

        let getUserProfileImage = UtilService.getInfoFromStorage('localStorage', constant.CURRENT_USER).profileImage;
        if (!getUserProfileImage) {
          UtilService.updateLocalStorage(constant.CURRENT_USER, 'profileImage', avatar)
        }
        UtilService.setInfoInStorage('localStorage', constant.PASSWORD, encryptPassword)

        return dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: { logged_in_user: response }
        });
      })
      .catch(error => {
        console.log("Fali Response login", error)
        return dispatch({ type: USER_LOGIN_FAIL, payload: error.response });
      });
  };
};
