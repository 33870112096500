import React, { Component } from 'react';
import $ from "jquery";

class Delete extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         setId : ''
      }
    }
    // onClick={this.props.getSingleRecordId.bind(this, this.props.clubId)}
    deleteUser =(id)=>{
    this.setState({
        setId : id
    })
    $(`#deleteRecord${id}`).modal('show')
    }
    
    hideUser = () =>{

    }
    componentWillUnmount(){
        let id = this.state.setId
        $(`#deleteRecord${id}`).modal('hide')  
    }
    render() {
        return (
            <React.Fragment>
                <svg className="ml-3 cursor-pointer delete-club" height="18px" version="1.1" viewBox="0 0 14 18" width="14px"
                onClick={()=>this.deleteUser(this.props.clubId)} 
                data-toggle="modal" 
                // data-target={`#deleteRecord${this.props.clubId}`}
                >
                    <g fill="none" fillOpacity="0.5" fillRule="evenodd" id="Page-1" stroke="none"
                        strokeWidth="1">
                        <g fill="#000000" fillRule="nonzero" id="ic-edit"
                            transform="translate(-5.000000, -3.000000)">
                            <g id="ic-delete">
                                <path
                                    d="M6,19 C6.0032948,20.1032019 6.89679813,20.9967052 8,21 L16,21 C17.1032019,20.9967052 17.9967052,20.1032019 18,19 L18,7 L6,7 L6,19 Z M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 Z"
                                    id="Path_141"></path>
                            </g>
                        </g>
                    </g>
                </svg>
                <div className="modal fade" id={`deleteRecord${this.props.clubId}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="d-table w-100">
                        <div className="d-table-cell">
                            <div className="modal-dialog  modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <button type="button" className="close popup-close-btn" data-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><path d="M5.314 6.829l5.671 5.671-5.671 5.67a1.071 1.071 0 001.515 1.515l5.671-5.67 5.671 5.671a1.071 1.071 0 001.515-1.515L14.015 12.5l5.671-5.671a1.071 1.071 0 00-1.516-1.515l-5.67 5.671-5.671-5.671a1.071 1.071 0 00-1.515 1.515z" transform="translate(-5 -5)" fill="#666"></path></svg>
                                    </button>
                                    <div className="modal-body p-0 pt-3 text-center">
                                        <p>Are you sure you want to delete {this.props.message}?</p>
                                    </div>
                                    <div className="modal-footer p-0 mt-4 border-none justify-content-center">
                                        <button type="button" className="btn cancel-btn" data-dismiss="modal">No</button>
                                        <button type="button" className="btn red-btn ml-3" onClick={this.props.deleteIfYes.bind(this, this.props.clubId)} data-dismiss="modal">Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Delete;