import { cloneDeep } from "lodash";
import {
  USER_LOGIN_START,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_FAIL,
  SET_PASSWORD_FAIL,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_START,
  PROFILE_START,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  RESET,
  SIGNOUT,
  SIGNOUT_FAIL,
  SIGNOUT_START,
  ADD_CLUB_FAIL,
  ADD_CLUB_START,
  ADD_CLUB_SUCCESS,
  UNAUTHORIZED_USER,
  CLUB_EDIT_FAIL,
  CLUB_EDIT_START,
  CLUB_EDIT_SUCCESS,
  CLUB_DELETE_SUCCESS,
  ADD_CLUB_ADMIN_USER_START,
  ADD_CLUB_ADMIN_USER_SUCCESS,
  ADD_CLUB_ADMIN_USER_FAIL,
  API_FAIL,
  API_START,
  API_SUCCESS,
  DELETE_MATCH_SUCCESS,
  DELETE_MATCH_FAIL,
  CREATE_MATCH_FAIL,
  SAVECARD_SUCCESS,
  SAVECARD_START,
  SAVECARD_FAIL,
  UPDATECARD_START,
  UPDATECARD_FAIL,
  UPDATECARD_SUCCESS,
  DELETECARD_START,
  DELETECARD_FAIL,
  DELETECARD_SUCCESS,
  PROFILE_DETAIL_START,
  PROFILE_DETAIL_SUCCESS,
  PROFILE_DETAIL_FAIL,
} from "../constants/type";
const initialState = {
  has_error: false,
  is_loding: false,
  isShowMessage: false,
  unAuthorized_user: false,
  profileDetailData:null
};

export default (state = initialState, action) => {
  let currentState = cloneDeep(state);
  switch (action.type) {
    case USER_LOGIN_START:
    case FORGOT_PASSWORD_START:
    case SET_PASSWORD_START:
    case PROFILE_START:
    case CHANGE_PASSWORD_START:
    case SIGNOUT_START:
    case ADD_CLUB_START:
    case CLUB_EDIT_START:
    case SAVECARD_START:
    case UPDATECARD_START:
    case DELETECARD_START:
    case API_START:
    case PROFILE_DETAIL_START:
      currentState.is_loding = true;
      currentState.has_error = false;
      currentState.isShowMessage = false;
      currentState.unAuthorized_user = false;
      break;
    case USER_LOGIN_FAIL:
    case FORGOT_PASSWORD_FAIL:
    case SET_PASSWORD_FAIL:
    case PROFILE_FAIL:
    case CHANGE_PASSWORD_FAIL:
    case SIGNOUT_FAIL:
    case ADD_CLUB_FAIL:
    case CLUB_EDIT_FAIL:
    case API_FAIL:
    case SAVECARD_FAIL:
    case UPDATECARD_FAIL:
    case DELETECARD_FAIL:
    case DELETE_MATCH_FAIL:
    case PROFILE_DETAIL_FAIL:
      currentState.is_loding = false;
      currentState.has_error = true;
      currentState.isShowMessage = false;
      currentState.unAuthorized_user = false;
      break;
    case USER_LOGIN_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case SET_PASSWORD_SUCCESS:
    case PROFILE_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
    case SIGNOUT:
    case ADD_CLUB_SUCCESS:
    case CLUB_EDIT_SUCCESS:
    case API_SUCCESS:
    case SAVECARD_SUCCESS:
    case UPDATECARD_SUCCESS:
    case DELETECARD_SUCCESS:
    case DELETE_MATCH_SUCCESS:
      currentState.is_loding = false;
      currentState.has_error = false;
      currentState.isShowMessage = true;
      currentState.unAuthorized_user = false;
      break;
    case CLUB_DELETE_SUCCESS:
    case ADD_CLUB_ADMIN_USER_SUCCESS:
      currentState.is_loding = false;
      currentState.has_error = false;
      currentState.isShowMessage = true;
      currentState.unAuthorized_user = false;
      break;
    case PROFILE_DETAIL_SUCCESS:
      currentState.is_loding = false;
      currentState.has_error = false;
      currentState.isShowMessage = true;
      currentState.profileDetailData = false;

      break;  
    case ADD_CLUB_ADMIN_USER_START:
      currentState.is_loding = true;
      currentState.has_error = false;
      currentState.isShowMessage = false;
      currentState.unAuthorized_user = false;
      break;
    case ADD_CLUB_ADMIN_USER_FAIL:
    case CREATE_MATCH_FAIL:
      currentState.is_loding = false;
      currentState.has_error = true;
      currentState.isShowMessage = true;
      currentState.unAuthorized_user = false;
      break;
    case RESET:
      currentState.is_loding = false;
      currentState.has_error = false;
      currentState.isShowMessage = false;
      currentState.unAuthorized_user = false;
      break;
    case UNAUTHORIZED_USER:
      currentState.unAuthorized_user = true;
      break
    default:
      return currentState;
  }
  return currentState;
};
