import { SIGNOUT, RESET, SIGNOUT_FAIL, NO_INTERNET_CONNECTION } from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
  hasLogOut: false,
  signOutError: null
};

export default (state = INITIAL_STATE, action) => {
  let currentState = cloneDeep(state);
  switch (action.type) {
    case SIGNOUT:
      currentState.signOutError = null
      currentState.hasLogOut = true;
      break;
    case RESET:
      currentState.hasLogOut = false;
      currentState.signOutError = null
      break;
    case SIGNOUT_FAIL:
      currentState.hasLogOut = false;
      currentState.signOutError = null
      break;
    case NO_INTERNET_CONNECTION:
      currentState.hasLogOut = false;
      currentState.signOutError = action.payload
      break
    default:
      return currentState;
  }
  return currentState;
};
