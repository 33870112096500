import {
  SIGNOUT,
  SIGNOUT_FAIL,
  NO_INTERNET_CONNECTION,
} from "../constants/type";
import API from "./utils/api";
import config from "../constants/config";
import constant from "../constants/constant";

export const signOutAction = () => {
  return function (dispatch) {
    API.getRequest(`${config.BASE_URL}/signout`)
      .then(response => {
        //success API response
        dispatch({ type: SIGNOUT });
      })
      .catch(error => {
        if (!error.response) {
          // network error
          return dispatch({ type: NO_INTERNET_CONNECTION, payload: constant.NO_INTERNET_CONNECTION });
        } else {
          return dispatch({ type: SIGNOUT_FAIL, payload: error.response.data.message });
        }

      });
  };
}