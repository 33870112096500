import {
    CLUB_EDIT_FAIL,
    CLUB_EDIT_SUCCESS,
    CLUB_EDIT_START,
    CLUB_EDIT_RESET
} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
    isClubEditApiSuccess: false,
    clubEditSuccessResponse: null,
    clubEditFailResponse: null
};

export default (state = INITIAL_STATE, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
        case CLUB_EDIT_START:
            currentState.isClubEditApiSuccess = false;
            break;
        case CLUB_EDIT_SUCCESS:
            currentState.isClubEditApiSuccess = true;
            currentState.clubEditSuccessResponse = action.payload;
            break;
        case CLUB_EDIT_FAIL:
            currentState.isClubEditApiSuccess = false;
            currentState.clubEditFailResponse = action.payload;
            break;
        case CLUB_EDIT_RESET:
            currentState.isClubEditApiSuccess = false;
            currentState.clubEditSuccessResponse = null;
            currentState.clubEditFailResponse = null;
            break;
        default:
            return currentState;
    }
    return currentState;
};
