import {
    GET_ALL_NEWS_START,
    GET_ALL_NEWS_SUCCESS,
    GET_ALL_NEWS_FAIL,
    ADD_NEW_NEWS_START,
    ADD_NEW_NEWS_SUCCESS,
    ADD_NEW_NEWS_FAIL,
    UPDATE_NEWS_START,
    UPDATE_NEWS_SUCCESS,
    UPDATE_NEWS_FAIL,
    DELETE_NEWS_START,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAIL,
    GET_ALL_MEMBER_CLOUD_NEWS_START,
    GET_ALL_MEMBER_CLOUD_NEWS_SUCCESS,
    GET_ALL_MEMBER_CLOUD_NEWS_FAIL,
    GET_ALL_MEMBER_CLUB_NEWS_START,
    GET_ALL_MEMBER_CLUB_NEWS_SUCCESS,
    GET_ALL_MEMBER_CLUB_NEWS_FAIL,
    } from "../constants/type";
  import { cloneDeep } from "lodash";
  
  const INITIAL_STATE = {
    isLoading:false,
    newsList: null,
    addNewsRes: null,
    updateNewsRes: null,
    deleteNewsRes: null,
    memberCloudNewsList: null,
    memberClubNewsList: null,
  };
  
  export default (state = INITIAL_STATE, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
      case GET_ALL_NEWS_START:
        currentState.isLoading = true;
        break;
      case GET_ALL_NEWS_SUCCESS:
        currentState.isLoading = false;
        currentState.newsList = action.payload;
        break;
      case GET_ALL_NEWS_FAIL:
        currentState.isLoading = false;
        currentState.newsList = action.payload;
        break;
      case ADD_NEW_NEWS_START:
        currentState.isLoading = true;
        break;
      case ADD_NEW_NEWS_SUCCESS:
        currentState.isLoading = false;
        currentState.addNewsRes = action.payload;
        break;
      case ADD_NEW_NEWS_FAIL:
        currentState.isLoading = false;
        currentState.addNewsRes = action.payload;
        break;
      case UPDATE_NEWS_START:
        currentState.isLoading = true;
        break;
      case UPDATE_NEWS_SUCCESS:
        currentState.isLoading = false;
        currentState.updateNewsRes = action.payload;
        break;
      case UPDATE_NEWS_FAIL:
        currentState.isLoading = false;
        currentState.updateNewsRes = action.payload;
        break;
      case DELETE_NEWS_START:
        currentState.isLoading = true;
        break;
      case DELETE_NEWS_SUCCESS:
        currentState.isLoading = false;
        currentState.deleteNewsRes = action.payload;
        break;
      case DELETE_NEWS_FAIL:
        currentState.isLoading = false;
        currentState.deleteNewsRes = action.payload;
        break;
      case GET_ALL_MEMBER_CLOUD_NEWS_START:
        currentState.isLoading = true;
        break;
      case GET_ALL_MEMBER_CLOUD_NEWS_SUCCESS:
        currentState.isLoading = false;
        currentState.memberCloudNewsList = action.payload;
        break;
      case GET_ALL_MEMBER_CLOUD_NEWS_FAIL:
        currentState.isLoading = false;
        currentState.memberCloudNewsList = action.payload;
        break; 
      case GET_ALL_MEMBER_CLUB_NEWS_START:
      currentState.isLoading = true;
      break;
      case GET_ALL_MEMBER_CLUB_NEWS_SUCCESS:
        currentState.isLoading = false;
        currentState.memberClubNewsList = action.payload;
        break;
      case GET_ALL_MEMBER_CLUB_NEWS_FAIL:
        currentState.isLoading = false;
        currentState.memberClubNewsList = action.payload;
        break; 
      
      default:
        return currentState;
    }
    return currentState;
  };
  