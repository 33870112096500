import React, { Component } from 'react';
import { signOutAction } from "./signoutAction";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { get } from "lodash";
import { toast } from "react-toastify";
import ResetAction from '.././commonAction/ResetAction'
import UtilService from './utils/utils';
// import constant from '../constants/constant';
import { withRouter } from "react-router-dom";
import { compose } from "redux";

class SignOut extends Component {
    constructor(props) {
        super(props);
        this.signOut = this.signOut.bind(this);
    }

    signOut = () => {
        const path = this.props.location.pathname;
        if (path.includes("/all-news") || path.includes("/view-news")) {
            UtilService.locaStorageClear()
        }
        this.props.dispatch(signOutAction());

    };

    render() {
        //console.log("--------------", this.props);
        //  let isLoggedIn = get(this.props, "login.isLoggedIn", false);
        const isSignOutSuccessfully = get(this.props, "ui.isShowMessage", false);
        const hasLogOut = get(this.props, "header.hasLogOut", false);
        const signOutError = get(this.props, "header.signOutError", null);
        //console.log("SignOutAlways Called")
        //console.log("Sign Out called ==>> ", this.props)
        if (isSignOutSuccessfully && hasLogOut) {
            UtilService.locaStorageClear()
            //localStorage.setItem('logout-event', 'logout' + Math.random());
            return <Redirect to="/" />;
        }

        if (signOutError != null) {
            this.componentWillUnmount();
            toast.warn(signOutError) 
        }
        return (
            <span onClick={this.signOut} className="cursor-pointer d-flex align-items-center signout-btn">
                <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="22.918" height="23.651" viewBox="0 0 22.918 23.651">
                    <g id="prefix__ic_signout" transform="translate(-9.2 -8.8)">
                        <g id="prefix__Group_6563" data-name="Group 6563" transform="translate(9.4 9)">
                            <path id="prefix__Path_5236" d="M37.311 42.412a1.073 1.073 0 00.72.277 1.121 1.121 0 00.83-.388l3.488-4.041a1.094 1.094 0 000-1.439l-3.488-4.041a1.1 1.1 0 00-1.661 1.439l1.91 2.214h-7a1.107 1.107 0 000 2.214h7l-1.91 2.215a1.072 1.072 0 00.111 1.55z" data-name="Path 5236" transform="translate(-25.021 -25.915)" fill="#333333" stroke="#ffffff" strokeWidth=".4px" />
                            <path id="prefix__Path_5237" d="M29.744 17.055a1.105 1.105 0 102.048-.83 11.625 11.625 0 100 8.8 1.105 1.105 0 10-2.048-.83 9.411 9.411 0 110-7.141z" data-name="Path 5237" transform="translate(-9.4 -9)" fill="#333333" stroke="#ffffff" strokeWidth=".4px" />
                        </g>
                    </g>
                </svg>
                Sign Out
            </span>
        );
    }

    componentWillUnmount() {
        this.props.dispatch(ResetAction());
    }
}


// This will call to reducers
const mapStateToProps = state => {
    return {
        header: state.signoutReducer,
        ui: state.ui,
        // login: state.loginReducer,

    };
};

// This will call to action
const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(SignOut);