import {
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS
} from "../../constants/type";
import myApi from "../utils/api";
import config from "../../constants/config";

export const forgotPasswordAction = emailId => {
  return function (dispatch) {
    dispatch({ type: FORGOT_PASSWORD_START });
    const data = {
      email: emailId
    };
    myApi
      .putRequest(`${config.BASE_URL}/user/forgotPassword`, data)
      .then(response => {
        dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: response.message });
      })
      .catch(error => {
        dispatch({ type: FORGOT_PASSWORD_FAIL, payload: error });
      });
  };
};
