// import { constant } from "lodash";

export const USER_LOGIN_START = "USER_LOGIN_START";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const SET_PASSWORD_START = "SET_PASSWORD_START";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAIL = "SET_PASSWORD_FAIL";
 // Profile type constant define...
export const PROFILE_START = "PROFILE_START";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAIL = "PROFILE_FAIL";
export const PROFILE_DETAIL_START = "PROFILE_DETAIL_START";
export const PROFILE_DETAIL_SUCCESS = "PROFILE_DETAIL_SUCCESS";
export const PROFILE_DETAIL_FAIL = "PROFILE_DETAIL_FAIL";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const RESET = "RESET";
export const SIGNOUT_START = "SIGNOUT_START";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_FAIL = "SIGNOUT_FAIL";

export const ADD_CLUB_START = "ADD_CLUB_START";
export const ADD_CLUB_SUCCESS = "ADD_CLUB_SUCCESS";
export const ADD_CLUB_FAIL = "ADD_CLUB_FAIL";

export const COUNTRY_API_START = "COUNTRY_API_START";
export const COUNTRY_API_SUCCESS = "COUNTRY_API_SUCCESS";
export const COUNTRY_API_FAIL = "COUNTRY_API_FAIL";
export const NO_INTERNET_CONNECTION = "NO_INTERNET_CONNECTION";

export const UNAUTHORIZED_USER = "UNAUTHORIZED_USER";

export const CLUB_LIST_START = "CLUB_LIST_START";
export const CLUB_LIST_SUCCESS = "CLUB_LIST_SUCCESS";
export const CLUB_LIST_FAIL = "CLUB_LIST_FAIL";

export const CLUB_ACTIVE_START = "CLUB_ACTIVE_START";
export const CLUB_ACTIVE_SUCCESS = "CLUB_ACTIVE_SUCCESS";
export const CLUB_ACTIVE_FAIL = "CLUB_ACTIVE_FAIL";

export const CLUB_SUSPENDED_START = "CLUB_SUSPENDED_START";
export const CLUB_SUSPENDED_SUCCESS = "CLUB_SUSPENDED_SUCCESS";
export const CLUB_SUSPENDED_FAIL = "CLUB_SUSPENDED_FAIL";

export const CLUB_DELETE_START = "CLUB_DELETE_START";
export const CLUB_DELETE_SUCCESS = "CLUB_DELETE_SUCCESS";
export const CLUB_DELETE_FAIL = "CLUB_DELETE_FAIL";

export const CLUB_SINGLE_DETAILS_START = "CLUB_SINGLE_DETAILS_START";
export const CLUB_SINGLE_DETAILS_SUCCESS = "CLUB_SINGLE_DETAILS_SUCCESS";
export const CLUB_SINGLE_DETAILS_FAIL = "CLUB_SINGLE_DETAILS_FAIL";

export const CLUB_EDIT_START = "ADD_EDIT_START";
export const CLUB_EDIT_SUCCESS = "ADD_EDIT_SUCCESS";
export const CLUB_EDIT_FAIL = "ADD_EDIT_FAIL";

export const CLUB_EDIT_RESET = "CLUB_EDIT_RESET";
export const ADD_CLUB_ADMIN_USER_START = "ADD_CLUB_ADMIN_USER_START";
export const ADD_CLUB_ADMIN_USER_SUCCESS = "ADD_CLUB_ADMIN_USER_SUCCESS";
export const ADD_CLUB_ADMIN_USER_FAIL = "ADD_CLUB_ADMIN_USER_FAIL";

export const GET_CLUB_ADMIN_USER_START = "GET_CLUB_ADMIN_USER_START";
export const GET_CLUB_ADMIN_USER_SUCCESS = "GET_CLUB_ADMIN_USER_SUCCESS";
export const GET_CLUB_ADMIN_USER_FAIL = "GET_CLUB_ADMIN_USER_FAIL";

export const ADMIN_USER_DELETE_START = 'ADMIN_USER_DELETE_START';
export const ADMIN_USER_DELETE_SUCCESS = 'ADMIN_USER_DELETE_SUCCESS';
export const ADMIN_USER_DELETE_FAIL = 'ADMIN_USER_DELETE_FAIL';

export const ADMIN_USER_DETAILS_START = 'ADMIN_USER_DETAILS_START';
export const ADMIN_USER_DETAILS_SUCCESS = 'ADMIN_USER_DETAILS_SUCCESS';
export const ADMIN_USER_DETAILS_FAIL = 'ADMIN_USER_DETAILS_FAIL';

export const API_START = 'API_START';
export const API_SUCCESS = 'API_SUCCESS';
export const API_FAIL = 'API_FAIL';

export const EDIT_PLAYER_DETAILS_START = 'EDIT_PLAYER_DETAILS_START';
export const EDIT_PLAYER_DETAILS_SUCCESS = 'EDIT_PLAYER_DETAILS_SUCCESS';
export const EDIT_PLAYER_DETAILS_FAIL = 'EDIT_PLAYER_DETAILS_FAIL';

export const DELETE_ROW_START = 'DELETE_ROW_START';
export const DELETE_ROW_SUCCESS = 'DELETE_ROW_SUCCESS';
export const DELETE_ROW_FAIL = 'DELETE_ROW_FAIL';
export const EDIT_DELETE_RESET = 'EDIT_DELETE_RESET';

export const CLUB_NAME_LIST_START = 'CLUB_NAME_LIST_START';
export const CLUB_NAME_LIST_SUCCESS = 'CLUB_NAME_LIST_SUCCESS';
export const CLUB_NAME_LIST_FAIL = 'CLUB_NAME_LIST_FAIL';

export const PLAYER_DETAILS_BY_EMAIL_START = 'PLAYER_DETAILS_BY_EMAIL_START';
export const PLAYER_DETAILS_BY_EMAIL_SUCCESS = 'PLAYER_DETAILS_BY_EMAIL_SUCCESS';
export const PLAYER_DETAILS_BY_EMAIL_FAIL = 'PLAYER_DETAILS_BY_EMAIL_FAIL';

export const API_START_OTHER = 'API_START_OTHER';
export const API_SUCCESS_OTHER = 'API_SUCCESS_OTHER';
export const API_FAIL_OTHER = 'API_FAIL_OTHER';

export const EDIT_DETAILS_START = 'EDIT_DETAILS_START';
export const EDIT_DETAILS_SUCCESS = 'EDIT_DETAILS_SUCCESS';
export const EDIT_DETAILS_FAIL = 'EDIT_DETAILS_FAIL';

export const CREATE_MATCH_START = 'CREATE_MATCH_START';
export const CREATE_MATCH_SUCCESS = 'CREATE_MATCH_SUCCESS';
export const CREATE_MATCH_FAIL = 'CREATE_MATCH_FAIL';

export const FILTER_MATCH_LIST_START = 'FILTER_MATCH_LIST_START';
export const FILTER_MATCH_LIST_SUCCESS = 'FILTER_MATCH_LIST_SUCCESS';
export const FILTER_MATCH_LIST_FAIL = 'FILTER_MATCH_LIST_FAIL';

export const GET_MATCH_LIST_START = 'GET_MATCH_LIST_START';
export const GET_MATCH_LIST_SUCCESS = 'GET_MATCH_LIST_SUCCESS';
export const GET_MATCH_LIST_FAIL = 'GET_MATCH_LIST_FAIL';

export const DELETE_MATCH_START = 'DELETE_MATCH_START';
export const DELETE_MATCH_SUCCESS = 'DELETE_MATCH_SUCCESS';
export const DELETE_MATCH_FAIL = 'DELETE_MATCH_FAIL';

export const EDIT_MATCH_START = 'EDIT_MATCH_START';
export const EDIT_MATCH_SUCCESS = 'EDIT_MATCH_SUCCESS';
export const EDIT_MATCH_FAIL = 'EDIT_MATCH_FAIL';

export const SEND_MAIL_START = 'SEND_MAIL_START';
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS';
export const SEND_MAIL_FAIL = 'SEND_MAIL_FAIL';
export const RESET_SEND_MAIL = 'RESET_SEND_MAIL';

export const ROUND_ROBIN_START = 'ROUND_ROBIN_START';
export const ROUND_ROBIN_SUCCESS = 'ROUND_ROBIN_SUCCESS';
export const ROUND_ROBIN_FAIL = 'ROUND_ROBIN_FAIL';
export const ROUND_ROBIN_RESET = 'ROUND_ROBIN_RESET';

export const ADD_PLAYER_TOURNAMENT_START = 'ADD_PLAYER_TOURNAMENT_START';
export const ADD_PLAYER_TOURNAMENT_SUCCESS = 'ADD_PLAYER_TOURNAMENT_SUCCESS';
export const ADD_PLAYER_TOURNAMENT_FAIL = 'ADD_PLAYER_TOURNAMENT_FAIL';

export const KNOCKOUT_START = 'KNOCKOUT_START';
export const KNOCKOUT_SUCCESS = 'KNOCKOUT_SUCCESS';
export const KNOCKOUT_FAIL = 'KNOCKOUT_FAIL';
export const KNOCKOUT_RESET = 'KNOCKOUT_RESET';

export const TOURNAMENT_LIST_START = 'TOURNAMENT_LIST_START';
export const TOURNAMENT_LIST_SUCCESS = 'TOURNAMENT_LIST_SUCCESS';
export const TOURNAMENT_LIST_FAIL = 'TOURNAMENT_LIST_FAIL';

export const UPCOMING_TOURNAMENT_LIST_START = 'UPCOMING_TOURNAMENT_LIST_START';
export const UPCOMING_TOURNAMENT_LIST_SUCCESS = 'UPCOMING_TOURNAMENT_LIST_SUCCESS';
export const UPCOMING_TOURNAMENT_LIST_FAIL = 'UPCOMING_TOURNAMENT_LIST_FAIL';

export const API_START_LISTING = 'API_START_LISTING';
export const API_SUCCESS_LISTING = 'API_SUCCESS_LISTING';
export const API_FAIL_LISTING = 'API_FAIL_LISTING';

export const ADD_EDIT_LICENCE_START = 'ADD_EDIT_LICENCE_START';
export const ADD_EDIT_LICENCE_SUCCESS = 'ADD_EDIT_LICENCE_SUCCESS';
export const ADD_EDIT_LICENCE_FAIL = 'ADD_EDIT_LICENCE_FAIL';

export const TOURNAMENT_MATCH_DETAILS_START = 'TOURNAMENT_MATCH_DETAILS_START';
export const TOURNAMENT_MATCH_DETAILS_SUCCESS = 'TOURNAMENT_MATCH_DETAILS_SUCCESS';
export const TOURNAMENT_MATCH_DETAILS_FAIL = 'TOURNAMENT_MATCH_DETAILS_FAIL';

export const GET_LIVE_MATCHES_START = "GET_LIVE_MATCHES_START";
export const GET_LIVE_MATCHES_SUCCESS = "GET_LIVE_MATCHES_SUCCESS";
export const GET_LIVE_MATCHES_FAIL = "GET_LIVE_MATCHES_FAIL";

export const GET_UPCOMING_MATCHES_START = "GET_UPCOMING_MATCHES_START";
export const GET_UPCOMING_MATCHES_SUCCESS = "GET_UPCOMING_MATCHES_SUCCESS";
export const GET_UPCOMING_MATCHES_FAIL = "GET_UPCOMING_MATCHES_FAIL";

export const GET_PAST_MATCHES_START = "GET_PAST_MATCHES_START";
export const GET_PAST_MATCHES_SUCCESS = "GET_PAST_MATCHES_SUCCESS";
export const GET_PAST_MATCHES_FAIL = "GET_PAST_MATCHES_FAIL";

export const SAVECARD_START = "SAVECARD_START";
export const SAVECARD_SUCCESS = "SAVECARD_SUCCESS";
export const SAVECARD_FAIL = "SAVECARD_FAIL";

export const GETCARD_DETAILS_START = "GETCARD_DETAILS_START";
export const GETCARD_DETAILS_SUCCESS = "GETCARD_DETAILS_SUCCESS";
export const GETCARD_DETAILS_FAIL = "GETCARD_DETAILS_FAIL";

export const RELEASE_CHANNEL_DETAILS_START = "RELEASE_CHANNEL_DETAILS_START";
export const RELEASE_CHANNEL_DETAILS_SUCCESS = "RELEASE_CHANNEL_DETAILS_SUCCESS";
export const RELEASE_CHANNEL_DETAILS_FAIL = "RELEASE_CHANNEL_DETAILS_FAIL";

export const UPDATECARD_START = "UPDATECARD_START";
export const UPDATECARD_SUCCESS = "UPDATECARD_SUCCESS";
export const UPDATECARD_FAIL = "UPDATECARD_FAIL";

export const DELETECARD_START = "DELETECARD_START";
export const DELETECARD_SUCCESS = "DELETECARD_SUCCESS";
export const DELETECARD_FAIL = "DELETECARD_FAIL";


export const INITIATE_PAYMENT_START = "INITIATE_PAYMENT_START";
export const INITIATE_PAYMENT_SUCCESS = "INITIATE_PAYMENT_SUCCESS";
export const INITIATE_PAYMENT_FAIL = "INITIATE_PAYMENT_FAIL";

export const OVERLAY_SCORE_START = "OVERLAY_SCORE_START";
export const OVERLAY_SCORE_SUCCESS = "OVERLAY_SCORE_SUCCESS";
export const OVERLAY_SCORE_FAIL = "INITIATE_PAYMENT_FAIL";

export const INITIATE_MYTRANSACTION_START = "INITIATE_MYTRANSACTION_START";
export const INITIATE_MYTRANSACTION_SUCCESS = "INITIATE_MYTRANSACTION_SUCCESS";
export const INITIATE_MYTRANSACTION_FAIL = "INITIATE_MYTRANSACTION_FAIL";


export const PAYMENT_START = "PAYMENT_START";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAIL = "PAYMENT_FAIL";

export const TRANSACTION_START = "TRANSACTION_START";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_FAIL = "TRANSACTION_FAIL";

export const PAIDCTA_START = "PAIDCTA_START";
export const PAIDCTA_SUCCESS = "PAIDCTA_SUCCESS";
export const PAIDCTA_FAIL = "PAIDCTA_FAIL";

export const CREATE_TABLE_START = 'CREATE_TABLE_START';
export const CREATE_TABLE_SUCCESS = 'CREATE_TABLE_SUCCESS';
export const CREATE_TABLE_FAIL = 'CREATE_TABLE_FAIL';

export const DELETE_TABLE_START = 'DELETE_TABLE_START';
export const DELETE_TABLE_SUCCESS = 'DELETE_TABLE_SUCCESS';
export const DELETE_TABLE_FAIL = 'DELETE_TABLE_FAIL';

export const GET_CLUB_TABLE_START = 'GET_CLUB_TABLE_START';
export const GET_CLUB_TABLE_SUCCESS = 'GET_CLUB_TABLE_SUCCESS';
export const GET_CLUB_TABLE_FAIL = 'GET_CLUB_TABLE_FAIL';

export const EDIT_CLUB_TABLE_START = 'EDIT_CLUB_TABLE_START';
export const EDIT_CLUB_TABLE_SUCCESS = 'EDIT_CLUB_TABLE_SUCCESS';
export const EDIT_CLUB_TABLE_FAIL = 'EDIT_CLUB_TABLE_FAIL';

export const CREATE_FOOD_BEVERAGE_START = "CREATE_FOOD_BEVERAGE_START";
export const CREATE_FOOD_BEVERAGE_SUCCESS = 'CREATE_FOOD_BEVERAGE_SUCCESS';
export const CREATE_FOOD_BEVERAGE_FAIL = 'CREATE_FOOD_BEVERAGE_FAIL';

export const DELETE_FOOD_BEVERAGE_START = 'DELETE_FOOD_BEVERAGE_START';
export const DELETE_FOOD_BEVERAGE_SUCCESS = 'DELETE_FOOD_BEVERAGE_SUCCESS';
export const DELETE_FOOD_BEVERAGE_FAIL = 'DELETE_FOOD_BEVERAGE_FAIL';

export const GET_FOOD_BEVERAGE_START = 'GET_FOOD_BEVERAGE_START';
export const GET_FOOD_BEVERAGE_SUCCESS = 'GET_FOOD_BEVERAGE_SUCCESS';
export const GET_FOOD_BEVERAGE_FAIL = 'GET_FOOD_BEVERAGE_FAIL';

export const EDIT_FOOD_BEVERAGE_START = 'EDIT_FOOD_BEVERAGE_START';
export const EDIT_FOOD_BEVERAGE_SUCCESS = 'EDIT_FOOD_BEVERAGE_SUCCESS';
export const EDIT_FOOD_BEVERAGE_FAIL = 'EDIT_FOOD_BEVERAGE_FAIL';

export const CREATE_TABLERATE_START = 'CREATE_TABLERATE_START';
export const CREATE_TABLERATE_SUCCESS = 'CREATE_TABLERATE_SUCCESS';
export const CREATE_TABLERATE_FAIL = 'CREATE_TABLERATE_FAIL';

export const CREATE_LIGHT_CREDENTIAL_START = 'CREATE_LIGHT_CREDENTIAL_START';
export const CREATE_LIGHT_CREDENTIAL_SUCCESS = 'CREATE_LIGHT_CREDENTIAL_SUCCESS';
export const CREATE_LIGHT_CREDENTIAL_FAIL = 'CREATE_LIGHT_CREDENTIAL_FAIL';

export const UPDATE_LIGHT_CONTROL_START = 'UPDATE_LIGHT_CONTROL_START';
export const UPDATE_LIGHT_CONTROL_SUCCESS = 'UPDATE_LIGHT_CONTROL_SUCCESS';
export const UPDATE_LIGHT_CONTROL_FAIL = 'UPDATE_LIGHT_CONTROL_FAIL';

export const GET_BILLHISTORY_START = "GET_BILLHISTORY_START";
export const GET_BILLHISTORY_SUCCESS = "GET_BILLHISTORY_SUCCESS,";
export const GET_BILLHISTORY_FAIL = "GET_BILLHISTORY_FAIL,";

export const GET_TABLE_PRICE_START = 'GET_TABLE_PRICE_START';
export const GET_TABLE_PRICE_SUCCESS = 'GET_TABLE_PRICE_SUCCESS';
export const GET_TABLE_PRICE_FAIL = 'GET_TABLE_PRICE_FAIL';
export const GET_CONNECT_ONBOARD_START = "GET_CONNECT_ONBOARD_START";
export const GET_CONNECT_ONBOARD_SUCCESS = "GET_CONNECT_ONBOARD_SUCCESS,";
export const GET_CONNECT_ONBOARD_FAIL = "GET_CONNECT_ONBOARD_FAIL,";

export const GET_TABLE_SHEDULE_START = 'GET_TABLE_SHEDULE_START';
export const GET_TABLE_SHEDULE_SUCCESS = 'GET_TABLE_SHEDULE_SUCCESS';
export const GET_TABLE_SHEDULE_FAIL = 'GET_TABLE_SHEDULE_FAIL';

export const CREATE_NEW_SLOT_START = 'CREATE_NEW_SLOT_START';
export const CREATE_NEW_SLOT_SUCCESS = 'CREATE_NEW_SLOT_SUCCESS';
export const CREATE_NEW_SLOT_FAIL = 'CREATE_NEW_SLOT_FAIL';

export const DELETE_SLOT_START = 'DELETE_SLOT_START';
export const DELETE_SLOT_SUCCESS = 'DELETE_SLOT_SUCCESS';
export const DELETE_SLOT_FAIL = 'DELETE_SLOT_FAIL';

export const EDIT_TABLE_SCHEDULE_START = 'EDIT_TABLE_SCHEDULE_START';
export const EDIT_TABLE_SCHEDULE_SUCCESS = 'EDIT_TABLE_SCHEDULE_SUCCESS';
export const EDIT_TABLE_SCHEDULE_FAIL = 'EDIT_TABLE_SCHEDULE_FAIL';

export const GET_OTHER_BOOKING_SHEDULE_START = 'GET_OTHER_BOOKING_SHEDULE_START';
export const GET_OTHER_BOOKING_SHEDULE_SUCCESS = 'GET_OTHER_BOOKING_SHEDULE_SUCCESS';
export const GET_OTHER_BOOKING_SHEDULE_FAIL = 'GET_OTHER_BOOKING_SHEDULE_FAIL'

export const GET_PRODUCT_LIST_START = "GET_PRODUCT_LIST_START";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAIL = "GET_PRODUCT_LIST_FAIL";

export const UPDATE_PRODUCT_LIST_START = "UPDATE_PRODUCT_LIST_START";
export const UPDATE_PRODUCT_LIST_SUCCESS = "UPDATE_PRODUCT_LIST_SUCCESS";
export const UPDATE_PRODUCT_LIST_FAIL = "UPDATE_PRODUCT_LIST_FAIL";

export const ADD_PRODUCT_LIST_START = "ADD_PRODUCT_LIST_START";
export const ADD_PRODUCT_LIST_SUCCESS = "ADD_PRODUCT_LIST_SUCCESS,";
export const ADD_PRODUCT_LIST_FAIL = "ADD_PRODUCT_LIST_FAIL,";

export const DELETE_PRODUCT_LIST_START = "DELETE_PRODUCT_LIST_START";
export const DELETE_PRODUCT_LIST_SUCCESS = "DELETE_PRODUCT_LIST_SUCCESS,";
export const DELETE_PRODUCT_LIST_FAIL = "DELETE_PRODUCT_LIST_FAIL,";

export const GET_PRODUCT_PRICE_LIST_START = "GET_PRODUCT_PRICE_LIST_START";
export const GET_PRODUCT_PRICE_LIST_SUCCESS = "GET_PRODUCT_PRICE_LIST_SUCCESS";
export const GET_PRODUCT_PRICE_LIST_FAIL = "GET_PRODUCT_PRICE_LIST_FAIL";

export const UPDATE_PRODUCT_PRICE_LIST_START = "UPDATE_PRODUCT_PRICE_LIST_START";
export const UPDATE_PRODUCT_PRICE_LIST_SUCCESS = "UPDATE_PRODUCT_PRICE_LIST_SUCCESS";
export const UPDATE_PRODUCT_PRICE_LIST_FAIL = "UPDATE_PRODUCT_PRICE_LIST_FAIL";

export const ADD_PRODUCT_PRICE_LIST_START = "ADD_PRODUCT_PRICE_LIST_START";
export const ADD_PRODUCT_PRICE_LIST_SUCCESS = "ADD_PRODUCT_PRICE_LIST_SUCCESS,";
export const ADD_PRODUCT_PRICE_LIST_FAIL = "ADD_PRODUCT_PRICE_LIST_FAIL,";

export const DELETE_PRODUCT_PRICE_LIST_START = "DELETE_PRODUCT_PRICE_LIST_START";
export const DELETE_PRODUCT_PRICE_LIST_SUCCESS = "DELETE_PRODUCT_PRICE_LIST_SUCCESS,";
export const DELETE_PRODUCT_PRICE_LIST_FAIL = "DELETE_PRODUCT_PRICE_LIST_FAIL,";

export const GET_CLUB_SUBSCRIPTION_LIST_START = "GET_CLUB_SUBSCRIPTION_LIST_START";
export const GET_CLUB_SUBSCRIPTION_LIST_SUCCESS = "GET_CLUB_SUBSCRIPTION_LIST_SUCCESS";
export const GET_CLUB_SUBSCRIPTION_LIST_FAIL = "GET_CLUB_SUBSCRIPTION_FAIL";

export const GET_MEMBER_SUBSCRIPTION_LIST_START = "GET_MEMBER_SUBSCRIPTION_LIST_START";
export const GET_MEMBER_SUBSCRIPTION_LIST_SUCCESS = "GET_MEMBER_SUBSCRIPTION_LIST_SUCCESS";
export const GET_MEMBER_SUBSCRIPTION_LIST_FAIL = "GET_MEMBER_SUBSCRIPTION_FAIL";

export const GET_PLATFORM_SUBSCRIPTION_LIST_START = "GET_PLATFORM_SUBSCRIPTION_LIST_START";
export const GET_PLATFORM_SUBSCRIPTION_LIST_SUCCESS = "GET_PLATFORM_SUBSCRIPTION_LIST_SUCCESS";
export const GET_PLATFORM_SUBSCRIPTION_LIST_FAIL = "GET_PLATFORM_SUBSCRIPTION_FAIL";

export const ADD_MEMBER_SUBSCRIPTION_LIST_START = "ADD_MEMBER_SUBSCRIPTION_LIST_START";
export const ADD_MEMBER_SUBSCRIPTION_LIST_SUCCESS = "ADD_MEMBER_SUBSCRIPTION_LIST_SUCCESS";
export const ADD_MEMBER_SUBSCRIPTION_LIST_FAIL = "ADD_MEMBER_SUBSCRIPTION_FAIL";

export const ADD_CLUB_SUBSCRIPTION_LIST_START = "ADD_CLUB_SUBSCRIPTION_LIST_START";
export const ADD_CLUB_SUBSCRIPTION_LIST_SUCCESS = "ADD_CLUB_SUBSCRIPTION_LIST_SUCCESS";
export const ADD_CLUB_SUBSCRIPTION_LIST_FAIL = "ADD_CLUB_SUBSCRIPTION_FAIL";

export const ADD_PLATFORM_SUBSCRIPTION_LIST_START = "ADD_PLATFORM_SUBSCRIPTION_LIST_START";
export const ADD_PLATFORM_SUBSCRIPTION_LIST_SUCCESS = "ADD_PLATFORM_SUBSCRIPTION_LIST_SUCCESS";
export const ADD_PLATFORM_SUBSCRIPTION_LIST_FAIL = "ADD_PLATFORM_SUBSCRIPTION_FAIL";

export const DELETE_SUBSCRIPTION_LIST_START = "DELETE_SUBSCRIPTION_LIST_START";
export const DELETE_SUBSCRIPTION_LIST_SUCCESS = "DELETE_SUBSCRIPTION_LIST_SUCCESS";
export const DELETE_SUBSCRIPTION_LIST_FAIL = "DELETE_SUBSCRIPTION_FAIL";

export const ADD_MEMBER_CREDIT_START = "ADD_MEMBER_CREDIT_START";
export const ADD_MEMBER_CREDIT_SUCCESS = "ADD_MEMBER_CREDIT_SUCCESS";
export const ADD_MEMBER_CREDIT_FAIL = "ADD_MEMBER_CREDIT_FAIL";

export const UPDATE_STRIPE_CONNECT_START = "UPDATE_STRIPE_CONNECT_START";
export const UPDATE_STRIPE_CONNECT_SUCCESS = "UPDATE_STRIPE_CONNECT_SUCCESS";
export const UPDATE_STRIPE_CONNECT_FAIL = "UPDATE_STRIPE_CONNECT_FAIL";

export const GET_PRODUCT_ENABLE_PRICE_LIST_START = "GET_PRODUCT_ENABLE_PRICE_LIST_START";
export const GET_PRODUCT_ENABLE_PRICE_LIST_SUCCESS = "GET_PRODUCT_ENABLE_PRICE_LIST_SUCCESS";
export const GET_PRODUCT_ENABLE_PRICE_LIST_FAIL = "GET_PRODUCT_ENABLE_PRICE_LIST_FAIL";

export const API_OTHER_DATA_RESET = 'API_OTHER_DATA_RESET';
export const ADD_FRAME_SCORE_START = "ADD_FRAME_SCORE_START";
export const ADD_FRAME_SCORE_SUCCESS = "ADD_FRAME_SCORE_SUCCESS";
export const ADD_FRAME_SCORE_FAIL = "ADD_FRAME_SCORE_FAIL";

export const ADD_MEMBER_REGISTRATION_START = "ADD_MEMBER_REGISTRATION_START";
export const ADD_MEMBER_REGISTRATION_SUCCESS = "ADD_MEMBER_REGISTRATION_SUCCESS";
export const ADD_MEMBER_REGISTRATION_FAIL = "ADD_MEMBER_REGISTRATION_FAIL";

export const ACTIVE_MEMBER_REGISTRATION_START = "ACTIVE_MEMBER_REGISTRATION_START";
export const ACTIVE_MEMBER_REGISTRATION_SUCCESS = "ACTIVE_MEMBER_REGISTRATION_SUCCESS";
export const ACTIVE_MEMBER_REGISTRATION_FAIL = "ACTIVE_MEMBER_REGISTRATION_FAIL";

export const GET_PLAYER_RANKING_START = "GET_PLAYER_RANKING_START";
export const GET_PLAYER_RANKING_SUCCESS = "GET_PLAYER_RANKING_SUCCESS";
export const GET_PLAYER_RANKING_FAIL = "GET_PLAYER_RANKING_FAIL";

export const UPDATE_PLAYER_RANKING_START = "UPDATE_PLAYER_RANKING_START";
export const UPDATE_PLAYER_RANKING_SUCCESS = "UPDATE_PLAYER_RANKING_SUCCESS";
export const UPDATE_PLAYER_RANKING_FAIL = "UPDATE_PLAYER_RANKING_FAIL";

export const GET_PLAYER_STATISTICS_START = "GET_PLAYER_STATISTICS_START";
export const GET_PLAYER_STATISTICS_SUCCESS = "GET_PLAYER_STATISTICS_SUCCESS";
export const GET_PLAYER_STATISTICS_FAIL = "GET_PLAYER_STATISTICS_FAIL";

export const GOOGLE_REGISTRATION_START = "GOOGLE_REGISTRATION_START";
export const GOOGLE_REGISTRATION_SUCCESS = "GOOGLE_REGISTRATION_SUCCESS";
export const GOOGLE_REGISTRATION_FAIL = "GOOGLE_REGISTRATION_FAIL";

export const FACEBOOK_REGISTRATION_START = "FACEBOOK_REGISTRATION_START";
export const FACEBOOK_REGISTRATION_SUCCESS = "FACEBOOK_REGISTRATION_SUCCESS";
export const FACEBOOK_REGISTRATION_FAIL = "FACEBOOK_REGISTRATION_FAIL";

export const GET_ALL_NEWS_START = 'GET_ALL_NEWS_START';
export const GET_ALL_NEWS_SUCCESS = 'GET_ALL_NEWS_SUCCESS';
export const GET_ALL_NEWS_FAIL = 'GET_ALL_NEWS_FAIL'

export const ADD_NEW_NEWS_START = 'ADD_NEW_NEWS_START';
export const ADD_NEW_NEWS_SUCCESS = 'ADD_NEW_NEWS_SUCCESS';
export const ADD_NEW_NEWS_FAIL = 'ADD_NEW_NEWS_FAIL';

export const UPDATE_NEWS_START = 'UPDATE_NEWS_START';
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_FAIL = 'UPDATE_NEWS_FAIL';

export const DELETE_NEWS_START = 'DELETE_NEWS_START';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAIL = 'DELETE_NEWS_FAIL';

export const NEWS_BROADCAST_START = 'NEWS_BROADCAST_START';
export const NEWS_BROADCAST_SUCCESS = 'NEWS_BROADCAST_SUCCESS';
export const NEWS_BROADCAST_FAIL = 'NEWS_BROADCAST_FAIL';

export const NEWS_BROADCAST_STOP_START = 'NEWS_BROADCAST_STOP_START';
export const NEWS_BROADCAST_STOP_SUCCESS = 'NEWS_BROADCAST_STOP_SUCCESS';
export const NEWS_BROADCAST_STOP_FAIL = 'NEWS_BROADCAST_STOP_FAIL';

export const GET_ALL_MEMBER_CLOUD_NEWS_START = 'GET_ALL_MEMBER_CLOUD_NEWS_START';
export const GET_ALL_MEMBER_CLOUD_NEWS_SUCCESS = 'GET_ALL_MEMBER_CLOUD_NEWS_SUCCESS';
export const GET_ALL_MEMBER_CLOUD_NEWS_FAIL = 'GET_ALL_MEMBER_CLOUD_NEWS_FAIL'

export const GET_ALL_MEMBER_CLUB_NEWS_START = 'GET_ALL_MEMBER_CLUB_NEWS_START';
export const GET_ALL_MEMBER_CLUB_NEWS_SUCCESS = 'GET_ALL_MEMBER_CLUB_NEWS_SUCCESS';
export const GET_ALL_MEMBER_CLUB_NEWS_FAIL = 'GET_ALL_MEMBER_CLUB_NEWS_FAIL'

export const DELETE_OTHER_MEMBER_START = "DELETE_OTHER_MEMBER_START";
export const DELETE_OTHER_MEMBER_SUCCESS = "DELETE_OTHER_MEMBER_SUCCESS";
export const DELETE_OTHER_MEMBER_FAIL = "DELETE_OTHER_MEMBER_FAIL";

export const CLUB_MEMBER_ASSOCIATIONS_START = "CLUB_MEMBER_ASSOCIATIONS_START";
export const CLUB_MEMBER_ASSOCIATIONS_SUCCESS = "CLUB_MEMBER_ASSOCIATIONS_SUCCESS";
export const CLUB_MEMBER_ASSOCIATIONS_FAIL = "CLUB_MEMBER_ASSOCIATIONS_FAIL";

export const GET_ALL_REFEREE_LIST_START = "GET_ALL_REFEREE_LIST_START";
export const GET_ALL_REFEREE_LIST_SUCCESS = "GET_ALL_REFEREE_LIST_SUCCESS";
export const GET_ALL_REFEREE_LIST_FAIL = "GET_ALL_REFEREE_LIST_FAIL";

export const EMAIL_VERIFICATION_START = "EMAIL_VERIFICATION_START";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAIL = "EMAIL_VERIFICATION_FAIL";

export const OTP_VERIFICATION_START = "OTP_VERIFICATION_START";
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
export const OTP_VERIFICATION_FAIL = "OTP_VERIFICATION_FAIL";

export const REMOVE_SELF_ENTRY_START = 'REMOVE_SELF_ENTRY_START';
export const REMOVE_SELF_ENTRY_SUCCESS = 'REMOVE_SELF_ENTRY_SUCCESS';
export const REMOVE_SELF_ENTRY_FAIL = 'REMOVE_SELF_ENTRY_FAIL';

export const GET_ALL_LEVELS_START = 'GET_ALL_LEVELS_START';
export const GET_ALL_LEVELS_SUCCESS = 'GET_ALL_LEVELS_SUCCESS';
export const GET_ALL_LEVELS_FAIL = 'GET_ALL_LEVELS_FAIL';

export const ADD_LEVEL_START = 'ADD_LEVEL_START';
export const ADD_LEVEL_SUCCESS = 'ADD_LEVEL_SUCCESS';
export const ADD_LEVEL_FAIL = 'ADD_LEVEL_FAIL';

export const DELETE_LEVEL_START = 'DELETE_LEVEL_START';
export const DELETE_LEVEL_SUCCESS = 'DELETE_LEVEL_SUCCESS';
export const DELETE_LEVEL_FAIL = 'DELETE_LEVEL_FAIL';

export const GET_LEVEL_POINTS_START = 'GET_LEVEL_POINTS_START';
export const GET_LEVEL_POINTS_SUCCESS = 'GET_LEVEL_POINTS_SUCCESS';
export const GET_LEVEL_POINTS_FAIL = 'GET_LEVEL_POINTS_FAIL';

export const ADD_LEVEL_POINTS_START = 'ADD_LEVEL_POINTS_START';
export const ADD_LEVEL_POINTS_SUCCESS = 'ADD_LEVEL_POINTS_SUCCESS';
export const ADD_LEVEL_POINTS_FAIL = 'ADD_LEVEL_POINTS_FAIL';

export const UPDATE_LEVEL_POINTS_START = 'UPDATE_LEVEL_POINTS_START';
export const UPDATE_LEVEL_POINTS_SUCCESS = 'UPDATE_LEVEL_POINTS_SUCCESS';
export const UPDATE_LEVEL_POINTS_FAIL = 'UPDATE_LEVEL_POINTS_FAIL';
