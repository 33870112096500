import { cloneDeep } from "lodash";
import {
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL
} from "../constants/type";
const initialState = {
  isForgotPasswordSuccess: false,
  forgotPasswordError: null,
  forgotPasswordMessage: null
};

export default (state = initialState, action) => {
  let currentState = cloneDeep(state);
  switch (action.type) {
    case FORGOT_PASSWORD_START:
      currentState.forgotPasswordMessage = null;
      currentState.forgotPasswordError = null
      currentState.isForgotPasswordSuccess = false;
      break;
    case FORGOT_PASSWORD_SUCCESS:
      currentState.isForgotPasswordSuccess = true;
      currentState.forgotPasswordMessage = null
      currentState.forgotPasswordMessage = action.payload;
      break;
    case FORGOT_PASSWORD_FAIL:
      currentState.isForgotPasswordSuccess = false;
      currentState.forgotPasswordMessage = null;
      currentState.forgotPasswordError = action.payload;
      break;
    default:
      return currentState;
  }
  return currentState;
};
