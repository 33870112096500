import {
    ADMIN_USER_DETAILS_START,
    ADMIN_USER_DETAILS_SUCCESS,
    ADMIN_USER_DETAILS_FAIL
} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
    isAdminUserSuccess: false,
    response: null,
    errorMsg: null
};

export default (state = INITIAL_STATE, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
        case ADMIN_USER_DETAILS_START:
            currentState.isAdminUserSuccess = false;
            break;
        case ADMIN_USER_DETAILS_SUCCESS:
            currentState.isAdminUserSuccess = true;
            currentState.response = action.payload;
            break;
        case ADMIN_USER_DETAILS_FAIL:
            currentState.isAdminUserSuccess = false;
            currentState.errorMsg = action.payload;
            break;
        default:
            return currentState;
    }
    return currentState;
};
