import { cloneDeep } from "lodash";
import {
    COUNTRY_API_FAIL,
    COUNTRY_API_SUCCESS,
    COUNTRY_API_START
} from "../constants/type";
// import constant from "../constants/constant";
const initialState = {
    isCountryApiSuccess: false,
    countryApiError: null,
    countryApiData: null
};

export default (state = initialState, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
        case COUNTRY_API_START:
            currentState.isCountryApiSuccess = false;
            break;
        case COUNTRY_API_SUCCESS:
            currentState.isCountryApiSuccess = true;
            currentState.countryApiData = action.payload
            break;
        case COUNTRY_API_FAIL:
            currentState.isCountryApiSuccess = false;
            currentState.countryApiError = action.payload;
            break;
        default:
            return currentState;
    }
    return currentState;
};
