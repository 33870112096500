import React, { PureComponent } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux'
import ValidationMessage from "../../view/ValidationMessage";
import UtilService from '../../utils/utils';
import { ErrorAlert } from '../../view/ErrorAlert';
import Delete from '../../view/delete';
import * as moment from 'moment'
import $ from 'jquery';
// import constant from '../../../constants/constant';
// const ADD = "ADD"
// const EDIT = "EDIT"
// const RENEW = "RENEW"


class LicenceView extends PureComponent {
    componentWillUnmount(){
        $('#addLicencePopup').modal('hide');
        
    }
    render() {
        //console.log("**********************LicenceView Component********************");

        const { errors, noOfLicence, submitted, handleChangeEventLicence,
            endDate, startDate, handleChangeStartDateLicence,
            handleChangeEndDateLicence, licenceListData, isLicenceGetApiSuccess,
            addEditFailDataLicence, isEditLicenceFieldDisable, isStartDateLicenceFieldDisable } = this.props

        let playerListRow;
        {
            isLicenceGetApiSuccess && licenceListData && (
                playerListRow = licenceListData.licences.map((element, index) =>
                    //************************Row Design******************************************

                    <tr key={index}>
                        <td className="position-relative d-none"></td>
                        <td><span className="pl-2">CS-{element.id}</span></td>
                        <td className="text-center">{element.numberOfLicence}</td>
                        <td className="text-center">{moment(element.startDate).format('MM/DD/YYYY')}</td>
                        <td className="text-center">{moment(element.endDate).format('MM/DD/YYYY')}</td>

                        <td className={`text-center ${UtilService.getStatusType(element.licenceStatusId).statusType}`}>{UtilService.getStatusType(element.licenceStatusId).statusName}</td>
                        <td>
                            <div className="clearfix pl-3 pr-3 text-center actions-tab">
                                <div className="float-left renew-icon-show">
                                    {(element.licenceStatusId === 9 || element.licenceStatusId === 3) && (
                                        <button type="button"
                                            className="btn d-flex align-items-center red-text-btn p-0 tooltip-show"
                                            data-toggle="modal"
                                            onClick={() => { this.props.reNewLicenceClick(element.startDate, element.endDate, element.numberOfLicence, element.id, element.licenceStatusId) }}
                                        >
                                            <svg className="cursor-pointer edit-club" data-toggle="modal" height="18px" version="1.1" viewBox="0 0 18 18" width="18px"><g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1" fillOpacity="0.6"><g fill="#000000" fillRule="nonzero" id="ic-renew" transform="translate(-1.000000, 0.000000)"><path d="M9.919,9.3938226e-15 C6.13313568,-0.00180408036 2.75873395,2.38692512 1.50224124,5.95819912 C0.245748526,9.52947312 1.38104204,13.5048628 4.334,15.874 L1,17.842 L9.919,17.842 C13.1054518,17.842 16.0498548,16.1420483 17.6430807,13.3825 C19.2363066,10.6229518 19.2363066,7.22304818 17.6430807,4.46349996 C16.0498548,1.70395174 13.1054518,0.00399995252 9.919,0.004 L9.919,9.3938226e-15 Z M9.919,17.045 L3.96,17.045 L5.846,15.945 C2.32230171,13.8971911 0.86122398,9.55550845 2.42994488,5.7939814 C3.99866577,2.03245435 8.11162,0.0153862401 12.0461878,1.07799969 C15.9807556,2.14061313 18.5190724,5.95399799 17.9808687,9.99383806 C17.442665,14.0336781 13.9945307,17.049524 9.919,17.045 Z M14.062,9.174 L13.028,10.631 L11.994,9.174 L12.574,9.174 C12.582,9.089 12.587,9.002 12.587,8.915 C12.5931332,7.82890515 11.9402712,6.84749682 10.9361856,6.43342956 C9.93209994,6.01936231 8.77722866,6.25529323 8.016,7.03 L7.504,6.31 C8.53695963,5.35339587 10.0384955,5.09959907 11.32847,5.66356975 C12.6184445,6.22754043 13.4517769,7.50213052 13.451,8.91 C13.451,8.997 13.444,9.083 13.437,9.169 L14.064,9.169 L14.062,9.174 Z M12.343,11.474 C11.3186382,12.456724 9.80619928,12.7322771 8.50107425,12.1739652 C7.19594923,11.6156532 6.35071719,10.3315256 6.354,8.912 C6.354,8.812 6.362,8.707 6.371,8.606 L5.774,8.606 L6.808,7.148 L7.842,8.606 L7.236,8.606 C7.22330021,8.70751591 7.21662186,8.80969468 7.216,8.912 C7.20770544,10.0119793 7.87290075,11.0051556 8.89322705,11.4162001 C9.91355334,11.8272446 11.0814627,11.5725453 11.838,10.774 L12.343,11.474 Z" id="Path_452"
                                            ></path></g></g></svg>
                                        </button>
                                    )}
                                </div>
                                <button type="button"  data-placement="top" title="Edit license" className="btn d-inline-block align-items-center red-text-btn p-0 flex-1" data-toggle="modal" onClick={() => { this.props.editLicenceClick(element.startDate, element.endDate, element.numberOfLicence, element.id, element.licenceStatusId) }}>
                                    <svg className="ml-2 cursor-pointer edit-club" height="18px" version="1.1" viewBox="0 0 18 18" width="18px"><g fill="none" fillOpacity="0.5" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g fill="#000000" fillRule="nonzero" id="ic-edit" transform="translate(-3.000000, -3.000000)"><path d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 Z M20.71,7.04 C21.0977236,6.64996255 21.0977236,6.02003745 20.71,5.63 L18.37,3.29 C17.9799625,2.90227641 17.3500375,2.90227641 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 Z" id="Path_137"
                                        data-toggle="modal"
                                    ></path></g></g></svg>
                                </button>
                                <div className="float-right">
                                    <Delete
                                        clubId={element.id}
                                        deleteIfYes={this.props.deleteIfYesLicence}
                                        message="this License" />
                                </div>
                            </div>
                        </td>
                    </tr>
                    //************************Row Design******************************************
                )
            )
        }
        return (
            <React.Fragment>
                <div className="mb-3">
                    <div className="layout-view layout-view-in-channel d-flex justify-content-between align-items-start position-relative">
                        <h4 className="m-0">License Management</h4>

                        {addEditFailDataLicence && (
                            <ErrorAlert errorMessage={`${addEditFailDataLicence}`} isShow={true} />

                        )}
                        <button type="button" className="btn d-flex align-items-center red-text-btn p-0" data-toggle="modal" onClick={() => { this.props.addLicenceClick() }}>
                            <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" id="prefix__ic_add" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M0 0h24v24H0z" data-name="Path 152" fill="none" />
                                <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4zm-1-5a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8.011 8.011 0 01-8 8z" data-name="Path 153" fill="#b52830" />
                            </svg>
                            Add License
                        </button>
                    </div>
                    <div className="table-responsive global-table licence-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="licence-id-width"><span className="pl-2">License ID</span></th>

                                    <th className="available-licences-width text-center"># of Licenses</th>
                                    <th className="start-date-width text-center">Start Date</th>
                                    <th className="expiry-date-width text-center">Expiry Date</th>
                                    <th className="status-tab-width text-center">Status</th>
                                    <th className="actions-tab text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLicenceGetApiSuccess && (
                                    playerListRow
                                )}

                            </tbody>
                        </table>
                    </div>
                </div>



                <div className="modal fade add-licence-popup" id="addLicencePopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="d-table w-100">
                        <div className="d-table-cell">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <form className="modal-content">

                                    {this.props.actionType === 'RENEW_LICENCE' ?
                                        <h3 className="modal-title text-center">Renew License</h3> :
                                        this.props.actionType === 'EDIT_LICENCE' ?
                                            <h3 className="modal-title text-center">Edit License</h3> :
                                            <h3 className="modal-title text-center">Add New License</h3>
                                    }
                                    <button type="button" className="close popup-close-btn" data-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                            <path d="M5.314 6.829l5.671 5.671-5.671 5.67a1.071 1.071 0 001.515 1.515l5.671-5.67 5.671 5.671a1.071 1.071 0 001.515-1.515L14.015 12.5l5.671-5.671a1.071 1.071 0 00-1.516-1.515l-5.67 5.671-5.671-5.671a1.071 1.071 0 00-1.515 1.515z" transform="translate(-5 -5)" fill="#666" />
                                        </svg>
                                    </button>

                                    <div className="modal-body p-0 pt-1 mt-3">
                                        {/* <ErrorAlert errorMessage={`${errorMessage}`} isShow={false} /> */}

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Number of License  <sup className="star">*</sup></label>
                                            <div className="position-relative">
                                                <input
                                                    type="number"
                                                    placeholder="Enter License"
                                                    name="noOfLicence"
                                                    onChange={handleChangeEventLicence}
                                                    value={noOfLicence}
                                                    className={`form-control ${submitted && errors["fullName"]
                                                            ? "input-error"
                                                            : ""
                                                        }`}
                                                    readOnly={isEditLicenceFieldDisable}
                                                />
                                                {submitted && errors["noOfLicence"] && (
                                                    <ValidationMessage message={errors["noOfLicence"]} />
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Start Date <sup className="star">*</sup></label>
                                            <div className="position-relative">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={handleChangeStartDateLicence}
                                                    dateFormat="dd-MM-yyyy"
                                                    minDate={new Date()}
                                                    readOnly={isStartDateLicenceFieldDisable}
                                                />
                                                <svg className="calendar-icon" xmlns="http://www.w3.org/2000/svg" id="prefix__ic_calendar" width="21.575" height="21.883" viewBox="0 0 21.575 21.883">
                                                    <g id="prefix__Group_6535" data-name="Group 6535">
                                                        <g id="prefix__Group_6534" data-name="Group 6534">
                                                            <path id="prefix__Path_5204" d="M32.26 15.849h1.233c2.466 0 3.082.616 3.082 3.082V32.8c0 2.466-.616 3.082-3.082 3.082H18.082C15.616 35.883 15 35.266 15 32.8V18.931c0-2.466.616-3.082 3.082-3.082h1.233v1.233a1.849 1.849 0 1 0 3.7 0v-1.233h5.548v1.233a1.849 1.849 0 1 0 3.7 0zm-14.486 4.315c-.925 0-1.233.308-1.233 1.233v11.712c0 .925.308 1.233 1.233 1.233H33.8c.925 0 1.233-.308 1.233-1.233V21.4c0-.925-.308-1.233-1.233-1.233zm7.4 2.157H26.4c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.229c-.925 0-1.233-.308-1.233-1.233v-1.233c0-.924.308-1.232 1.233-1.232zm5.856 0h1.23c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233v-1.233c0-.924.306-1.232 1.233-1.232zm-11.715 5.857h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.308-1.232 1.233-1.232zm5.856 0H26.4c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.229c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.308-1.232 1.233-1.232zm5.856 0h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.306-1.232 1.233-1.232zM21.164 14a1.194 1.194 0 0 1 1.236 1.151v2.014a1.236 1.236 0 0 1-2.466 0v-2.014A1.194 1.194 0 0 1 21.164 14zm9.246 0a1.194 1.194 0 0 1 1.233 1.151v2.014a1.236 1.236 0 0 1-2.466 0v-2.014A1.194 1.194 0 0 1 30.41 14zm-11.1 8.322h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.228c-.925 0-1.233-.308-1.233-1.233v-1.234c0-.924.308-1.232 1.233-1.232zm-.308 1.233v1.233a1.759 1.759 0 0 0 .019.289 1.759 1.759 0 0 0 .289.019h1.233a1.759 1.759 0 0 0 .289-.019 1.759 1.759 0 0 0 .019-.289v-1.234a1.759 1.759 0 0 0-.019-.289 1.759 1.759 0 0 0-.289-.019h-1.228a1.759 1.759 0 0 0-.289.019 1.759 1.759 0 0 0-.019.289z" data-name="Path 5204" transform="translate(-15 -14)" fill="#858585" fillRule="evenodd" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Expiry Date <sup className="star">*</sup></label>
                                            <div className="position-relative">
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={handleChangeEndDateLicence}
                                                    dateFormat="dd-MM-yyyy"
                                                    minDate={new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1))}

                                                />
                                                <svg className="calendar-icon" xmlns="http://www.w3.org/2000/svg" id="prefix__ic_calendar" width="21.575" height="21.883" viewBox="0 0 21.575 21.883">
                                                    <g id="prefix__Group_6535" data-name="Group 6535">
                                                        <g id="prefix__Group_6534" data-name="Group 6534">
                                                            <path id="prefix__Path_5204" d="M32.26 15.849h1.233c2.466 0 3.082.616 3.082 3.082V32.8c0 2.466-.616 3.082-3.082 3.082H18.082C15.616 35.883 15 35.266 15 32.8V18.931c0-2.466.616-3.082 3.082-3.082h1.233v1.233a1.849 1.849 0 1 0 3.7 0v-1.233h5.548v1.233a1.849 1.849 0 1 0 3.7 0zm-14.486 4.315c-.925 0-1.233.308-1.233 1.233v11.712c0 .925.308 1.233 1.233 1.233H33.8c.925 0 1.233-.308 1.233-1.233V21.4c0-.925-.308-1.233-1.233-1.233zm7.4 2.157H26.4c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.229c-.925 0-1.233-.308-1.233-1.233v-1.233c0-.924.308-1.232 1.233-1.232zm5.856 0h1.23c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233v-1.233c0-.924.306-1.232 1.233-1.232zm-11.715 5.857h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.308-1.232 1.233-1.232zm5.856 0H26.4c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.229c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.308-1.232 1.233-1.232zm5.856 0h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.306-1.232 1.233-1.232zM21.164 14a1.194 1.194 0 0 1 1.236 1.151v2.014a1.236 1.236 0 0 1-2.466 0v-2.014A1.194 1.194 0 0 1 21.164 14zm9.246 0a1.194 1.194 0 0 1 1.233 1.151v2.014a1.236 1.236 0 0 1-2.466 0v-2.014A1.194 1.194 0 0 1 30.41 14zm-11.1 8.322h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.228c-.925 0-1.233-.308-1.233-1.233v-1.234c0-.924.308-1.232 1.233-1.232zm-.308 1.233v1.233a1.759 1.759 0 0 0 .019.289 1.759 1.759 0 0 0 .289.019h1.233a1.759 1.759 0 0 0 .289-.019 1.759 1.759 0 0 0 .019-.289v-1.234a1.759 1.759 0 0 0-.019-.289 1.759 1.759 0 0 0-.289-.019h-1.228a1.759 1.759 0 0 0-.289.019 1.759 1.759 0 0 0-.019.289z" data-name="Path 5204" transform="translate(-15 -14)" fill="#858585" fillRule="evenodd" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <div>
                                            {/* <p>Note:- Renew license will create new license id.</p> */}
                                        </div>


                                    </div>
                                    <div className="modal-footer p-0 border-none mt-3">
                                        <button type="button" className="btn cancel-btn" data-dismiss="modal">Cancel</button>
                                        <div className="position-relative">
                                            <button type="button"
                                                className="btn red-btn ml-3"
                                                onClick={() => { this.props.saveLicence() }}
                                            >Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    ui: state.ui,
    apiReducer: state.apiReducer
});

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(LicenceView)

