import { cloneDeep } from "lodash";
import {
  GET_CONNECT_ONBOARD_FAIL,
  GET_CONNECT_ONBOARD_START,
  GET_CONNECT_ONBOARD_SUCCESS,
  RESET,
} from "../constants/type";
const initialState = {
  isLoading:false,
  onboardData: null,
  isOnboardSuccess: false,
  isOnboardError: false,
};

export default (state = initialState, action) => {
  let currentState = cloneDeep(state);
  switch (action.type) {
    case GET_CONNECT_ONBOARD_START:
      currentState.isLoading = true;
      currentState.isOnboardSuccess = false;
      currentState.isOnboardError = false;
      currentState.onboardData = action.payload;
      break;
    case GET_CONNECT_ONBOARD_SUCCESS:
      currentState.isLoading = false;
      currentState.isOnboardSuccess = true;
      currentState.isOnboardError = false;
      currentState.onboardData = action.payload;
      break;
    case GET_CONNECT_ONBOARD_FAIL:
      currentState.isLoading = false;
      currentState.isOnboardSuccess = false;
      currentState.isOnboardError = true;
      currentState.onboardData = action.payload;
      break;
      case RESET:
        currentState.isLoading = false;
        currentState.isOnboardSuccess = false;
        currentState.isOnboardError =false ;
        currentState.onboardData = null;
        break;
    default:
      return currentState;
  }
  return currentState;
};
