import React, { Component } from 'react';
import ValidationMessage from "../../view/ValidationMessage";
import UtilService from '../../utils/utils';
import { ErrorAlert } from '../../view/ErrorAlert';
import Delete from '../../view/delete';

class AddChannel extends Component {

    render() {
        // console.log("addChannel", this.props)
        const { channel, channels, submitted, errors, isUpdateChannel, channelErrorMsg } = this.props
        return (
            <React.Fragment>

                <div className="layout-view layout-view-in-channel d-flex justify-content-between align-items-start position-relative">

                    <h4 className="mb-0">Twitch Channel List</h4>

                    {submitted && errors["addChannelList"] && (

                        < ValidationMessage message={errors["addChannelList"]} />
                    )}
                    {channelErrorMsg && (
                        <ErrorAlert errorMessage={`${channelErrorMsg}`} isShow={true} />

                    )}
                    <span className="red-text-btn cursor-pointer" data-toggle="modal" onClick={this.props.openAddChannelDialog}>
                        <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M0 0h24v24H0z" data-name="Path 152" fill="none" />
                            <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4zm-1-5a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8.011 8.011 0 01-8 8z" data-name="Path 153" fill="#b52830" />
                        </svg>
                        Add Twitch channel

                    </span>
                </div>

                <div className="table-responsive global-table channel-list mb-3">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="pl-3"><span className="pl-1">Channel Name</span></th>
                                <th>Channel Code</th>
                                <th className="text-center status-tab">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {channel && channel.length > 0 ? (
                                channel.map((channelList, index) => {
                                    return <tr key={index}>
                                        <td className="pl-3"><span className="pl-1">{channelList.channel}</span></td>
                                        <td>{channelList.streamKey}</td>
                                        <td className="text-center">
                                            <div className="d-flex justify-content-center">
                                                <button
                                                    type="button"
                                                    className="btn d-flex align-items-center red-text-btn p-0"
                                                    data-toggle="modal"
                                                    onClick={() => { this.props.editChannel(index) }}>
                                                    <svg className="cursor-pointer edit-club" height="18px" version="1.1" viewBox="0 0 18 18" width="18px"><g fill="none" fillOpacity="0.5" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g fill="#000000" fillRule="nonzero" id="ic-edit" transform="translate(-3.000000, -3.000000)"><path d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 Z M20.71,7.04 C21.0977236,6.64996255 21.0977236,6.02003745 20.71,5.63 L18.37,3.29 C17.9799625,2.90227641 17.3500375,2.90227641 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 Z" id="Path_137"
                                                    ></path></g></g></svg>
                                                </button>
                                                {/* <button 
                                                    type="button" 
                                                    className="btn d-flex align-items-center red-text-btn p-0" 
                                                    data-toggle="modal" 
                                                    onClick={() => { this.props.deleteChannel(index) }}>
                                                    <svg className="ml-3 cursor-pointer" height="18px" version="1.1" viewBox="0 0 14 18" width="14px" data-toggle="modal" data-target="#deleteClub"><g fill="none" fillOpacity="0.5" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g fill="#000000" fillRule="nonzero" id="ic-edit" transform="translate(-5.000000, -3.000000)"><g id="ic-delete"><path d="M6,19 C6.0032948,20.1032019 6.89679813,20.9967052 8,21 L16,21 C17.1032019,20.9967052 17.9967052,20.1032019 18,19 L18,7 L6,7 L6,19 Z M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 Z" id="Path_141"></path></g></g></g></svg>
                                                </button> */}
                                                <Delete
                                                    clubId={index}
                                                    deleteIfYes={this.props.deleteIfYes}
                                                    message="this channel" />
                                            </div>
                                        </td>
                                    </tr>

                                })
                            ) : <tr>
                                    <td colSpan="5" className="no-record-found">
                                        No record found!
                                    </td>
                                </tr>}


                        </tbody>
                    </table>
                </div>

                <div className="modal fade add-user-popup" id="addChannelpopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="d-table w-100">
                        <div className="d-table-cell">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <h3 className="modal-title text-center" id="exampleModalLabel">{isUpdateChannel ? "Update Twitch Channel" : "Add Twitch Channel"}</h3>
                                    <button type="button" className="close popup-close-btn" data-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                            <path d="M5.314 6.829l5.671 5.671-5.671 5.67a1.071 1.071 0 001.515 1.515l5.671-5.67 5.671 5.671a1.071 1.071 0 001.515-1.515L14.015 12.5l5.671-5.671a1.071 1.071 0 00-1.516-1.515l-5.67 5.671-5.671-5.671a1.071 1.071 0 00-1.515 1.515z" transform="translate(-5 -5)" fill="#666" />
                                        </svg>
                                    </button>
                                    <div className="modal-body pt-4 pl-0 pr-0 pb-0">
                                        <div className="form-group">
                                            <label>Channel Name <sup className="star">*</sup></label>
                                            <div className="position-relative">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter name"
                                                    name="channelName"
                                                    value={channels.channelName}
                                                    onChange={this.props.addChannelChangeEvent}
                                                    onKeyPress={(e) => UtilService.AvoidSpace(e)}
                                                    onPaste={(e) => UtilService.onPastAvoidSpace(e)}
                                                />
                                                {submitted && errors["channelName"] && (
                                                    <ValidationMessage message={errors["channelName"]} />
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Stream Key <sup className="star">*</sup></label>
                                            <div className="position-relative">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter name"
                                                    name="channelKey"
                                                    value={channels.channelKey}
                                                    onChange={this.props.addChannelChangeEvent}
                                                    onKeyPress={(e) => UtilService.AvoidSpace(e)}
                                                    onPaste={(e) => UtilService.onPastAvoidSpace(e)}

                                                />
                                                {submitted && errors["channelKey"] && (
                                                    <ValidationMessage message={errors["channelKey"]} />
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer p-0 border-none mt-3">
                                        <button type="button" className="btn cancel-btn" data-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn red-btn ml-3" onClick={this.props.saveChannel}>{isUpdateChannel ? 'Ok' : 'Ok'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default AddChannel;