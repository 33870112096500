import React from "react";
import { Route, Redirect } from "react-router-dom";
import constant from "../constants/constant";
import UtilService from "./utils/utils";

const AuthenticatedRoute = ({ component: Component, role = [], isNonMemberAllowed = true, ...rest }) => {
  const isUserLoggedIn = UtilService.getInfoFromStorage('localStorage', constant.CURRENT_USER)
  const roleId = UtilService.getUserSingleDataFromLoaclStorage('roleId');
  if (!isNonMemberAllowed) {
    let clubList = JSON.parse(localStorage.getItem('clubs'));
    isNonMemberAllowed = clubList && clubList.length > 0;
  }

  return (
    <Route
      {...rest}
      render={props => {
        //console.log("In render of auth HOC", props);
        return isUserLoggedIn && (role?.length == 0 || role?.includes(roleId)) && isNonMemberAllowed ? <Component {...props} /> : isUserLoggedIn ? <Redirect to="/pageNotFound" /> : <Redirect to="/" />
        
      }}
    />
  );
};

export default AuthenticatedRoute;
