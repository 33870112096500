import {
  SET_PASSWORD_FAIL,
  SET_PASSWORD_START,
  SET_PASSWORD_SUCCESS
} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
  is_set_password: false,
  set_password_error: null
};

export default (state = INITIAL_STATE, action) => {
  let currentState = cloneDeep(state);
  switch (action.type) {
    case SET_PASSWORD_START:
      currentState.is_set_password = false;
      break;
    case SET_PASSWORD_SUCCESS:
      currentState.is_set_password = true;
      break;
    case SET_PASSWORD_FAIL:
      currentState.is_set_password = false;
      currentState.set_password_error = action.payload;
      break;
    default:
      return currentState;
  }
  return currentState;
};
