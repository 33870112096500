import {
    CLUB_SINGLE_DETAILS_START,
    CLUB_SINGLE_DETAILS_SUCCESS,
    CLUB_SINGLE_DETAILS_FAIL
} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
    isUserDetailsSuccess: false,
    successResponse: null,
    failedResponse: null
};

export default (state = INITIAL_STATE, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
        case CLUB_SINGLE_DETAILS_START:
            currentState.isUserDetailsSuccess = false;
            break;
        case CLUB_SINGLE_DETAILS_SUCCESS:
            currentState.isUserDetailsSuccess = true;
            currentState.successResponse = action.payload;
            currentState.failedResponse = false;
            break;
        case CLUB_SINGLE_DETAILS_FAIL:
            currentState.isUserDetailsSuccess = false;
            currentState.successResponse = false;
            currentState.failedResponse = action.payload;
            break;
        default:
            return currentState;
    }
    return currentState;
};
