import {
    ADD_CLUB_FAIL,
    ADD_CLUB_START,
    ADD_CLUB_SUCCESS
} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
    isAddClubSuccess: false,
    addClubSuccessResponse: null,
    addClubErrorMsg: null
};

export default (state = INITIAL_STATE, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
        case ADD_CLUB_START:
            currentState.isAddClubSuccess = false;
            currentState.addClubSuccessResponse = null;
            break;
        case ADD_CLUB_SUCCESS:
            currentState.isAddClubSuccess = true;
            currentState.addClubSuccessResponse = action.payload;
            break;
        case ADD_CLUB_FAIL:
            currentState.isAddClubSuccess = false;
            currentState.addClubErrorMsg = action.payload;
            break;
        default:
            return currentState;
    }
    return currentState;
};
