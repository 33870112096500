/* eslint-disable no-fallthrough */
import {
    API_FAIL,
    API_SUCCESS,
    API_START,
    RESET,
    EDIT_PLAYER_DETAILS_FAIL,
    EDIT_PLAYER_DETAILS_START,
    EDIT_PLAYER_DETAILS_SUCCESS,
    DELETE_ROW_FAIL,
    DELETE_ROW_SUCCESS,
    DELETE_ROW_START,
    EDIT_DELETE_RESET,
    CLUB_NAME_LIST_FAIL,
    CLUB_NAME_LIST_START,
    CLUB_NAME_LIST_SUCCESS,
    API_FAIL_OTHER,
    API_SUCCESS_OTHER,
    API_START_OTHER,
    EDIT_DETAILS_START,
    EDIT_DETAILS_FAIL,
    EDIT_DETAILS_SUCCESS,
    FILTER_MATCH_LIST_START,
    FILTER_MATCH_LIST_SUCCESS,
    FILTER_MATCH_LIST_FAIL,
    GET_MATCH_LIST_START,
    GET_MATCH_LIST_SUCCESS,
    GET_MATCH_LIST_FAIL,
    DELETE_MATCH_START,
    DELETE_MATCH_SUCCESS,
    DELETE_MATCH_FAIL,
    EDIT_MATCH_START,
    EDIT_MATCH_SUCCESS,
    EDIT_MATCH_FAIL,
    CREATE_MATCH_START,
    CREATE_MATCH_SUCCESS,
    CREATE_MATCH_FAIL,
    SEND_MAIL_FAIL,
    SEND_MAIL_SUCCESS,
    SEND_MAIL_START,
    RESET_SEND_MAIL,
    ROUND_ROBIN_START,
    ROUND_ROBIN_SUCCESS,
    ROUND_ROBIN_FAIL,
    ROUND_ROBIN_RESET,
    TOURNAMENT_LIST_START,
    TOURNAMENT_LIST_SUCCESS,
    TOURNAMENT_LIST_FAIL,
    API_START_LISTING,
    API_SUCCESS_LISTING,
    API_FAIL_LISTING,
    ADD_EDIT_LICENCE_FAIL,
    ADD_EDIT_LICENCE_START,
    ADD_EDIT_LICENCE_SUCCESS,
    KNOCKOUT_START,
    KNOCKOUT_SUCCESS,
    KNOCKOUT_RESET,
    KNOCKOUT_FAIL,
    TOURNAMENT_MATCH_DETAILS_START,
    TOURNAMENT_MATCH_DETAILS_SUCCESS,
    TOURNAMENT_MATCH_DETAILS_FAIL,
    GET_LIVE_MATCHES_SUCCESS,
    GET_LIVE_MATCHES_FAIL,
    GET_UPCOMING_MATCHES_SUCCESS,
    GET_UPCOMING_MATCHES_FAIL,
    GET_PAST_MATCHES_SUCCESS,
    GET_PAST_MATCHES_FAIL,
    INITIATE_PAYMENT_START,
    INITIATE_PAYMENT_SUCCESS,
    INITIATE_PAYMENT_FAIL,
    OVERLAY_SCORE_START,
    OVERLAY_SCORE_SUCCESS,
    OVERLAY_SCORE_FAIL,
    INITIATE_MYTRANSACTION_START,
    INITIATE_MYTRANSACTION_SUCCESS,
    INITIATE_MYTRANSACTION_FAIL,
    CREATE_TABLE_START,
    CREATE_TABLE_SUCCESS,
    CREATE_TABLE_FAIL,
    DELETE_TABLE_START,
    DELETE_TABLE_SUCCESS,
    DELETE_TABLE_FAIL,
    GET_CLUB_TABLE_START,
    GET_CLUB_TABLE_SUCCESS,
    GET_CLUB_TABLE_FAIL,
    EDIT_CLUB_TABLE_START,
    EDIT_CLUB_TABLE_SUCCESS,
    EDIT_CLUB_TABLE_FAIL,
    GET_FOOD_BEVERAGE_START,
    GET_FOOD_BEVERAGE_SUCCESS,
    GET_FOOD_BEVERAGE_FAIL,
    CREATE_FOOD_BEVERAGE_START,
    CREATE_FOOD_BEVERAGE_SUCCESS,
    CREATE_FOOD_BEVERAGE_FAIL,
    DELETE_FOOD_BEVERAGE_START,
    DELETE_FOOD_BEVERAGE_SUCCESS,
    DELETE_FOOD_BEVERAGE_FAIL,
    CREATE_TABLERATE_START,
    CREATE_TABLERATE_SUCCESS,
    CREATE_TABLERATE_FAIL,
    CREATE_LIGHT_CREDENTIAL_START,
    CREATE_LIGHT_CREDENTIAL_SUCCESS,
    CREATE_LIGHT_CREDENTIAL_FAIL,
    UPDATE_LIGHT_CONTROL_START,
    UPDATE_LIGHT_CONTROL_SUCCESS,
    UPDATE_LIGHT_CONTROL_FAIL,
    GET_BILLHISTORY_START,
    GET_BILLHISTORY_SUCCESS,
    GET_BILLHISTORY_FAIL,
    GET_TABLE_PRICE_START,
    GET_TABLE_PRICE_SUCCESS,
    GET_TABLE_PRICE_FAIL,
    GET_TABLE_SHEDULE_START,
    GET_TABLE_SHEDULE_SUCCESS,
    GET_TABLE_SHEDULE_FAIL,
    DELETE_SLOT_START,
    DELETE_SLOT_SUCCESS,
    DELETE_SLOT_FAIL,
    EDIT_TABLE_SCHEDULE_START,
    EDIT_TABLE_SCHEDULE_SUCCESS,
    EDIT_TABLE_SCHEDULE_FAIL,
    CREATE_NEW_SLOT_START,
    CREATE_NEW_SLOT_SUCCESS,
    CREATE_NEW_SLOT_FAIL,
    GET_OTHER_BOOKING_SHEDULE_START,
    GET_OTHER_BOOKING_SHEDULE_SUCCESS,
    GET_OTHER_BOOKING_SHEDULE_FAIL,
    API_OTHER_DATA_RESET,
    ADD_FRAME_SCORE_START,
    ADD_FRAME_SCORE_SUCCESS,
    ADD_FRAME_SCORE_FAIL,
    ADD_PLAYER_TOURNAMENT_START,
    ADD_PLAYER_TOURNAMENT_SUCCESS,
    ADD_PLAYER_TOURNAMENT_FAIL,
    GET_PLAYER_RANKING_START,
    GET_PLAYER_RANKING_SUCCESS,
    GET_PLAYER_RANKING_FAIL,
    UPDATE_PLAYER_RANKING_START,
    UPDATE_PLAYER_RANKING_SUCCESS,
    UPDATE_PLAYER_RANKING_FAIL,
    UPCOMING_TOURNAMENT_LIST_START,
    UPCOMING_TOURNAMENT_LIST_SUCCESS,
    UPCOMING_TOURNAMENT_LIST_FAIL,
    GET_PLAYER_STATISTICS_START,
    GET_PLAYER_STATISTICS_SUCCESS,
    GET_PLAYER_STATISTICS_FAIL,
    DELETE_OTHER_MEMBER_START,
    DELETE_OTHER_MEMBER_SUCCESS,
    DELETE_OTHER_MEMBER_FAIL,
    GET_ALL_REFEREE_LIST_START,
    GET_ALL_REFEREE_LIST_SUCCESS,
    GET_ALL_REFEREE_LIST_FAIL,
    REMOVE_SELF_ENTRY_START,
    REMOVE_SELF_ENTRY_SUCCESS,
    REMOVE_SELF_ENTRY_FAIL,
    GET_ALL_LEVELS_START,
    GET_ALL_LEVELS_SUCCESS,
    GET_ALL_LEVELS_FAIL,
    ADD_LEVEL_START,
    ADD_LEVEL_SUCCESS,
    ADD_LEVEL_FAIL,
    GET_LEVEL_POINTS_START,
    GET_LEVEL_POINTS_SUCCESS,
    GET_LEVEL_POINTS_FAIL,
    ADD_LEVEL_POINTS_START,
    ADD_LEVEL_POINTS_SUCCESS,
    ADD_LEVEL_POINTS_FAIL,
    DELETE_LEVEL_START,
    DELETE_LEVEL_SUCCESS,
    DELETE_LEVEL_FAIL
} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
    isApiSuccess: false,
    apiSuccesData: null,
    apiFailData: null,

    editApiSuccess: false,
    editApiSuccesData: null,
    editApiFailData: null,

    editPlayerApiSuccess: false,
    editPlayerApiFailData: null,
    editPlayerApiSuccesData: null,

    editTableApiSuccess: false,
    editTableApiFailData: null,
    editTableApiSuccesData: null,

    deleteApiSuccess: false,
    deleteApiSuccesData: null,
    deleteApiFailData: null,

    clubNameListApiSuccess: false,
    clubNameListApiSuccesData: null,
    clubNameListApiFailData: null,

    isApiSuccessOther: false,
    apiSuccesDataOther: {},
    apiFailDataOther: null,

    playerListSuccess: false,
    playerListData: null,
    playerListFail: null,

    refereeListSuccess: false,
    refereeListData: null,
    refereeListFail: null,

    listSuccess: false,
    listData: null,
    listFail: null,

    createdSuccess: false,
    createdData: null,
    createdFail: null,

    isMailApiSuccess: false,
    mailApiSuccesData: null,
    mailApiFailData: null,

    createRoundRobinSuccess: false,
    createRoundRobinData: null,
    createRoundRobinFail: null,

    isApiSuccessListing: false,
    apiSuccesDataListing: null,
    apiFailDataListing: null,

    isAddEditSuccess: false,
    addEditSuccesData: null,
    addEditFailData: null,
    createKnockoutSuccess: false,
    createKnockoutData: null,
    createKnockoutFail: null,

    liveMatchesResponse: [],
    liveMatchesResponseError: false,

    upcomingMatchesResponse: [],
    upcomingMatchesResponseError: false,

    pastMatchesResponse: [],
    pastMatchesResponseError: false,

    getTableSchedueleSuccess: false,
    getTableSchedueleData: [],

    deleteTableSchedueleSuccess: false,
    deleteTableSchedueleData: [],

    getOtherBookingSlotSuccess: false,
    getOtherBookingSlotData: [],

    addFrameScoresSuccess: false,
    addFrameScoresData: null,

    getPlayerRankingSuccess: false,
    getPlayerRankingData: [],

    updatePlayerRankingSuccess: false,
    updatePlayerRankingData: null,

    getPlayerStatsSuccess: false,
    getPlayerStatsData: [],

    deleteOtherMemberSuccess: false,
    deleteOtherMemberData: null
};

export default (state = INITIAL_STATE, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
        case API_START:
            currentState.isApiSuccess = false;
            break;
        case API_SUCCESS:
            currentState.isApiSuccess = true;
            currentState.apiSuccesData = action.payload;
            break;
        case API_FAIL:
            currentState.isApiSuccess = false;
            currentState.apiFailData = action.payload;
            break;
        case API_START_OTHER:
            currentState.isApiSuccessOther = false;
            break;
        case API_SUCCESS_OTHER:
            currentState.isApiSuccessOther = true;
            currentState.apiSuccesDataOther = action.payload;
            break;
        case API_FAIL_OTHER:
            currentState.isApiSuccessOther = false;
            currentState.apiFailDataOther = action.payload;
            break;
        case API_FAIL_LISTING:
            currentState.isApiSuccessListing = false;
            currentState.apiFailDataListing = action.payload;
            break;
        case API_SUCCESS_LISTING:
            currentState.isApiSuccessListing = true;
            currentState.apiSuccesDataListing = action.payload;
            break;
        case API_START_LISTING:
            currentState.isApiSuccessListing = false;
            break;
        case ADD_EDIT_LICENCE_SUCCESS:
            currentState.isAddEditSuccess = true;
            currentState.addEditSuccesData = action.payload;
            break;
        case ADD_EDIT_LICENCE_FAIL:
            currentState.isAddEditSuccess = false;
            currentState.addEditFailData = action.payload;
            break;
        case ADD_EDIT_LICENCE_START:
            currentState.isAddEditSuccess = false;
            break;
        case RESET:
            currentState.isApiSuccess = false;
            currentState.apiFailData = null;
            currentState.apiSuccesData = null;
            currentState.isApiSuccessOther = false;
            currentState.apiFailDataOther = null;
            currentState.apiSuccesDataOther = {};
            // currentState.isApiSuccessListing = false;
            // currentState.apiFailDataListing = null;
            // currentState.apiSuccesDataListing = null;
            currentState.isAddEditSuccess = false;
            currentState.addEditFailData = null;
            currentState.addEditSuccesData = null;
            currentState.editPlayerApiSuccess = false;
            currentState.editPlayerApiFailData = null;
            currentState.editPlayerApiSuccesData = null;
            currentState.editTableApiSuccess = false;
            currentState.editTableApiFailData = null;
            currentState.editTableApiSuccesData = null;
            break;
        case EDIT_DETAILS_FAIL:
        case EDIT_MATCH_FAIL:
        case TOURNAMENT_MATCH_DETAILS_FAIL:
            currentState.editApiSuccess = false;
            currentState.editApiFailData = action.payload;
            currentState.editApiSuccesData = null;
            break;

        case EDIT_DETAILS_START:
        case EDIT_MATCH_START:
        case TOURNAMENT_MATCH_DETAILS_START:
            currentState.editApiSuccess = false;
            currentState.editApiFailData = null;
            currentState.editApiSuccesData = null;
            break;

        case EDIT_DETAILS_SUCCESS:
        case EDIT_MATCH_SUCCESS:
        case TOURNAMENT_MATCH_DETAILS_SUCCESS:
            currentState.editApiSuccess = true;
            currentState.editApiFailData = null;
            currentState.editApiSuccesData = action.payload;
            break;

        case EDIT_PLAYER_DETAILS_START:
            currentState.editPlayerApiSuccess = false;
            currentState.editPlayerApiFailData = null;
            currentState.editPlayerApiSuccesData = null;
            break;

        case EDIT_PLAYER_DETAILS_SUCCESS:
            currentState.editPlayerApiSuccess = true;
            currentState.editPlayerApiFailData = null;
            currentState.editPlayerApiSuccesData = action.payload;
            break;

        case EDIT_PLAYER_DETAILS_FAIL:
            currentState.editPlayerApiSuccess = false;
            currentState.editPlayerApiFailData = action.payload;
            currentState.editPlayerApiSuccesData = null;
            break;

        case EDIT_CLUB_TABLE_START:
            currentState.editTableApiSuccess = false;
            currentState.editTableApiFailData = null;
            currentState.editTableApiSuccesData = null;
            break;
        case EDIT_CLUB_TABLE_SUCCESS:
            currentState.editTableApiSuccess = true;
            currentState.editTableApiFailData = null;
            currentState.editTableApiSuccesData = action.payload;
            break;
        case EDIT_CLUB_TABLE_FAIL:
            currentState.editTableApiSuccess = false;
            currentState.editTableApiFailData = action.payload;
            currentState.editTableApiSuccesData = null;
            break;
        case DELETE_ROW_FAIL:
        case DELETE_MATCH_FAIL:
            currentState.deleteApiSuccesData = null;
            currentState.deleteApiFailData = action.payload;
            currentState.deleteApiSuccess = false;
            break;
        case DELETE_ROW_START:
        case DELETE_MATCH_START:
            currentState.deleteApiSuccesData = null;
            currentState.deleteApiFailData = null;
            currentState.deleteApiSuccess = false;
            break;
        case DELETE_ROW_SUCCESS:
        case DELETE_MATCH_SUCCESS:
            currentState.deleteApiSuccesData = action.payload;
            currentState.deleteApiFailData = null;
            currentState.deleteApiSuccess = true;
            break;
        case EDIT_DELETE_RESET:
            currentState.editApiSuccess = false;
            currentState.editApiFailData = null;
            currentState.editApiSuccesData = null;
            currentState.deleteApiSuccesData = null;
            currentState.deleteApiFailData = null;
            currentState.deleteApiSuccess = false;
            break;
        case CLUB_NAME_LIST_FAIL:
            currentState.clubNameListApiSuccess = false;
            currentState.clubNameListApiSuccesData = null;
            currentState.clubNameListApiFailData = action.payload;
            break;
        case CLUB_NAME_LIST_START:
            currentState.clubNameListApiSuccess = false;
            currentState.clubNameListApiSuccesData = null;
            currentState.clubNameListApiFailData = null;
            break;
        case CLUB_NAME_LIST_SUCCESS:
            currentState.clubNameListApiSuccess = true;
            currentState.clubNameListApiSuccesData = action.payload;
            currentState.clubNameListApiFailData = null;
            break;
        case FILTER_MATCH_LIST_START:
            currentState.playerListSuccess = true;
            currentState.playerListData = action.payload;
            currentState.playerListFail = null;
            break;
        case FILTER_MATCH_LIST_SUCCESS:
            currentState.playerListSuccess = true;
            currentState.playerListData = action.payload;
            currentState.playerListFail = null;
            break;
        case FILTER_MATCH_LIST_FAIL:
            currentState.playerListSuccess = false;
            currentState.playerListData = null;
            currentState.playerListFail = action.payload;
            break;
        case GET_ALL_REFEREE_LIST_START:
            currentState.refereeListSuccess = true;
            currentState.refereeListData = action.payload;
            currentState.refereeListFail = null;
            break;
        case GET_ALL_REFEREE_LIST_SUCCESS:
            currentState.refereeListSuccess = true;
            currentState.refereeListData = action.payload;
            currentState.refereeListFail = null;
            break;
        case GET_ALL_REFEREE_LIST_FAIL:
            currentState.refereeListSuccess = false;
            currentState.refereeListData = null;
            currentState.refereeListFail = action.payload;
            break;
        case GET_MATCH_LIST_START:
        case TOURNAMENT_LIST_START:
            currentState.listSuccess = true;
            currentState.listData = action.payload;
            currentState.listFail = null;
            break;
        case GET_MATCH_LIST_SUCCESS:
        case TOURNAMENT_LIST_SUCCESS:
            currentState.listSuccess = true;
            currentState.listData = action.payload;
            currentState.listFail = null;
            break;
        case GET_MATCH_LIST_FAIL:
        case TOURNAMENT_LIST_FAIL:
            currentState.listSuccess = false;
            currentState.listData = null;
            currentState.listFail = action.payload;
            break;
        case CREATE_MATCH_START:
            currentState.createdSuccess = false;
            currentState.createdData = action.payload;
            currentState.createdFail = null;
            break;

        case CREATE_MATCH_SUCCESS:
            currentState.createdSuccess = true;
            currentState.createdData = action.payload;
            currentState.createdFail = null;
            break;
        case CREATE_MATCH_FAIL:
            currentState.createdSuccess = false;
            currentState.createdData = null;
            currentState.createdFail = action.payload;
            break;
        case SEND_MAIL_START:
            currentState.isMailApiSuccess = false;
            break;
        case SEND_MAIL_SUCCESS:
            currentState.isMailApiSuccess = true;
            currentState.mailApiSuccesData = action.payload;
            break;
        case SEND_MAIL_FAIL:
            currentState.isMailApiSuccess = false;
            currentState.mailApiFailData = action.payload;
            break;
        case ROUND_ROBIN_START:
            currentState.createRoundRobinSuccess = false;
            currentState.createRoundRobinData = action.payload;
            currentState.createRoundRobinFail = null;
            break;
        case ROUND_ROBIN_SUCCESS:
            currentState.createRoundRobinSuccess = true;
            currentState.createRoundRobinData = action.payload;
            currentState.createRoundRobinFail = null;
            break;
        case ROUND_ROBIN_FAIL:
            currentState.createRoundRobinSuccess = false;
            currentState.createRoundRobinData = null;
            currentState.createRoundRobinFail = action.payload;
            break;
        case ROUND_ROBIN_RESET:
            currentState.createRoundRobinSuccess = false;
            currentState.createRoundRobinData = null;
            currentState.createRoundRobinFail = null;
            break;
        case KNOCKOUT_START:
            currentState.createKnockoutSuccess = false;
            currentState.createKnockoutData = action.payload;
            currentState.createKnockoutFail = null;
            break;
        case KNOCKOUT_SUCCESS:
            currentState.createKnockoutSuccess = true;
            currentState.createKnockoutData = action.payload;
            currentState.createKnockoutFail = null;
            break;
        case KNOCKOUT_FAIL:
            currentState.createKnockoutSuccess = false;
            currentState.createKnockoutData = action.payload.data;//null;
            currentState.createKnockoutFail = action.payload;
            break;
        case KNOCKOUT_RESET:
            currentState.createKnockoutSuccess = false;
            currentState.createKnockoutData = null;
            currentState.createKnockoutFail = null;
            break;
        case RESET_SEND_MAIL:
            currentState.isMailApiSuccess = false;
            currentState.mailApiFailData = null;
            currentState.mailApiSuccesData = null;
            break;
        case GET_LIVE_MATCHES_SUCCESS:
            currentState.liveMatchesResponse = action.payload;
            currentState.liveMatchesResponseError = false;
            break;
        case GET_LIVE_MATCHES_FAIL:
            currentState.liveMatchesResponse = [];
            currentState.liveMatchesResponseError = true;
            break;
        case GET_UPCOMING_MATCHES_SUCCESS:
            currentState.upcomingMatchesResponse = action.payload;
            currentState.upcomingMatchesResponseError = false;
            break;
        case GET_UPCOMING_MATCHES_FAIL:
            currentState.upcomingMatchesResponse = [];
            currentState.upcomingMatchesResponseError = true;
            break;
        case GET_PAST_MATCHES_SUCCESS:
            currentState.pastMatchesResponse = action.payload;
            currentState.pastMatchesResponseError = false;
            break;
        case GET_PAST_MATCHES_FAIL:
            currentState.pastMatchesResponse = [];
            currentState.pastMatchesResponseError = true;
            break;
        case INITIATE_PAYMENT_START:
            currentState.initiatePaymentSuccess = false;
            currentState.initiatePaymentResponse = [];
            currentState.initiatePaymentResponseError = false;
            break;

        case INITIATE_PAYMENT_SUCCESS:
            currentState.initiatePaymentSuccess = true;
            currentState.initiatePaymentResponse = action.payload;
            currentState.initiatePaymentResponseError = false;
            break;

        case INITIATE_PAYMENT_FAIL:
            currentState.initiatePaymentSuccess = false;
            currentState.initiatePaymentResponse = false;
            currentState.initiatePaymentResponseError = action.payload;
            break;

        case OVERLAY_SCORE_START:
            currentState.overLayScoreSuccess = false;
            currentState.overLayScoreResponse = [];
            currentState.overLayScoreResponseError = false;
            break;

        case OVERLAY_SCORE_SUCCESS:
            currentState.overLayScoreSuccess = true;
            currentState.overLayScoreResponse = action.payload;
            currentState.overLayScoreResponseError = false;
            break;

        case OVERLAY_SCORE_FAIL:
            currentState.overLayScoreSuccess = false;
            currentState.overLayScoreResponse = false;
            currentState.overLayScoreResponseError = action.payload;
            break;

        case INITIATE_MYTRANSACTION_START:
            currentState.initiateMytransactionSuccess = false;
            currentState.initiateMytransactionResponse = [];
            currentState.initiateMytransactionResponseError = false;
            break;

        case INITIATE_MYTRANSACTION_SUCCESS:
            currentState.initiateMytransactionSuccess = true;
            currentState.initiateMytransactionResponse = action.payload;
            currentState.initiateMytransactionResponseError = false;
            break;

        case INITIATE_MYTRANSACTION_FAIL:
            currentState.initiateMytransactionSuccess = false;
            currentState.initiateMytransactionResponse = false;
            currentState.initiateMytransactionResponseError = action.payload;
            break;

        case GET_CLUB_TABLE_START:
        case GET_CLUB_TABLE_SUCCESS:
            currentState.isApiSuccess = true;
            currentState.apiSuccesData = action.payload;
        case GET_CLUB_TABLE_FAIL:
        case CREATE_TABLE_START:
            currentState.createdSuccess = false;
            currentState.createdData = action.payload;
            currentState.createdFail = null;
            break;

        case CREATE_TABLE_SUCCESS:
            currentState.createdSuccess = true;
            currentState.createdData = action.payload;
            currentState.createdFail = null;
            break;

        case CREATE_TABLE_FAIL:
            currentState.createdSuccess = false;
            currentState.createdData = null;
            currentState.createdFail = action.payload;
            break;

        case DELETE_TABLE_SUCCESS:
            currentState.deleteApiSuccesData = action.payload;
            currentState.deleteApiFailData = null;
            currentState.deleteApiSuccess = true;
            break;

        case DELETE_TABLE_FAIL:
            currentState.deleteApiSuccesData = null;
            currentState.deleteApiFailData = action.payload;
            currentState.deleteApiSuccess = false;
            break;

        case DELETE_TABLE_START:
            currentState.deleteApiSuccesData = null;
            currentState.deleteApiFailData = null;
            currentState.deleteApiSuccess = false;

        case GET_TABLE_PRICE_START:
        case GET_TABLE_PRICE_SUCCESS:
            currentState.getTablePriceSuccess = true;
            currentState.getTablePriceResponse = action.payload;
            currentState.getTablePriceResponseError = false;
        case GET_TABLE_PRICE_FAIL:

        case GET_FOOD_BEVERAGE_START:
        case GET_FOOD_BEVERAGE_SUCCESS:
            currentState.isApiSuccess = true;
            currentState.apiSuccesData = action.payload;
        case GET_FOOD_BEVERAGE_FAIL:

        case CREATE_FOOD_BEVERAGE_START:
            currentState.createdSuccess = false;
            currentState.createdData = action.payload;
            currentState.createdFail = null;
            break;

        case CREATE_FOOD_BEVERAGE_SUCCESS:
            currentState.createdSuccess = true;
            currentState.createdData = action.payload;
            currentState.createdFail = null;
            break;

        case CREATE_FOOD_BEVERAGE_FAIL:
            currentState.createdSuccess = false;
            currentState.createdData = null;
            currentState.createdFail = action.payload;
            break;

        case DELETE_FOOD_BEVERAGE_SUCCESS:
            currentState.deleteApiSuccesData = action.payload;
            currentState.deleteApiFailData = null;
            currentState.deleteApiSuccess = true;
            break;

        case DELETE_FOOD_BEVERAGE_FAIL:
            currentState.deleteApiSuccesData = null;
            currentState.deleteApiFailData = action.payload;
            currentState.deleteApiSuccess = false;
            break;

        case DELETE_FOOD_BEVERAGE_START:
            currentState.deleteApiSuccesData = null;
            currentState.deleteApiFailData = null;
            currentState.deleteApiSuccess = false;

        case CREATE_TABLERATE_START:
            currentState.tableratecreatedSuccess = false;
            currentState.tableratecreatedData = action.payload;
            currentState.tableratecreatedFail = null;
            break;

        case CREATE_TABLERATE_SUCCESS:
            currentState.tableratecreatedSuccess = true;
            currentState.tableratecreatedData = action.payload;
            currentState.tableratecreatedFail = null;
            break;

        case CREATE_TABLERATE_FAIL:
            currentState.tableratecreatedSuccess = false;
            currentState.tableratecreatedData = null;
            currentState.tableratecreatedFail = action.payload;
            break;

        case CREATE_LIGHT_CREDENTIAL_START:
        case CREATE_LIGHT_CREDENTIAL_SUCCESS:
            currentState.createLightCredentialSuccesData = action.payload;
            currentState.createLightCredentialFailData = null;
            currentState.createLightCredentialSuccess = true;
        case CREATE_LIGHT_CREDENTIAL_FAIL:

        case UPDATE_LIGHT_CONTROL_START:
        case UPDATE_LIGHT_CONTROL_SUCCESS:
            currentState.updateLightStatusSuccesData = action.payload;
            currentState.updateLightStatusFailData = null;
            currentState.updateLightStatusSuccess = true;
            break;
        case UPDATE_LIGHT_CONTROL_FAIL:
            currentState.updateLightStatusSuccesData = null;
            currentState.updateLightStatusFailData = action.payload;
            currentState.updateLightStatusSuccess = false;
            break;
        case GET_BILLHISTORY_START:
            currentState.getBillHistorySuccess = false;
            currentState.getBillHistoryResponse = [];
            currentState.getBillHistoryResponseError = false;
            break;

        case GET_BILLHISTORY_SUCCESS:
            currentState.getBillHistorySuccess = true;
            currentState.getBillHistoryResponse = action.payload;
            currentState.getBillHistoryResponseError = false;
            break;

        case GET_BILLHISTORY_FAIL:
            currentState.getBillHistorySuccess = false;
            currentState.getBillHistoryResponse = false;
            currentState.getBillHistoryResponseError = action.payload;
            break;
        case GET_TABLE_SHEDULE_START:
            currentState.getTableSchedueleSuccess = false;
            currentState.getTableSchedueleData = action.payload;
            currentState.getTableSchedueleFail = null;
            break;
        case GET_TABLE_SHEDULE_SUCCESS:
            currentState.getTableSchedueleSuccess = true;
            currentState.getTableSchedueleData = action.payload;
            currentState.getTableSchedueleFail = null;
            break;
        case GET_TABLE_SHEDULE_FAIL:
            currentState.getTableSchedueleSuccess = false;
            currentState.getTableSchedueleData = null;
            currentState.getTableSchedueleFail = action.payload;
            break;
        case DELETE_SLOT_START:
            currentState.deleteTableSchedueleSuccess = false;
            currentState.deleteTableSchedueleData = action.payload;
            currentState.deleteTableSchedueleFail = null;
            break;
        case DELETE_SLOT_SUCCESS:
            currentState.deleteTableSchedueleSuccess = true;
            currentState.deleteTableSchedueleData = action.payload;
            currentState.deleteTableSchedueleFail = null;
            break;
        case DELETE_SLOT_FAIL:
            currentState.deleteTableSchedueleSuccess = false;
            currentState.deleteTableSchedueleData = null;
            currentState.deleteTableSchedueleFail = action.payload;
            break;
        case EDIT_TABLE_SCHEDULE_START:
            currentState.editTableSchedueleSuccess = false;
            currentState.editTableSchedueleData = action.payload;
            currentState.editTableSchedueleFail = null;
            break;
        case EDIT_TABLE_SCHEDULE_SUCCESS:
            currentState.editTableSchedueleSuccess = true;
            currentState.editTableSchedueleData = action.payload;
            currentState.editTableSchedueleFail = null;
            break;
        case EDIT_TABLE_SCHEDULE_FAIL:
            currentState.editTableSchedueleSuccess = false;
            currentState.editTableSchedueleData = null;
            currentState.editTableSchedueleFail = action.payload;
            break;
        case API_OTHER_DATA_RESET:
            currentState.apiSuccesDataOther = {};
            break;
        case CREATE_NEW_SLOT_START:
            currentState.createdNewSlotSuccess = false;
            currentState.createdNewSlotData = action.payload;
            currentState.createdNewSlotFaail = null;
            break;
        case CREATE_NEW_SLOT_SUCCESS:
            currentState.createdNewSlotSuccess = true;
            currentState.createdNewSlotData = action.payload;
            currentState.createdNewSlotFaail = null;
            break;
        case CREATE_NEW_SLOT_FAIL:
            currentState.tableScheduleCreatedSuccess = false;
            currentState.tableScheduleCreatedData = null;
            currentState.tableScheduleCreatedFail = action.payload;
            break;
        case GET_OTHER_BOOKING_SHEDULE_START:
            currentState.getOtherBookingSlotSuccess = false;
            currentState.getOtherBookingSlotData = action.payload;
            currentState.getOtherBookingSlotFail = null;
            break;
        case GET_OTHER_BOOKING_SHEDULE_SUCCESS:
            currentState.getOtherBookingSlotSuccess = false;
            currentState.getOtherBookingSlotData = action.payload;
            currentState.getOtherBookingSlotFail = null;
            break;
        case GET_OTHER_BOOKING_SHEDULE_FAIL:
            currentState.getOtherBookingSlotSuccess = false;
            currentState.getOtherBookingSlotData = action.payload;
            currentState.getOtherBookingSlotFail = null;
            break;
        case ADD_FRAME_SCORE_START:
            currentState.addFrameScoresSuccess = false;
            currentState.addFrameScoresData = action.payload;
            currentState.addFrameScoresFail = null;
            break;
        case ADD_FRAME_SCORE_SUCCESS:
            currentState.addFrameScoresSuccess = true;
            currentState.addFrameScoresData = action.payload;
            currentState.addFrameScoresFail = null;
            break;
        case ADD_FRAME_SCORE_FAIL:
            currentState.addFrameScoresSuccess = false;
            currentState.addFrameScoresData = null
            currentState.addFrameScoresFail = action.payload;
            break;
        case ADD_PLAYER_TOURNAMENT_START:
            currentState.addPlayerInTournamentSuccess = false;
            currentState.addPlayerInTournamentData = action.payload;
            currentState.addPlayerInTournamentFail = null;
            break;
        case ADD_PLAYER_TOURNAMENT_SUCCESS:
            currentState.addPlayerInTournamentSuccess = true;
            currentState.addPlayerInTournamentData = action.payload;
            currentState.addPlayerInTournamentFail = null;
            break;
        case ADD_PLAYER_TOURNAMENT_FAIL:
            currentState.addPlayerInTournamentSuccess = false;
            currentState.addPlayerInTournamentData = null;
            currentState.addPlayerInTournamentFail = action.payload;
            break;
        case GET_PLAYER_RANKING_START:
            currentState.getPlayerRankingSuccess = false;
            currentState.getPlayerRankingData = action.payload;
            currentState.getPlayerRankingFail = null;
            break;
        case GET_PLAYER_RANKING_SUCCESS:
            currentState.getPlayerRankingSuccess = true;
            currentState.getPlayerRankingData = action.payload;
            currentState.getPlayerRankingFail = null;
            break;
        case GET_PLAYER_RANKING_FAIL:
            currentState.getPlayerRankingSuccess = false;
            currentState.getPlayerRankingData = null;
            currentState.getPlayerRankingFail = action.payload;
            break;
        case UPDATE_PLAYER_RANKING_START:
            currentState.updatePlayerRankingSuccess = false;
            currentState.updatePlayerRankingData = action.payload;
            currentState.updatePlayerRankingFail = null;
            break;
        case UPDATE_PLAYER_RANKING_SUCCESS:
            currentState.updatePlayerRankingSuccess = true;
            currentState.updatePlayerRankingData = action.payload;
            currentState.updatePlayerRankingFail = null;
            break;
        case UPDATE_PLAYER_RANKING_FAIL:
            currentState.updatePlayerRankingSuccess = false;
            currentState.updatePlayerRankingData = null;
            currentState.updatePlayerRankingFail = action.payload;
            break;

        case UPCOMING_TOURNAMENT_LIST_START:
            currentState.upcomingListSuccess = true;
            currentState.upcomingListData = action.payload;
            currentState.upcomingListFail = null;
            break;
        case UPCOMING_TOURNAMENT_LIST_SUCCESS:
            currentState.upcomingListSuccess = true;
            currentState.upcomingListData = action.payload;
            currentState.upcomingListFail = null;
            break;
        case UPCOMING_TOURNAMENT_LIST_FAIL:
            currentState.upcomingListSuccess = false;
            currentState.upcomingListData = null;
            currentState.upcomingListFail = action.payload;
            break;
        case GET_PLAYER_STATISTICS_START:
            currentState.getPlayerStatsSuccess = false;
            currentState.getPlayerStatsData = action.payload;
            currentState.getPlayerStatsFail = null;
            break;
        case GET_PLAYER_STATISTICS_SUCCESS:
            currentState.getPlayerStatsSuccess = true;
            currentState.getPlayerStatsData = action.payload;
            currentState.getPlayerStatsFail = null;
            break;
        case GET_PLAYER_STATISTICS_FAIL:
            currentState.getPlayerStatsSuccess = false;
            currentState.getPlayerStatsData = null;
            currentState.getPlayerStatsFail = action.payload;
            break;
        case DELETE_OTHER_MEMBER_START:
            currentState.deleteOtherMemberSuccess = false;
            currentState.deleteOtherMemberData = action.payload;
            currentState.deleteOtherMemberFail = null;
            break;
        case DELETE_OTHER_MEMBER_SUCCESS:
            currentState.deleteOtherMemberSuccess = true;
            currentState.deleteOtherMemberData = action.payload;
            currentState.deleteOtherMemberFail = null;
            break;
        case DELETE_OTHER_MEMBER_FAIL:
            currentState.deleteOtherMemberSuccess = false;
            currentState.deleteOtherMemberData = null;
            currentState.deleteOtherMemberFail = action.payload;
            break;
        case REMOVE_SELF_ENTRY_START:
            currentState.removePlayerFromTournamentSuccess = false;
            currentState.removePlayerFromTournamentData = action.payload;
            currentState.removePlayerFromTournamentFail = null;
            break;
        case REMOVE_SELF_ENTRY_SUCCESS:
            currentState.removePlayerFromTournamentSuccess = true;
            currentState.removePlayerFromTournamentData = action.payload;
            currentState.removePlayerFromTournamentFail = null;
            break;
        case REMOVE_SELF_ENTRY_FAIL:
            currentState.removePlayerFromTournamentSuccess = false;
            currentState.removePlayerFromTournamentData = null;
            currentState.removePlayerFromTournamentFail = action.payload;
            break;
        case GET_ALL_LEVELS_START:
            currentState.getAllLevelsSuccess = false;
            currentState.getAllLevelsData = action.payload;
            currentState.getAllLevelsFail = null;
            break;
        case GET_ALL_LEVELS_SUCCESS:
            currentState.getAllLevelsSuccess = true;
            currentState.getAllLevelsData = action.payload;
            currentState.getAllLevelsFail = null;
            break;
        case GET_ALL_LEVELS_FAIL:
            currentState.getAllLevelsSuccess = false;
            currentState.getAllLevelsData = null;
            currentState.getAllLevelsFail = action.payload;
            break;

        case ADD_LEVEL_START:
            currentState.addLevelSuccess = false;
            currentState.addLevelData = action.payload;
            currentState.addLevelFail = null;
            break;
        case ADD_LEVEL_SUCCESS:
            currentState.addLevelSuccess = true;
            currentState.addLevelData = action.payload;
            currentState.addLevelFail = null;
            break;
        case ADD_LEVEL_FAIL:
            currentState.addLevelSuccess = false;
            currentState.addLevelData = null;
            currentState.addLevelFail = action.payload;
            break;

        case GET_LEVEL_POINTS_START:
            currentState.getLevelPointsSuccess = false;
            currentState.getLevelPointsData = action.payload;
            currentState.getLevelPointsFail = null;
            break;
        case GET_LEVEL_POINTS_SUCCESS:
            currentState.getLevelPointsSuccess = true;
            currentState.getLevelPointsData = action.payload;
            currentState.getLevelPointsFail = null;
            break;
        case GET_LEVEL_POINTS_FAIL:
            currentState.getLevelPointsSuccess = false;
            currentState.getLevelPointsData = null;
            currentState.getLevelPointsFail = action.payload;
            break;

        case ADD_LEVEL_POINTS_START:
            currentState.addLevelPointsSuccess = false;
            currentState.addLevelPointsData = action.payload;
            currentState.addLevelPointsFail = null;
            break;
        case ADD_LEVEL_POINTS_SUCCESS:
            currentState.addLevelPointsSuccess = true;
            currentState.addLevelPointsData = action.payload;
            currentState.addLevelPointsFail = null;
            break;
        case ADD_LEVEL_POINTS_FAIL:
            currentState.addLevelPointsSuccess = false;
            currentState.addLevelPointsData = null;
            currentState.addLevelPointsFail = action.payload;
            break;

        case DELETE_LEVEL_START:
            currentState.deleteLevelSuccess = false;
            currentState.deleteLevelData = action.payload;
            currentState.deleteLevelFail = null;
            break;
        case DELETE_LEVEL_SUCCESS:
            currentState.deleteLevelSuccess = true;
            currentState.deleteLevelData = action.payload;
            currentState.deleteLevelFail = null;
            break;
        case DELETE_LEVEL_FAIL:
            currentState.deleteLevelSuccess = false;
            currentState.deleteLevelData = null;
            currentState.deleteLevelFail = action.payload;
            break;

        default:
            return currentState;
    }
    return currentState;
};
