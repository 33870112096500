import React, { Component } from 'react';
import ValidationMessage from "../../view/ValidationMessage";
import CountryCodeSelect from '../../view/CountryCodeSelect';
import constant from '../../../constants/constant';
import UtilService from '../../utils/utils';

class PointOfContact extends Component {
    render() {
        return (
            <div className="point-of-contact layout-view">
                <h4>Point of Contact Information</h4>
                <div className="form-group">
                    <label>Name <sup className="star">*</sup></label>
                    <div className="position-relative">
                        <input
                            type="text"
                            onKeyPress={(e) => UtilService.alphabetsValidation(e)}
                            onPaste={(e) => UtilService.onPastRestriction(e)}
                            className={`form-control ${this.props.submitted && this.props.errors["ownerName"]
                                    ? "input-error"
                                    : ""
                                }`}
                            placeholder="Enter owner name"
                            name="ownerName"
                            value={this.props.pointOfContact.ownerName}
                            onChange={this.props.handleChangeEvent}
                        />
                        {this.props.submitted && this.props.errors["ownerName"] && (
                            <ValidationMessage message={this.props.errors["ownerName"]} />
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <label>Email <sup className="star">*</sup></label>
                    <div className="position-relative">
                        <input
                            type="email"
                            className={`form-control ${this.props.submitted && this.props.errors["email"]
                                    ? "input-error"
                                    : ""
                                }`}
                            placeholder="Enter email address"
                            name="email"
                            value={this.props.pointOfContact.email}
                            onChange={this.props.handleChangeEvent}
                            readOnly={`${this.props.isEditClub ? 'readOnly' : ""}`}
                        />
                        {this.props.submitted && this.props.errors["email"] && (
                            <ValidationMessage message={this.props.errors["email"]} />
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <label>Contact Number <sup className="star">*</sup></label>
                    <div
                        className={`d-flex mobile-number ${this.props.submitted && this.props.errors["pointOfPhoneNumber"]
                                ? "input-error"
                                : ""
                            }`}
                    >
                        <CountryCodeSelect value={this.props.pointOfContact.poc_country_code} className="form-control border-none pr-4" name="poc_country_code" selectionKey={constant.COUNTRY_CODE} onSelectChange={this.props.handleChangeEvent} />

                        <div className="position-relative flex-1">
                            <input
                                type="number"
                                className="form-control border-none"
                                placeholder="Enter contact number"
                                name="pointOfPhoneNumber"
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                                }}
                                value={this.props.pointOfContact.pointOfPhoneNumber}
                                onChange={this.props.handleChangeEvent}
                            />
                            {this.props.submitted && this.props.errors["pointOfPhoneNumber"] && (
                                <ValidationMessage message={this.props.errors["pointOfPhoneNumber"]} />
                            )}
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label> Slack Id
                    <i class="fa fa-info-circle info_icon" aria-hidden="true">
                     <span className = "tooltiptext w-275">This is the poc slack member id . We can get this from slack profile . This will be use to send event message on slack. </span>
                   </i>
                    </label>
                    <div className="position-relative">
                        <input
                            // type=""
                            className={`form-control`}
                            placeholder="Enter slack id"
                            name="pocSlackId"
                            value={this.props.pointOfContact?.pocSlackId}
                            onChange={this.props.handleChangeEvent}
                            // readOnly={`${(this.props.isEditClub && this.props.editSlackId) ? 'readOnly' : ""}`}
                        />
                       
                    </div>
                </div>
                {this.props.isEditClub && (
                    <div className="form-group mb-0">
                        <label>POC Status</label>
                        <div className="position-relative d-flex justify-content-between">
                            {this.props.pointOfContact.statusId === 1 && (
                                <div className="text-success d-flex">
                                    <svg className="mr-2" height="17px" version="1.1" viewBox="0 0 12 17" width="12px"><g _ngcontent-c11="" fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g _ngcontent-c11="" id="ic-lock" transform="translate(-4.000000, -1.000000)"><polygon _ngcontent-c11="" id="Path_197" points="0 0 20 0 20 20 0 20"></polygon><path _ngcontent-c11="" d="M14.5,18 L5.5,18 C4.63979791,17.9647807 3.96957335,17.2413849 4,16.381 L4,8.281 C3.97183329,7.42232874 4.64156771,6.70169449 5.5,6.667 L12.325,6.667 L12.325,5.047 C12.3723238,3.71342135 11.3333174,2.59218814 10,2.538 C8.66707869,2.59272228 7.62866699,3.71379584 7.676,5.047 L7.676,5.452 L6.25,5.452 L6.25,5.047 C6.17338272,2.89592009 7.84932486,1.08724334 10,1 C12.1504272,1.0877811 13.8260622,2.89612645 13.75,5.047 L13.75,6.667 L14.5,6.667 C15.3602021,6.70221934 16.0304267,7.42561506 16,8.286 L16,16.386 C16.0276152,17.2444648 15.3581861,17.9647705 14.5,18 Z M10,10.714 C9.15508362,10.7804546 8.50333813,11.4854742 8.50333813,12.333 C8.50333813,13.1805258 9.15508362,13.8855454 10,13.952 C10.8449164,13.8855454 11.4966619,13.1805258 11.4966619,12.333 C11.4966619,11.4854742 10.8449164,10.7804546 10,10.714 Z" fill="#28a745" fillRule="nonzero" id="Subtraction_2"></path></g></g></svg>
                                    <span>{constant.ACTIVE}</span>
                                </div>
                            )}
                            {this.props.pointOfContact.statusId === 4 && (
                                <React.Fragment>
                                    <div className="text-danger d-flex">
                                        <svg className="mr-2" height="17px" version="1.1" viewBox="0 0 12 17" width="12px"><g _ngcontent-c7="" fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g _ngcontent-c7="" id="ic-lock" transform="translate(-4.000000, -1.000000)"><polygon _ngcontent-c7="" id="Path_197" points="0 0 20 0 20 20 0 20"></polygon><path _ngcontent-c7="" d="M14.5,18 L5.5,18 C4.63979791,17.9647807 3.96957335,17.2413849 4,16.381 L4,8.281 C3.97183329,7.42232874 4.64156771,6.70169449 5.5,6.667 L12.325,6.667 L12.325,5.047 C12.3723238,3.71342135 11.3333174,2.59218814 10,2.538 C8.66707869,2.59272228 7.62866699,3.71379584 7.676,5.047 L7.676,5.452 L6.25,5.452 L6.25,5.047 C6.17338272,2.89592009 7.84932486,1.08724334 10,1 C12.1504272,1.0877811 13.8260622,2.89612645 13.75,5.047 L13.75,6.667 L14.5,6.667 C15.3602021,6.70221934 16.0304267,7.42561506 16,8.286 L16,16.386 C16.0276152,17.2444648 15.3581861,17.9647705 14.5,18 Z M10,10.714 C9.15508362,10.7804546 8.50333813,11.4854742 8.50333813,12.333 C8.50333813,13.1805258 9.15508362,13.8855454 10,13.952 C10.8449164,13.8855454 11.4966619,13.1805258 11.4966619,12.333 C11.4966619,11.4854742 10.8449164,10.7804546 10,10.714 Z" fill="#F44336" fillRule="nonzero" id="Subtraction_2"></path></g></g></svg>
                                        <span>{constant.PASSWORD_NOT_SET}</span>
                                    </div>
                                    <span className="resend-e-mail cursor-pointer" onClick={this.props.sendMail}>Resend e-mail</span>
                                </React.Fragment>
                            )}

                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default PointOfContact;