import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ValidationMessage from "../../view/ValidationMessage";

class LicenceManagement extends Component {
    render() {
        return (
            <div className="licence-management layout-view mb-3">
                <h4>License Management</h4>
                <div className="d-lg-flex">
                    <div className="form-group flex-1 pr-lg-3">
                        <label>Number of License <sup className="star">*</sup></label>
                        <div className="position-relative">
                            <input
                                type="number"
                                className={`form-control ${this.props.submitted && this.props.errors["noOfLicence"]
                                        ? "input-error"
                                        : ""
                                    }`}
                                placeholder="Enter number of License"
                                name="noOfLicence"
                                value={this.props.addLicence.noOfLicence}
                                onChange={this.props.handleChangeEvent}
                                onKeyDown={ (evt) => (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '+' || evt.key === '.') && evt.preventDefault() }
                            />
                            {this.props.submitted && this.props.errors["noOfLicence"] && (
                                <ValidationMessage message={this.props.errors["noOfLicence"]} />
                            )}
                        </div>
                    </div>
                    <div className="form-group flex-1 pl-lg-1 pr-lg-3">
                        <label>Start Date <sup className="star">*</sup></label>
                        <div className="position-relative">

                            <DatePicker
                                selected={this.props.startDate}
                                onChange={this.props.handleChangeStartDate}
                                dateFormat="dd-MM-yyyy"
                                minDate={new Date()}
                            />
                            <svg className="calendar-icon" xmlns="http://www.w3.org/2000/svg" id="prefix__ic_calendar" width="21.575" height="21.883" viewBox="0 0 21.575 21.883">
                                <g id="prefix__Group_6535" data-name="Group 6535">
                                    <g id="prefix__Group_6534" data-name="Group 6534">
                                        <path id="prefix__Path_5204" d="M32.26 15.849h1.233c2.466 0 3.082.616 3.082 3.082V32.8c0 2.466-.616 3.082-3.082 3.082H18.082C15.616 35.883 15 35.266 15 32.8V18.931c0-2.466.616-3.082 3.082-3.082h1.233v1.233a1.849 1.849 0 1 0 3.7 0v-1.233h5.548v1.233a1.849 1.849 0 1 0 3.7 0zm-14.486 4.315c-.925 0-1.233.308-1.233 1.233v11.712c0 .925.308 1.233 1.233 1.233H33.8c.925 0 1.233-.308 1.233-1.233V21.4c0-.925-.308-1.233-1.233-1.233zm7.4 2.157H26.4c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.229c-.925 0-1.233-.308-1.233-1.233v-1.233c0-.924.308-1.232 1.233-1.232zm5.856 0h1.23c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233v-1.233c0-.924.306-1.232 1.233-1.232zm-11.715 5.857h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.308-1.232 1.233-1.232zm5.856 0H26.4c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.229c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.308-1.232 1.233-1.232zm5.856 0h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.306-1.232 1.233-1.232zM21.164 14a1.194 1.194 0 0 1 1.236 1.151v2.014a1.236 1.236 0 0 1-2.466 0v-2.014A1.194 1.194 0 0 1 21.164 14zm9.246 0a1.194 1.194 0 0 1 1.233 1.151v2.014a1.236 1.236 0 0 1-2.466 0v-2.014A1.194 1.194 0 0 1 30.41 14zm-11.1 8.322h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.228c-.925 0-1.233-.308-1.233-1.233v-1.234c0-.924.308-1.232 1.233-1.232zm-.308 1.233v1.233a1.759 1.759 0 0 0 .019.289 1.759 1.759 0 0 0 .289.019h1.233a1.759 1.759 0 0 0 .289-.019 1.759 1.759 0 0 0 .019-.289v-1.234a1.759 1.759 0 0 0-.019-.289 1.759 1.759 0 0 0-.289-.019h-1.228a1.759 1.759 0 0 0-.289.019 1.759 1.759 0 0 0-.019.289z" data-name="Path 5204" transform="translate(-15 -14)" fill="#858585" fillRule="evenodd" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className="form-group flex-1 pl-lg-1">
                        <label>Expiry Date <sup className="star">*</sup></label>
                        <div className="position-relative">
                            <DatePicker
                                selected={this.props.endDate}
                                onChange={this.props.handleChangeEndDate}
                                dateFormat="dd-MM-yyyy"
                                minDate={new Date(new Date(this.props.startDate).setDate(new Date(this.props.startDate).getDate() + 1))}
                            />
                            <svg className="calendar-icon" xmlns="http://www.w3.org/2000/svg" id="prefix__ic_calendar" width="21.575" height="21.883" viewBox="0 0 21.575 21.883">
                                <g id="prefix__Group_6535" data-name="Group 6535">
                                    <g id="prefix__Group_6534" data-name="Group 6534">
                                        <path id="prefix__Path_5204" d="M32.26 15.849h1.233c2.466 0 3.082.616 3.082 3.082V32.8c0 2.466-.616 3.082-3.082 3.082H18.082C15.616 35.883 15 35.266 15 32.8V18.931c0-2.466.616-3.082 3.082-3.082h1.233v1.233a1.849 1.849 0 1 0 3.7 0v-1.233h5.548v1.233a1.849 1.849 0 1 0 3.7 0zm-14.486 4.315c-.925 0-1.233.308-1.233 1.233v11.712c0 .925.308 1.233 1.233 1.233H33.8c.925 0 1.233-.308 1.233-1.233V21.4c0-.925-.308-1.233-1.233-1.233zm7.4 2.157H26.4c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.229c-.925 0-1.233-.308-1.233-1.233v-1.233c0-.924.308-1.232 1.233-1.232zm5.856 0h1.23c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233v-1.233c0-.924.306-1.232 1.233-1.232zm-11.715 5.857h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.308-1.232 1.233-1.232zm5.856 0H26.4c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.229c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.308-1.232 1.233-1.232zm5.856 0h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.233c-.925 0-1.233-.308-1.233-1.233V29.41c0-.924.306-1.232 1.233-1.232zM21.164 14a1.194 1.194 0 0 1 1.236 1.151v2.014a1.236 1.236 0 0 1-2.466 0v-2.014A1.194 1.194 0 0 1 21.164 14zm9.246 0a1.194 1.194 0 0 1 1.233 1.151v2.014a1.236 1.236 0 0 1-2.466 0v-2.014A1.194 1.194 0 0 1 30.41 14zm-11.1 8.322h1.233c.925 0 1.233.308 1.233 1.233v1.233c0 .925-.308 1.233-1.233 1.233h-1.228c-.925 0-1.233-.308-1.233-1.233v-1.234c0-.924.308-1.232 1.233-1.232zm-.308 1.233v1.233a1.759 1.759 0 0 0 .019.289 1.759 1.759 0 0 0 .289.019h1.233a1.759 1.759 0 0 0 .289-.019 1.759 1.759 0 0 0 .019-.289v-1.234a1.759 1.759 0 0 0-.019-.289 1.759 1.759 0 0 0-.289-.019h-1.228a1.759 1.759 0 0 0-.289.019 1.759 1.759 0 0 0-.019.289z" data-name="Path 5204" transform="translate(-15 -14)" fill="#858585" fillRule="evenodd" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LicenceManagement;