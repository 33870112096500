import React from 'react'
import MainHeader from './mainHeader';
import Collapsible from 'react-collapsible';
import image1 from '../assets/images/image1.png'
import image2 from '../assets/images/image2.png'
import image3 from '../assets/images/image3.png'
import image4 from '../assets/images/image4.png'
import image5 from '../assets/images/image5.png'
import image6 from '../assets/images/image-06.png'
import image7 from '../assets/images/image6.png'
import image8 from '../assets/images/image7.png'
import image9 from '../assets/images/image8.png'
import image10 from '../assets/images/image9.png'
import image11 from '../assets/images/image10a.png'
import image13 from '../assets/images/image12.png'
import image14 from '../assets/images/image13.png'
import image15 from '../assets/images/image14.png'
import image16 from '../assets/images/image15.png'
import image17 from '../assets/images/image16.png'
import image18 from '../assets/images/image17.png'
import image19 from '../assets/images/image18.png'
import image20 from '../assets/images/image19.png'
import image21 from '../assets/images/image20.png'
import image22 from '../assets/images/Frame.png'
import image23 from '../assets/images/thumb.png'


function faq() {

  return (
    <div>
      <MainHeader />
      <div className="content-wrapper" style={{ marginTop: "88px" }}>
        <div className='faq'>
          <h1>Frequently Asked Questions</h1>
          <h6>Answers to the most commonly asked questions about Cloud Snooker</h6>
          <Collapsible trigger="How to create a user account?">
            <p>
              A visitor can register on cloud snooker by clicking on "Member Registration."
            </p>
            <figure><img src={image1} alt="step-02"></img></figure>
            <p>The Member Registration form will appear [see below]. Enter the required* details.:-</p>
            <figure><img src={image2} alt="step-02"></img></figure>
            <ul>
              <li>It is required to register First Name, Last Name, Email Address, Contact Number, User Pin, Password and Confirm Password.</li>
              <li>Click the email verification button to verify the email first.</li>
              <li>The user will get an OTP via email, which he has to put in the OTP window on the screen.</li>
              <figure><img src={image4} alt="step-02"></img></figure>
              <li>The user has the option to select one or more clubs. It is not necessary to select the currency or club name.</li>
              <li>Click on ‘Accept End User License Agreement(EULA)’.</li>
            </ul>

           
            <p>Then click the Register button.</p>

            <p>
              Once your registration is complete, the following confirmation popup appears to confirm that your registration was successful [see below].
            </p>
            <figure><img src={image3} alt="step-02"></img></figure>
            <p> Member will recieve a confirmation email.</p>
            <figure><img src={image5} alt="step-02"></img></figure>
            <p>Members can log in to their accounts by clicking the Login button on the Cloud Snooker homepage. [see below]</p>
            <figure><img src={image6} alt="step-02"></img></figure>
          </Collapsible>
          <Collapsible trigger="How to join a club?">
            <p>
              CSSA (Cloud Snooker Super Admin) can associate clubs with players, or CSA (Club Super Admin) can associate himself with players.
            </p>
            <br></br>
            <h3>Add Member</h3>
            <p>The user can Click on the “Add member” button.</p>
            <figure><img src={image14} alt="step-02"></img></figure>
            <p>After clicking on "Add Member," users can create a new member.</p>
            <p>Enter the required details:</p>
            <ul><li>It is required to fill out Member Name, Member Address, Contact Number, Email Address, Member Height (in cm), Highest Billiards Practice Break, Highest Billiards Tournament Break, Highest Snooker Practice Break, Highest Snooker Tournament Break, and Membership Type.</li>
              <li>Clicking on the member address in the form will open the Address Details dialogue box [see below inset] to be completed, and then click Save.</li>
              <li>It is not required to register Date of Birth, Credit, Discount(%), Club Name(user also allows members to join multiple clubs.), Currency, Gender, Style, Can be Referee.</li>
              <li>Click the “Save” button.</li></ul>
            <figure><img src={image15} alt="step-02"></img></figure>
            <p>A set password email is sent to the member’s email address with the End User License Agreement. Click the "Accept Eula & Set Password" button to complete the process.</p>
            <figure><img src={image16} alt="step-02"></img></figure>
            <p>After clicking on the ‘Accept Eula and Set Password’ button in the email, the user sets his password.</p>
            <figure><img src={image17} alt="step-02"></img></figure>
            <p>Clicking on the ‘Set Password’ button.</p>
            <figure><img src={image18} alt="step-02"></img></figure>
            <p>CSSA (Cloud Snooker Super Admin) can associate a member with multiple clubs by selecting the club name.</p>
            <p>After adding clubs, a "Member Association" email is sent to the member's email address.</p>
            <p>Members need to click on "I Accept" to give consent for club association.</p>
            <figure><img src={image19} alt="step-02"></img></figure>
            <figure><img src={image20} alt="step-02"></img></figure>
            <p>-All Clubs received an association email after the player clicked on the ‘I Accept’ button.</p>
            <figure><img src={image21} alt="step-02"></img></figure>
          </Collapsible>
          <Collapsible trigger="How to update the profile / credit card information?">
            <p>Players are redirected to Payment after logging in. click on the "Add New Card" link. Then Player can enter his credit or debit card information. so that he can be invoiced for his membership fee and also the entry fee. If he decides to participate in future tournaments.</p>
            <figure><img src={image7} alt="step-02"></img></figure>
            <p>After Clicking “+Add New Card” Enter credit or debit card details, then click on “ Save Card ”. </p>
            <figure><img src={image8} alt="step-02"></img></figure>
            <p>After clicking on the "Save Card" button, terms and conditions pop up.</p>
            <p>Players must click on the "I agree" button.</p>
            <figure><img src={image9} alt="step-02"></img></figure>
            <p>Once the terms and conditions are accepted, the message shows "Card added successfully."</p>
            <p>Later on, players can edit or delete cards as well.</p>
            <figure><img src={image10} alt="step-02"></img></figure>
            <p class="text-justify">Players can update their personal information by clicking on "My Profile," updating all personal information, and adding a profile picture. If you would like to change your email address, then enter your updated email and verify by clicking the "Verify email address" button. You will then get an email with an OTP for verification. Once the verification process has been successful, click on "Update Profile" button. By selecting the referee checkbox, the player can make himself the referee.
            </p>
            <figure><img src={image11} alt="step-02"></img></figure>
            <p><b>Note :</b> The player can hide their name on iFrame/Overlay, if they have selected <b>"Do not show my name in iFrame/Overlay".</b> </p>
          </Collapsible>
          <Collapsible trigger="How to enter into a tournament?">
            <div className='card'>
              <figure> <span className='video' data-toggle="modal" data-target="#videoPopup">
                <img src={image23} alt="step-02"></img>
                <img src={image22} alt="step-02" className='videoicon'></img>
              </span>
              </figure>
              <span className='video' data-toggle="modal" data-target="#videoPopup">
                <h3>How to enter into a tournament?</h3></span>
            </div>
            <p>
              Clicking on "Tournament" in the left menu bar shows all upcoming tournaments with the respective club. The player can also enter an upcoming tournament by clicking "Enter in Tournament." If the Player was already in the tournament, the "disable" card would be displayed.If Tournament is closed then “Entry closed” card would be displayed.
            </p>
            <figure><img src={image13} alt="step-02"></img></figure>
            <p>Players can also search for upcoming tournaments by using Search.</p>
          </Collapsible>
        </div>
      </div>
      <div className="modal fade" id="videoPopup" tabIndex="-1" role="dialog" aria-labelledby="videoPopupLabel" aria-hidden="true">
        <div className="d-table w-100">
          <div className="d-table-cell">
            <div className="modal-dialog modal-dialog-centered videos">
              <div className="modal-content p-0 b_g_color">
                <div className="display-table">
                  <div className="display-table-cell">
                    <div className="videos-page position-relative">
                      <div className='flex'>
                        <h3>How to enter into a tournament?</h3>
                        <button type="button" className="close popup-close-btn" data-dismiss="modal" aria-label="Close">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 15 15">
                            <path d="M5.314 6.829l5.671 5.671-5.671 5.67a1.071 1.071 0 001.515 1.515l5.671-5.67 5.671 5.671a1.071 1.071 0 001.515-1.515L14.015 12.5l5.671-5.671a1.071 1.071 0 00-1.516-1.515l-5.67 5.671-5.671-5.671a1.071 1.071 0 00-1.515 1.515z" transform="translate(-5 -5)" fill="#666" />
                          </svg>
                        </button></div>
                      <div className="embed-responsive embed-responsive-16by9">
                        <video width="100%" autoplay controls>
                          <source src='https://pabsa-images.s3.amazonaws.com/enterInTornament.mp4' type="video/mp4"></source>
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default faq