import React, { Component } from 'react';
import ValidationMessage from "../../view/ValidationMessage";
import CountryCodeSelect from '../../view/CountryCodeSelect';
import CONSTANT from '../../../constants/constant';
import UtilsService from "../../utils/utils";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";

// import avatarImage from "../../../assets/images/avatar.png";
class OrganizationInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rTwitchName: null,
            currenciesData: []
        }
        this.removeTwitchName = this.removeTwitchName.bind(this);
    }

    removeTwitchName = (name) => {
        this.setState({
            rTwitchName: name
        })
    }
    
    async componentDidMount() {
        try {
            let response = await UtilsService.getCurrencyList();
            if (response && response.countries) {
                this.setState({
                    currenciesData: response.countries
                })
            } else {
                toast.error(response?.message)
            }
        } catch (error) {
            console.error(error);
        }
    }
    isNumber = (evt,req) =>{
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if(charCode == 46 && req === 'club') {
            return true;
        }
        if(charCode == 46) {
            return evt.preventDefault();
        }
        if (charCode >= 48 && charCode <= 57) {
            return true;
        }
        return evt.preventDefault();;
    }

    render() {
        const { rTwitchName } = this.state;
        return (
            <div className="organization-information mb-3 mb-lg-0 layout-view">
                <h4>Organization Information</h4>
                <div className="form-group">
                    <label>Club Name <sup className="star">*</sup></label>
                    <div className="position-relative">
                        <input
                            type="text"
                            className={`form-control ${
                                this.props.submitted && this.props.errors["clubName"]
                                    ? "input-error"
                                    : ""
                                }`}
                            placeholder="Enter club name"
                            name="clubName"
                            value={this.props.orgInfo.clubName}
                            onChange={this.props.handleChangeEvent}
                            readOnly={`${this.props.isEditClub ? 'readOnly' : ""}`}
                        />
                        {this.props.submitted && this.props.errors["clubName"] && (
                            <ValidationMessage message={this.props.errors["clubName"]} />
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <label>Address <sup className="star">*</sup></label>
                    <div className="position-relative">
                        <div
                            className={`form-control dashed-border text-truncate ${
                                this.props.submitted && this.props.errors["fillAddressData"]
                                    ? "input-error-dashed"
                                    : ""
                                }`}
                            data-toggle="modal"
                            data-target="#addressFilupForm"
                            id="addressInputValue"
                        >
                            <span className="address-placeholder">+ Add address details</span>

                        </div>
                        {this.props.submitted && this.props.errors["fillAddressData"] && (
                            <ValidationMessage message={this.props.errors["fillAddressData"]} />
                        )}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="currencyType">
                        Currency <sup className="star">*</sup>
                    </label>
                    <div className="position-relative">
                        <div class="form-group">
                            <select
                                className="form-control"
                                name="currencyId"
                                aria-label="Select Currency"
                                id="currencyType"
                                value={this.props.orgInfo.currencyId}
                                onChange={this.props.handleChangeEvent}
                            >
                                <option value="">Select</option>
                                {this.state.currenciesData.map((key, idx) => {
                                    return <option key={idx} value={key.currencyCode}>{key.currency}</option>
                                })}
                            </select>
                            <i className="arrow-icon"></i>
                        </div>
                        {this.props.submitted && this.props.errors["currencyId"] && (
                            <ValidationMessage message={this.props.errors["currencyId"]} />
                        )}
                    </div>
                </div>
                
                <div className="d-xl-flex">
                    <div className="flex-1 pr-xl-3 mb-3">

                        <div className="form-group">
                            <label>Platform Fee (%) <sup className="star">*</sup></label>
                            <div
                                className={`d-flex mobile-number ${
                                    this.props.submitted && this.props.errors["platformFee"]
                                        ? "input-error"
                                        : ""
                                    }`}
                            >
                                <div className="position-relative flex-1">
                                <input
                                        type="number"
                                        className="form-control border-none"
                                        placeholder="0.00"
                                        name="platformFee"
                                        value={this.props.orgInfo.platformFee}
                                        onChange={this.props.handleChangeEvent}
                                    />
                                    {this.props.submitted && this.props.errors["platformFee"] && (
                                        <ValidationMessage message={this.props.errors["platformFee"]} />
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="flex-1 pr-xl-0 mb-3">

                        <div className="form-group">
                            <label>Value in cents</label>
                            <div
                                className={`d-flex mobile-number ${
                                    this.props.submitted && this.props.errors["transactionFee"]
                                        ? "input-error"
                                        : ""
                                    }`}
                            ><div className="position-relative flex-1">
                            <input
                                type="number"
                                className="form-control border-none"
                                placeholder="¢"
                                name="transactionFee"
                                value={this.props.orgInfo.transactionFee}
                                onChange={this.props.handleChangeEvent}
                                onKeyPress={(e)=>this.isNumber(e)}
                            />
                             {this.props.submitted && this.props.errors["transactionFee"] && (
                                        <ValidationMessage message={this.props.errors["transactionFee"]} />
                                    )}
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="d-xl-flex">
                    <div className="flex-1 pr-xl-3 mb-3">

                        <div className="form-group">
                            <label>Monthly Charge Amount(<w className="doll_sig">$</w>)</label>
                            <i class="fa fa-info-circle info_icon" aria-hidden="true">
                                <span className = "tooltiptext w-275">The charged club must be located in the United States in accordance with Stripe restrictions.The value must be between 0.5 - 1000. </span>
                            </i>
                            <div
                                className={`d-flex mobile-number`}
                            >
                                <div className="position-relative flex-1">
                                <input
                                        type="number"
                                        className="form-control border-none"
                                        placeholder="Amount"
                                        name="monthlyCharge"
                                        value={this.props.orgInfo.monthlyCharge}
                                        onChange={this.props.handleChangeEvent}
                                        onKeyPress={(e)=>this.isNumber(e,'club')}
                                    />
                                    {this.props.submitted && this.props.errors["monthlyCharge"] && (
                                        <ValidationMessage message={this.props.errors["monthlyCharge"]} />
                                    )}
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="flex-1 pr-xl-0 mb-3">

                        <div className="form-group">
                            <label>Schedule on (<span className="period_time">Day of month</span>)</label>
                            <div
                                className={`d-flex mobile-number`}
                            ><div className="position-relative flex-1">
                                <select className="form-control border-none bg-white" 
                                        name="monthlyChargeDay"
                                        value={this.props.orgInfo.monthlyChargeDay}
                                        onChange={this.props.handleChangeEvent}>
                                            <option value="">Select</option>
                                            <option value="1">1st</option><option value="2">2nd</option><option value="3">3rd</option><option value="4">4th</option><option value="5">5th</option><option value="6">6th</option><option value="7">7th</option><option value="8">8th</option><option value="9">9th</option><option value="10">10th</option><option value="11">11th</option><option value="12">12th</option><option value="13">13th</option><option value="14">14th</option><option value="15">15th</option><option value="16">16th</option><option value="17">17th</option><option value="18">18th</option><option value="19">19th</option><option value="20">20th</option><option value="21">21st</option><option value="22">22nd</option><option value="23">23rd</option><option value="24">24th</option><option value="25">25th</option><option value="26">26th</option><option value="27">27th</option><option value="28">28th</option>

                                </select>
                                <i className='arrow-icon'></i>
                                {this.props.submitted && this.props.errors["monthlyChargeDay"] && (
                                        <ValidationMessage message={this.props.errors["monthlyChargeDay"]} />
                                    )}
                          
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                <div className="d-xl-flex">
                    <div className="flex-1 pr-xl-3 mb-3">

                        <div className="form-group">
                            <label>Contact Number <sup className="star">*</sup></label>
                            <div
                                className={`d-flex mobile-number ${
                                    this.props.submitted && this.props.errors["mobile"]
                                        ? "input-error"
                                        : ""
                                    }`}
                            >
                                <CountryCodeSelect value={this.props.orgInfo.countryCode} selectionKey={CONSTANT.COUNTRY_CODE} className="form-control border-none pr-4" name="countryCode" onSelectChange={this.props.handleChangeEvent} />

                                <div className="position-relative flex-1">
                                    <input
                                        type="number"
                                        className="form-control border-none"
                                        placeholder="Enter contact number"
                                        name="mobile"
                                        value={this.props.orgInfo.mobile}
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                                        }}
                                        onChange={this.props.handleChangeEvent}
                                    />
                                    {this.props.submitted && this.props.errors["mobile"] && (
                                        <ValidationMessage message={this.props.errors["mobile"]} />
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="flex-1 pl-lg-1 position-relative">

                        <div className="form-group">
                            <label>Alternate Number</label>
                            <div className="d-flex mobile-number">
                                <CountryCodeSelect value={this.props.orgInfo.alternateCountryCode} className="form-control border-none pr-4" selectionKey={CONSTANT.COUNTRY_CODE} name="alternateCountryCode" onSelectChange={this.props.handleChangeEvent} />
                                <div className="position-relative flex-1">
                                <input
                                    type="number"
                                    className="form-control border-none"
                                    placeholder="Enter alternate number"
                                    name="contactNumber"
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                                    }}
                                    onChange={this.props.handleChangeEvent}
                                    value={this.props.orgInfo.contactNumber}
                                />
                                </div>
                            </div>
                        </div>


                        <div className="sliding-btn d-flex align-items-center justify-content-end">
                            <label className="mb-0 mr-3">
                                 Active
                            </label>

                            <label className="switch">
                                <input type="checkbox"
                                    onChange={this.props.changeStatus}
                                    checked={this.props.checked}
                                />
                                <span className="slider-switch"></span>
                            </label>
                        </div>

                    </div>

                </div>

                <div className="modal fade" id="deleteTwitchName" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="d-table w-100">
                        <div className="d-table-cell">
                            <div className="modal-dialog  modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <button type="button" className="close popup-close-btn" data-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><path d="M5.314 6.829l5.671 5.671-5.671 5.67a1.071 1.071 0 001.515 1.515l5.671-5.67 5.671 5.671a1.071 1.071 0 001.515-1.515L14.015 12.5l5.671-5.671a1.071 1.071 0 00-1.516-1.515l-5.67 5.671-5.671-5.671a1.071 1.071 0 00-1.515 1.515z" transform="translate(-5 -5)" fill="#666"></path></svg>
                                    </button>
                                    <div className="modal-body p-0 pt-3 text-center">
                                        <p className="m-0"> Are you sure you want to delete this channel? </p>
                                        {/* <strong>{rTwitchName}</strong> */}
                                    </div>
                                    <div className="modal-footer p-0 mt-4 border-none justify-content-center">
                                        <button type="button" className="btn cancel-btn" data-dismiss="modal">No</button>
                                        <button type="button" className="btn red-btn ml-3" onClick={this.props.removeChannel.bind(this, rTwitchName)} data-dismiss="modal">Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrganizationInformation;