import { PROFILE_START, PROFILE_SUCCESS, PROFILE_FAIL } from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
    isProfileUpdated: false,
    profileData: null,
    profileError: null
};

export default (state = INITIAL_STATE, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
        case PROFILE_START:
            currentState.isProfileUpdated = false;
            currentState.profileData = null
            break;
        case PROFILE_SUCCESS:
            currentState.isProfileUpdated = true;
            currentState.profileData = action.payload
            break;
        case PROFILE_FAIL:
            currentState.isProfileUpdated = false;
            currentState.profileError = action.payload
            break;
        default:
            return currentState;
    }
    return currentState;
}


