import React from 'react'
import { NavLink } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <div class="d-flex justify-content-center align-items-center" id="pageNotFound">
            <h1 class="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
            <div class="inline-block align-middle">
                <h2 class="font-weight-normal lead" id="desc">The page you requested was not found.</h2>
                <NavLink to={"/"} class="btn btn-link">Back to Home</NavLink>
            </div>
        </div>
    )
}

export default PageNotFound