import React from "react";
import constant from "../../constants/constant";

export default function PasswordStrengthView({
  passwordValue,
  passwordStrengthListener
}) {
  var passwordType = "Weak";
  var passwordCSS = "pass-strength weak-color";
  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  var mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );
  if (strongRegex.test(passwordValue)) {
    passwordType = constant.STRONG_PASSWORD;
    passwordCSS = "pass-strength strong-color";
  } else if (mediumRegex.test(passwordValue)) {
    passwordType = constant.MEDIUM_PASSWORD;
    passwordCSS = "pass-strength medium-color";
  } else {
    passwordType = passwordType = constant.WEAK_PASSWORD;
    passwordCSS = "pass-strength weak-color";
  }
  passwordStrengthListener(passwordType);
  return <div className={`${passwordValue ? "d-block" : 'd-none'}`}><span className={passwordCSS}>{passwordType}</span></div>;
}
