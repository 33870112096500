let BASE_URL_PATH;
let PUBLISHER_KEY;
let SCORING_APK_URL = "";
let CLUB_APK_URL = "";
let STREAMING_APK_URL = "";

const scoring_app_dev = 'https://pabsa-mobile-app.s3.amazonaws.com/dev/scoring-app/Cloud-Snooker-Scoring-dev-release-v2.0.7.apk'
const club_app_dev = 'https://pabsa-mobile-app.s3.amazonaws.com/dev/club-app/CloudSnookerClub-dev-release-v1.1.2.apk'
const streaming_app_dev = 'https://pabsa-mobile-app.s3.amazonaws.com/dev/streaming-app/Cloud-Snooker-Capture-dev-release-v1.1.6.apk'

const scoring_app_qa = 'https://pabsa-mobile-app.s3.amazonaws.com/qa/scoring-app/Cloud-Snooker-Scoring-qa-release-v2.0.7.apk'
const club_app_qa = 'https://pabsa-mobile-app.s3.amazonaws.com/qa/club-app/CloudSnookerClub-qa-release-v1.1.2.apk'
const streaming_app_qa = 'https://pabsa-mobile-app.s3.amazonaws.com/qa/streaming-app/Cloud-Snooker-Capture-qa-release-v1.1.6.apk'

const scoring_app_prod = 'https://pabsa-mobile-app.s3.amazonaws.com/prod/scoring-app/Cloud-Snooker-Scoring-prod-release-v2.0.7.apk'
const club_app_prod = 'https://pabsa-mobile-app.s3.amazonaws.com/prod/club-app/CloudSnookerClub-prod-release-v1.1.2.apk'
const streaming_app_prod = 'https://pabsa-mobile-app.s3.amazonaws.com/prod/streaming-app/Cloud-Snooker-Capture-prod-release-v1.1.6.apk'


if (process.env.NODE_ENV === 'development') {
  BASE_URL_PATH =  'https://pabsa.impressicocrm.com/api'//'https://172.20.22.27/api'

  PUBLISHER_KEY = 'pk_test_51ILmNdJLVloLuuYW8RvPEuauFdAPKhj7Eb3gBeR3aSD44ECLmPV4kLoujy8QMTbn9hmTVl73JgXzBafFoeErOesV003NB5mptu'
  SCORING_APK_URL = scoring_app_qa;
  CLUB_APK_URL = club_app_qa;
  STREAMING_APK_URL = streaming_app_qa;
} else if (process.env.REACT_APP_NODE_ENV === 'production') {
  BASE_URL_PATH = process.env.REACT_APP_BASE_URL
  PUBLISHER_KEY = 'pk_live_51HZKJkEJddy2vLneP5j80sHzqxIUtzFQ5sNXlTH0xDUmLnrne75PQcN8r1Qn2Ifk0BPVDERRgYmPx7apIz2ZUD0X00KZ4hdBxB'
  SCORING_APK_URL = scoring_app_prod;
  CLUB_APK_URL = club_app_prod;
  STREAMING_APK_URL = streaming_app_prod;
} else if (process.env.REACT_APP_NODE_ENV === 'staging') {
  BASE_URL_PATH = process.env.REACT_APP_BASE_URL
  PUBLISHER_KEY = 'pk_test_51ILmNdJLVloLuuYW8RvPEuauFdAPKhj7Eb3gBeR3aSD44ECLmPV4kLoujy8QMTbn9hmTVl73JgXzBafFoeErOesV003NB5mptu'
} else if (process.env.REACT_APP_NODE_ENV === 'qa') {
  BASE_URL_PATH = process.env.REACT_APP_BASE_URL
  PUBLISHER_KEY = 'pk_test_51ILmNdJLVloLuuYW8RvPEuauFdAPKhj7Eb3gBeR3aSD44ECLmPV4kLoujy8QMTbn9hmTVl73JgXzBafFoeErOesV003NB5mptu'
  SCORING_APK_URL = scoring_app_qa
  CLUB_APK_URL = club_app_qa
  STREAMING_APK_URL = streaming_app_qa
} else if (process.env.REACT_APP_NODE_ENV === 'dev') {
  BASE_URL_PATH = process.env.REACT_APP_BASE_URL
  PUBLISHER_KEY = 'pk_test_51ILmNdJLVloLuuYW8RvPEuauFdAPKhj7Eb3gBeR3aSD44ECLmPV4kLoujy8QMTbn9hmTVl73JgXzBafFoeErOesV003NB5mptu'
  SCORING_APK_URL = scoring_app_dev
  CLUB_APK_URL = club_app_dev
  STREAMING_APK_URL = streaming_app_dev
}
else {
  BASE_URL_PATH = 'https://pabsa.impressicocrm.com/api'
  PUBLISHER_KEY = 'pk_test_51ILmNdJLVloLuuYW8RvPEuauFdAPKhj7Eb3gBeR3aSD44ECLmPV4kLoujy8QMTbn9hmTVl73JgXzBafFoeErOesV003NB5mptu'
  SCORING_APK_URL = scoring_app_qa
  CLUB_APK_URL = club_app_qa
  STREAMING_APK_URL = streaming_app_qa
}

const config = {
  BASE_URL: BASE_URL_PATH,
  PAGE_SIZE: 10,
  PAGE_SIZE_20:20,
  STRIPE_PUBLISHER_KEY: PUBLISHER_KEY,
  SCORING_APK_URL: SCORING_APK_URL,
  CLUB_APK_URL: CLUB_APK_URL,
  STREAMING_APK_URL: STREAMING_APK_URL
};

export default config;
