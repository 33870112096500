import React, { Component } from "react";
// import constant from "../../constants/constant";

export class PasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true
    };
    this.handlerCopy = this.handlerCopy.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.setState({ hidden: true });
    }
  }

  hideShowPassword = event => {
    //event.preventDefault();
    this.setState({ hidden: !this.state.hidden });
  };

  handlerCopy(e) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  }

  render() {
    return (
      <div className="position-relative">
        <input
        autoComplete={this.props.autoComplete}
          type={this.state.hidden ? "password" : "text"}
          className={`form-control ${
            this.props.submitted && this.props.errorClass ? "input-error" : ""
            }`}
          name={this.props.name}
          placeholder={this.props.placeholder}
          onChange={this.props.handleChangeEvent}
          value={this.props.value}
          onCopy={this.handlerCopy}
          onCut={this.handlerCopy}
          onPaste={this.handlerCopy}
        />
        <div>
          <div
            onClick={() => {
              this.hideShowPassword();
            }}
          >
            {this.props.value.length > 0 && ( !this.state.hidden ? <i className="fa fa-eye view-pass" aria-hidden="true"></i> 
            :<i class="fa fa-eye-slash view-pass" aria-hidden="true"></i>)}
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordField;
