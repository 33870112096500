import React, { Component } from "react";
import { connect } from "react-redux";
// import ResetAction from "../../commonAction/ResetAction";
export class ErrorAlert extends Component {
  // componentWillUnmount() {
  //   this.props.dispatch(ResetAction());
  // }
  render() {
    var style = {};
    if (!this.props.isShow) {
      style.display = "none";
    }
    return (
      <div style={style} className="error-alert" role="alert">
        {this.props.errorMessage}
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlert);
