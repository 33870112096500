import {
    GET_CLUB_ADMIN_USER_START,
    GET_CLUB_ADMIN_USER_SUCCESS,
    GET_CLUB_ADMIN_USER_FAIL,
} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
    isGetClubAdminSuccess: false,
    response: null,
    errorMsg: null
};

export default (state = INITIAL_STATE, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
        case GET_CLUB_ADMIN_USER_START:
            currentState.isGetClubAdminSuccess = false;
            break;
        case GET_CLUB_ADMIN_USER_SUCCESS:
            currentState.isGetClubAdminSuccess = true;
            currentState.response = action.payload;
            break;
        case GET_CLUB_ADMIN_USER_FAIL:
            currentState.isGetClubAdminSuccess = false;
            currentState.errorMsg = action.payload;
            break;
        default:
            return currentState;
    }
    return currentState;
};
