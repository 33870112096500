import {
  CLUB_LIST_START,
  CLUB_LIST_SUCCESS,
  CLUB_LIST_FAIL,
  CLUB_EDIT_FAIL,
  CLUB_EDIT_START,
  CLUB_EDIT_SUCCESS,
  CLUB_DELETE_START,
  CLUB_DELETE_SUCCESS,
  CLUB_DELETE_FAIL,
  CLUB_SINGLE_DETAILS_START,
  CLUB_SINGLE_DETAILS_SUCCESS,
  CLUB_SINGLE_DETAILS_FAIL,
  GET_CLUB_SUBSCRIPTION_LIST_START,
  GET_CLUB_SUBSCRIPTION_LIST_SUCCESS,
  GET_CLUB_SUBSCRIPTION_LIST_FAIL,
  GET_MEMBER_SUBSCRIPTION_LIST_START,
  GET_MEMBER_SUBSCRIPTION_LIST_SUCCESS,
  GET_MEMBER_SUBSCRIPTION_LIST_FAIL,
  GET_PLATFORM_SUBSCRIPTION_LIST_START,
  GET_PLATFORM_SUBSCRIPTION_LIST_SUCCESS,
  GET_PLATFORM_SUBSCRIPTION_LIST_FAIL,
  ADD_CLUB_SUBSCRIPTION_LIST_START,
  ADD_CLUB_SUBSCRIPTION_LIST_SUCCESS,
  ADD_CLUB_SUBSCRIPTION_LIST_FAIL,
  ADD_MEMBER_SUBSCRIPTION_LIST_START,
  ADD_MEMBER_SUBSCRIPTION_LIST_SUCCESS,
  ADD_MEMBER_SUBSCRIPTION_LIST_FAIL,
  ADD_PLATFORM_SUBSCRIPTION_LIST_START,
  ADD_PLATFORM_SUBSCRIPTION_LIST_SUCCESS,
  ADD_PLATFORM_SUBSCRIPTION_LIST_FAIL,
  DELETE_SUBSCRIPTION_LIST_START,
  DELETE_SUBSCRIPTION_LIST_SUCCESS,
  DELETE_SUBSCRIPTION_LIST_FAIL,
} from "../../../constants/type";
import API from "../../utils/api";
import config from "../../../constants/config";
import constant from "../../../constants/constant";
import { toast } from "react-toastify";


export const clubManagementAction = (data) => {
  return async function (dispatch) {

    dispatch({ type: CLUB_LIST_START });
    let pageSize = data.clubDataMember ? 1000 : config.PAGE_SIZE;
    let pageNumber = data.activePage
    let clubStatusId = data.searchBy.clubStatusId
    let clubName = data.searchBy.clubName

    //console.log("---------------- data ===>>>", data)
    let params;
    if (clubStatusId && clubName) {
      params = pageSize + `&pageNumber=` + pageNumber + `&clubStatusId=` + clubStatusId + `&clubName=` + clubName
    } else if (clubStatusId) {
      params = pageSize + `&pageNumber=` + pageNumber + `&clubStatusId=` + clubStatusId
    } else if (clubName) {
      params = pageSize + `&pageNumber=` + pageNumber + `&clubName=` + clubName
    } else {
      params = pageSize + `&pageNumber=` + pageNumber
    }
    if (data.clubId) {
      params = params + `&clubId=` + data.clubId
    }
    await API.getRequest(`${config.BASE_URL}/club?limit=` + params)
      .then(response => {
        //success API response
        return dispatch({
          type: CLUB_LIST_SUCCESS,
          payload: response
        });
      })
      .catch(error => {
        //failed Api response
        return dispatch({ type: CLUB_LIST_FAIL, payload: error });
      });
  };
};

export const deleteSingleRecordsAction = (clubId) => {
  return function (dispatch) {
    dispatch({ type: CLUB_DELETE_START });
    //console.log("----------------------------", clubId)
    //return
    API.deleteRequest(`${config.BASE_URL}/club/` + clubId)
      .then(response => {
        //success API response
        //console.log("****************", response)
        return dispatch({
          type: CLUB_DELETE_SUCCESS,
          payload: response
        });
      })
      .catch(error => {
        //failed Api response
        return dispatch({ type: CLUB_DELETE_FAIL, payload: error });
      });
  }
}

export const getSingleclubDetailsAction = (clubId) => {
  return async function (dispatch) {
    dispatch({ type: CLUB_SINGLE_DETAILS_START });
    //console.log("----------------------------", clubId)

    await API
      .getRequest(`${config.BASE_URL}/club/${clubId}`)
      .then(response => {
        dispatch({ type: CLUB_SINGLE_DETAILS_SUCCESS, payload: response });
      })
      .catch(error => {
        dispatch({ type: CLUB_SINGLE_DETAILS_FAIL, payload: error });
      });
  };
}


export const editClubAction = (clubId, actionName, history) => {
  return function (dispatch) {
    dispatch({ type: CLUB_EDIT_START });
    API
      .getRequest(`${config.BASE_URL}/club/${clubId}`)
      .then(response => {
        if (actionName === constant.EDIT_CLUB) {
          dispatch({ type: CLUB_EDIT_SUCCESS, payload: response });
          // history.push('/add-club')
          history.push('/add-club', {state: { clubId: clubId, actionName: actionName } })
        } else {
          //for refresh club when channel added
          dispatch({ type: CLUB_EDIT_SUCCESS, payload: response });
        }
      })
      .catch(error => {
        dispatch({ type: CLUB_EDIT_FAIL, payload: error });
      });
  };
};

export const getClubSubscriptionList = (userId, clubName) => {
  clubName = clubName ? clubName : "";
  let query = `createdBy=${userId}&limit=1000&pageNumber=1&clubName=${clubName}`

  return async function (dispatch) {
    dispatch({ type: GET_CLUB_SUBSCRIPTION_LIST_START });
    await API
      .getRequest(`${config.BASE_URL}/club/subscription?${query}`)
      .then(response => {
        const arr1 = response.clubs.filter(data => data.subscription !== null)
        arr1.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.subscription.createdAt) - new Date(a.subscription.createdAt);
        });
        const arr2 = response.clubs.filter(data => data.subscription === null)
        const arr = [...arr1, ...arr2]
        response.clubs = arr
          dispatch({ type: GET_CLUB_SUBSCRIPTION_LIST_SUCCESS, payload: response });
      })
      .catch(error => {
        toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
        dispatch({ type: GET_CLUB_SUBSCRIPTION_LIST_FAIL, payload: error });
      });
  };
};

export const addClubSubscriptionList = (reqPayload) => {
  return async function (dispatch) {
    dispatch({ type: ADD_CLUB_SUBSCRIPTION_LIST_START });
    await API
      .postRequest(`${config.BASE_URL}/club/subscription`,reqPayload)
      .then(response => {
          dispatch({ type: ADD_CLUB_SUBSCRIPTION_LIST_SUCCESS, payload: response });
      })
      .catch(error => {
        toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
        dispatch({ type: ADD_CLUB_SUBSCRIPTION_LIST_FAIL, payload: error });
      });
  };
};

export const getMemberSubscriptionList = (userId, clubId, filter) => {
  return async function (dispatch) {
    dispatch({ type: GET_MEMBER_SUBSCRIPTION_LIST_START });
    await API
      .getRequest(`${config.BASE_URL}/member/subscription?createdBy=${userId}&clubId=${clubId}&limit=1000&pageNumber=1&&fullName=${filter?.memberName?filter?.memberName:''}&email=${filter?.email?filter?.email.replace("+", "%2B"):''}`)
      .then(response => {
        const arr1 = response.players.filter(data => data.subscription !== null)
        arr1.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.subscription.createdAt) - new Date(a.subscription.createdAt);
        });
        const arr2 = response.players.filter(data => data.subscription === null)
        const arr = [...arr1, ...arr2]
        response.players = arr
        console.log("ttttttttt",response)
        dispatch({ type: GET_MEMBER_SUBSCRIPTION_LIST_SUCCESS, payload: response });
      })
      .catch(error => {
        toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
        dispatch({ type: GET_MEMBER_SUBSCRIPTION_LIST_FAIL, payload: error });
      });
  };
};

export const addMemberSubscriptionList = (reqPayload) => {
  return async function (dispatch) {
    dispatch({ type: ADD_MEMBER_SUBSCRIPTION_LIST_START });
    await API
      .postRequest(`${config.BASE_URL}/member/subscription/club`,reqPayload)
      .then(response => {
          dispatch({ type: ADD_MEMBER_SUBSCRIPTION_LIST_SUCCESS, payload: response });
      })
      .catch(error => {
        toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
        dispatch({ type: ADD_MEMBER_SUBSCRIPTION_LIST_FAIL, payload: error });
      });
  };
};

export const getPlateformSubscriptionList = (userId, filter) => {
  return async function (dispatch) {
    dispatch({ type: GET_PLATFORM_SUBSCRIPTION_LIST_START });
    await API
      .getRequest(`${config.BASE_URL}/member/subscription?createdBy=${userId}&limit=1000&pageNumber=1&fullName=${filter?.memberName?filter?.memberName:''}&email=${filter?.email?filter?.email.replace("+", "%2B"):''}`)
      .then(response => {
        const arr1 = response.players.filter(data => data.subscription !== null)
        arr1.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.subscription.createdAt) - new Date(a.subscription.createdAt);
        });

        const arr2 = response.players.filter(data => data.subscription === null)
        const arr = [...arr1, ...arr2]
        response.players = arr
          dispatch({ type: GET_PLATFORM_SUBSCRIPTION_LIST_SUCCESS, payload: response });
      })
      .catch(error => {
        toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
        dispatch({ type: GET_PLATFORM_SUBSCRIPTION_LIST_FAIL, payload: error });
      });
  };
};

export const addPlateformSubscriptionList = (reqPayload) => {
  return async function (dispatch) {
    dispatch({ type: ADD_PLATFORM_SUBSCRIPTION_LIST_START });
    await API
      .postRequest(`${config.BASE_URL}/member/subscription/platform`,reqPayload)
      .then(response => {
          dispatch({ type: ADD_PLATFORM_SUBSCRIPTION_LIST_SUCCESS, payload: response });
      })
      .catch(error => {
        toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
        dispatch({ type: ADD_PLATFORM_SUBSCRIPTION_LIST_FAIL, payload: error });
      });
  };
};
export const deleteSubscription = (subscriptionId,subscriptionScheduleId) => {
 
  // console.log(subscriptionId,subscriptionScheduleId)
  return async function (dispatch) {
    dispatch({ type: DELETE_SUBSCRIPTION_LIST_START });
    await API
      // .putRequest(`${config.BASE_URL}/cancel/subscription/${subscriptionId}`)
      .putRequest(`${config.BASE_URL}/cancel/subscription?subscriptionId=${subscriptionId}&subscriptionScheduleId=${subscriptionScheduleId}`)
      .then(response => {
          dispatch({ type: DELETE_SUBSCRIPTION_LIST_SUCCESS, payload: response });
      })
      .catch(error => {
        toast.error(error?.response?.data?.message ?error.response.data.message:"Something went wrong");
        dispatch({ type: DELETE_SUBSCRIPTION_LIST_FAIL, payload: error });
      });
  };
};
