import {
  GET_PRODUCT_LIST_START,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAIL,
  ADD_PRODUCT_LIST_START,
  ADD_PRODUCT_LIST_SUCCESS,
  ADD_PRODUCT_LIST_FAIL,
  UPDATE_PRODUCT_LIST_START,
  UPDATE_PRODUCT_LIST_SUCCESS,
  UPDATE_PRODUCT_LIST_FAIL,
  DELETE_PRODUCT_LIST_START,
  DELETE_PRODUCT_LIST_SUCCESS,
  DELETE_PRODUCT_LIST_FAIL,
  GET_PRODUCT_PRICE_LIST_START,
  GET_PRODUCT_PRICE_LIST_SUCCESS,
  GET_PRODUCT_PRICE_LIST_FAIL,
  ADD_PRODUCT_PRICE_LIST_START,
  ADD_PRODUCT_PRICE_LIST_SUCCESS,
  ADD_PRODUCT_PRICE_LIST_FAIL,
  UPDATE_PRODUCT_PRICE_LIST_START,
  UPDATE_PRODUCT_PRICE_LIST_SUCCESS,
  UPDATE_PRODUCT_PRICE_LIST_FAIL,
  DELETE_PRODUCT_PRICE_LIST_START,
  DELETE_PRODUCT_PRICE_LIST_SUCCESS,
  DELETE_PRODUCT_PRICE_LIST_FAIL,
  GET_PRODUCT_ENABLE_PRICE_LIST_START,
  GET_PRODUCT_ENABLE_PRICE_LIST_SUCCESS,
  GET_PRODUCT_ENABLE_PRICE_LIST_FAIL,
} from "../constants/type";
import { cloneDeep } from "lodash";

const INITIAL_STATE = {
  isLoading: false,
  productList: null,
  addProductRes: null,
  addProductResErr: null,
  updateProductRes: null,
  deleteProductRes: null,
  productPriceList: null,
  addProductPriceRes: null,
  updateProductPriceRes: null,
  deleteProductPriceRes: null,
  productEnablePriceList:null
};

export default (state = INITIAL_STATE, action) => {
  let currentState = cloneDeep(state);
  switch (action.type) {
    case GET_PRODUCT_LIST_START:
      currentState.isLoading = true;
      break;
    case GET_PRODUCT_LIST_SUCCESS:
      currentState.isLoading = false;
      currentState.productList = action.payload;
      break;
    case GET_PRODUCT_LIST_FAIL:
      currentState.isLoading = false;
      currentState.productList = action.payload;
      break;
    case ADD_PRODUCT_LIST_START:
      currentState.isLoading = true;
      currentState.addProductResErr = null;
      break;
    case ADD_PRODUCT_LIST_SUCCESS:
      currentState.isLoading = false;
      currentState.addProductRes = action.payload;
      break;
    case ADD_PRODUCT_LIST_FAIL:
      currentState.isLoading = false;
      currentState.addProductResErr = action.payload;
      break;
    case UPDATE_PRODUCT_LIST_START:
      currentState.isLoading = true;
      currentState.addProductResErr = null;
      break;
    case UPDATE_PRODUCT_LIST_SUCCESS:
      currentState.isLoading = false;
      currentState.updateProductRes = action.payload;
      break;
    case UPDATE_PRODUCT_LIST_FAIL:
      currentState.isLoading = false;
      currentState.addProductResErr = action.payload;
      break;
    case DELETE_PRODUCT_LIST_START:
      currentState.isLoading = true;
      break;
    case DELETE_PRODUCT_LIST_SUCCESS:
      currentState.isLoading = false;
      currentState.deleteProductRes = action.payload;
      break;
    case DELETE_PRODUCT_LIST_FAIL:
      currentState.isLoading = false;
      currentState.deleteProductRes = action.payload;
      break;
    case GET_PRODUCT_PRICE_LIST_START:
      currentState.isLoading = true;
      break;
    case GET_PRODUCT_PRICE_LIST_SUCCESS:
      currentState.isLoading = false;
      currentState.productPriceList = action.payload;
      break;
    case GET_PRODUCT_PRICE_LIST_FAIL:
      currentState.isLoading = false;
      currentState.productPriceList = action.payload;
      break;
    case ADD_PRODUCT_PRICE_LIST_START:
      currentState.isLoading = true;
      break;
    case ADD_PRODUCT_PRICE_LIST_SUCCESS:
      currentState.isLoading = false;
      currentState.addProductPriceRes = action.payload;
      break;
    case ADD_PRODUCT_PRICE_LIST_FAIL:
      currentState.isLoading = false;
      currentState.addProductPriceRes = action.payload;
      break;
    case UPDATE_PRODUCT_PRICE_LIST_START:
      currentState.isLoading = true;
      break;
    case UPDATE_PRODUCT_PRICE_LIST_SUCCESS:
      currentState.isLoading = false;
      currentState.updateProductPriceRes = action.payload;
      break;
    case UPDATE_PRODUCT_PRICE_LIST_FAIL:
      currentState.isLoading = false;
      currentState.updateProductPriceRes = action.payload;
      break;
    case DELETE_PRODUCT_PRICE_LIST_START:
      currentState.isLoading = true;
      break;
    case DELETE_PRODUCT_PRICE_LIST_SUCCESS:
      currentState.isLoading = false;
      currentState.deleteProductPriceRes = action.payload;
      break;
    case DELETE_PRODUCT_PRICE_LIST_FAIL:
      currentState.isLoading = false;
      currentState.deleteProductPriceRes = action.payload;
      break;
    case GET_PRODUCT_ENABLE_PRICE_LIST_START:
      currentState.isLoading = true;
      break;
    case GET_PRODUCT_ENABLE_PRICE_LIST_SUCCESS:
      currentState.isLoading = false;
      currentState.productEnablePriceList = action.payload;
      break;
    case GET_PRODUCT_ENABLE_PRICE_LIST_FAIL:
      currentState.isLoading = false;
      currentState.productEnablePriceList = action.payload;
      break;
    default:
      return currentState;
  }
  return currentState;
};
