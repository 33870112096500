import {
    COUNTRY_API_FAIL,
    COUNTRY_API_START,
    COUNTRY_API_SUCCESS,
    PROFILE_DETAIL_START,
    PROFILE_DETAIL_SUCCESS,
    PROFILE_DETAIL_FAIL,
} from "../constants/type";
import myApi from '../container/utils/api';
import config from "../constants/config";
import constant from "../constants/constant";
import utils from '../container/utils/utils';
import UtilService from "../container/utils/utils";


export const countryApiAction = () => {
    return async function (dispatch) {
        dispatch({ type: COUNTRY_API_START });
        await myApi
            .getRequestWithAsyn(`${config.BASE_URL}/country`)
            .then(response => {
                if(response){
                UtilService.setInfoInStorage('localStorage', constant.COUNTRY_LIST, JSON.stringify(response))
                return dispatch({ type: COUNTRY_API_SUCCESS, payload: JSON.stringify(response)});
            }

            })
            .catch(error => {
                return dispatch({ type: COUNTRY_API_FAIL, payload: (error.response && error.response.data) ? error.response.data.message : "" });
            });
    };
};

// Get Profile Details....
export const getprofileApiAction = () => {
    return async function (dispatch) {
        dispatch({ type: PROFILE_DETAIL_START });
        await myApi
            .getRequestWithAsyn(`${config.BASE_URL}/profile`)
            .then(response => {
                if(response){
                    utils.setInfoInStorage('localStorage', constant.CURRENT_USER, response)
                return dispatch({ type: PROFILE_DETAIL_SUCCESS, payload: response });
            }

            })
            .catch(error => {
                return dispatch({ type: PROFILE_DETAIL_FAIL, payload: (error.response && error.response.data) ? error.response.data.message : "" });
            });
    };
};


