import React, { Component } from 'react';
import _ from 'lodash';
import UtilService from '../utils/utils';

//This is commpont component for show country Id 
export default class CountryCodeSelect extends Component {
    // constructor(props) {
    //     super(props)
    // }
    //On the change event for the select box pass the selected event back to the parent
    handleChange = (event) => {
        // let selectedValue = event.target.value;
        if(this.props.countryIdSelection){
            let countryArray = JSON.parse(UtilService.getCountryList());
            let obj = countryArray.countries.find(c=>c.countryId === event.target.value)
            this.props.onSelectChange(event,obj.countryName); 
        } else {
            this.props.onSelectChange(event); 
        }
    }
    render() {
        let countryArray = JSON.parse(UtilService.getCountryList());
        let key = this.props.selectionKey
        //isCountryNameSorting is a boolean value to distinguish which component is using a country code with a contact number or a country name list for an address.
        //isCountryNameSorting is a boolean value to shorting country List by courtry name.
        let isCountryNameSorting = this.props.isCountryNameSorting ? this.props.isCountryNameSorting : false;
        var options
        if (countryArray && countryArray.countries) {
            let countries = countryArray?.countries;
            if (isCountryNameSorting) {
                countries = _.sortBy(countryArray.countries,
                    [function (o) {
                        if (o.countryId == "US" || o.countryId == "CA") {
                            return 1;
                        } else {
                            return o.countryName;
                        }
                    }]);
            }
         
            options = countries.map((countries) =>
                <option value={this.props.countryIdSelection ? countries['countryId'] : (key == 'countryCode' ? `${countries.countryId},${countries[key]}` : countries[key])} key={countries.countryId}>{(key == 'countryCode' ? `${countries.countryId}(${countries[key]})` : countries[key])}</option>
            );
        }
        return (
            <div className="position-relative">
                <select
                    id="selectBox"
                    className={this.props.className}
                    name={this.props.name}
                    onChange={this.handleChange}
                    value={this.props.value}
                    disabled={this.props.readOnly}
                >
                    {options}
                </select>
                <i className="arrow-icon"></i>
            </div>
        );
    }
}
