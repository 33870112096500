import {
    SAVECARD_FAIL,
    SAVECARD_START,
    SAVECARD_SUCCESS,
    GETCARD_DETAILS_START,
    GETCARD_DETAILS_FAIL,
    GETCARD_DETAILS_SUCCESS,
    UPDATECARD_START,
    UPDATECARD_FAIL,
    UPDATECARD_SUCCESS,
    DELETECARD_START,
    DELETECARD_FAIL,
    DELETECARD_SUCCESS,
    PAYMENT_START,
    PAYMENT_SUCCESS,
    PAYMENT_FAIL,
    TRANSACTION_START,
    TRANSACTION_SUCCESS,
    TRANSACTION_FAIL,
    PAIDCTA_START,
    PAIDCTA_SUCCESS,
    PAIDCTA_FAIL,
} from "../constants/type";
import { cloneDeep } from "lodash";
const INITIAL_STATE = {
    saveCardResponse: [],
    saveCardResponseError: false,
    saveCardErrorMsg: null,
    getTransactionResponseSuccess: "",
    getTransactionResponseError: "",
    getTransactionResponseErrorMsg: ""
};

export default (state = INITIAL_STATE, action) => {
    let currentState = cloneDeep(state);
    switch (action.type) {
        case SAVECARD_START:
            currentState.saveCardResponse = null
            currentState.saveCardResponseError = false;
            currentState.saveCardErrorMsg = null;
            break;
        case SAVECARD_SUCCESS:
            currentState.saveCardResponse = action.payload[0];
            currentState.saveCardResponseData = action.payload[1];
            currentState.saveCardResponseError = false;
            currentState.saveCardErrorMsg = null;
            break;
        case SAVECARD_FAIL:
            currentState.saveCardResponse = [];
            currentState.saveCardResponseError = true;
            currentState.saveCardErrorMsg = action.payload;
            break;
        case GETCARD_DETAILS_START:
            currentState.getCardResponse = null;
            currentState.getCardResponseError = false;
            currentState.getCardResponseErrorMsg = null;
            break;
        case GETCARD_DETAILS_SUCCESS:
            currentState.getCardResponse = action.payload;
            currentState.getCardResponseError = false;
            currentState.getCardResponseErrorMsg = null;
            break;
        case GETCARD_DETAILS_FAIL:
            currentState.getCardResponse = [];
            currentState.getCardResponseError = true;
            currentState.getCardResponseErrorMsg = action.payload;
            break;
        case UPDATECARD_START:
            currentState.getEditCardResponse = null;
            currentState.getEditCardResponseError = false;
            currentState.getEditCardResponseErrorMsg = null;
            break;
        case UPDATECARD_SUCCESS:
            currentState.getEditCardResponse = action.payload;
            currentState.getEditCardResponseError = false;
            currentState.getEditCardResponseErrorMsg = null;
            break;
        case UPDATECARD_FAIL:
            currentState.getEditCardResponse = [];
            currentState.getEditCardResponseError = true;
            currentState.getEditCardResponseErrorMsg = action.payload;
            break;
        case DELETECARD_START:
            currentState.getDeleteCardResponse = null;
            currentState.getDeleteCardResponseError = false;
            currentState.getDeleteCardResponseErrorMsg = null;
            break;
        case DELETECARD_SUCCESS:
            currentState.getDeleteCardResponse = action.payload;
            currentState.getDeleteCardResponseError = false;
            currentState.getDeleteCardResponseErrorMsg = null;
            break;
        case DELETECARD_FAIL:
            currentState.getDeleteCardResponse = [];
            currentState.getDeleteCardResponseError = true;
            currentState.getDeleteCardResponseErrorMsg = action.payload;
            break;
        case PAYMENT_START:
            currentState.getPaymentResponseSuccess = null;
            currentState.getPaymentResponseError = false;
            currentState.getPaymentResponseErrorMsg = null;
            break;
        case PAYMENT_SUCCESS:
            currentState.getPaymentResponseSuccess = action.payload;
            currentState.getPaymentResponseError = false;
            currentState.getPaymentResponseErrorMsg = null;
            break;
        case PAYMENT_FAIL:
            currentState.getPaymentResponseSuccess = false;
            currentState.getPaymentResponseError = true;
            currentState.getPaymentResponseErrorMsg = action.payload;
            break;

        case TRANSACTION_START:
            currentState.getTransactionResponseSuccess = null;
            currentState.getTransactionResponseError = false;
            currentState.getTransactionResponseErrorMsg = null;
            break;
        case TRANSACTION_SUCCESS:
            currentState.getTransactionResponseSuccess = action.payload;
            currentState.getTransactionResponseError = false;
            currentState.getTransactionResponseErrorMsg = null;
            break;
        case TRANSACTION_FAIL:
            currentState.getTransactionResponseSuccess = false;
            currentState.getTransactionResponseError = true;
            currentState.getTransactionResponseErrorMsg = action.payload;
            break;

        case PAIDCTA_START:
            currentState.getPaidCTAResponseSuccess = null;
            currentState.getPaidCTAResponseError = false;
            currentState.getPaidCTAResponseErrorMsg = null;
            break;
        case PAIDCTA_SUCCESS:
            currentState.getPaidCTAResponseSuccess = action.payload;
            currentState.getPaidCTAResponseError = false;
            currentState.getPaidCTAResponseErrorMsg = null;
            break;
        case PAIDCTA_FAIL:
            currentState.getPaidCTAResponseSuccess = false;
            currentState.getPaidCTAResponseError = true;
            currentState.getPaidCTAResponseErrorMsg = action.payload;
            break;

        default:
            return currentState;
    }
    return currentState;
};
