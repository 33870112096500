import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import ui from "./ui";
import forgotpassword from "./forgotpassword";
import setpassword from "./setpassword";
import changePasswordReducer from "./changePasswordReducer";
import signoutReducer from "./signoutReducer";
import myProfileReducer from './myProfileReducer'
import countryApiReducer from './countryApiReducer'
import addClubReducer from './addClubReducer'
import clubManagementReducer from './clubManagementReducer'
import editClubReducer from './editClubReducer'
import userDetailsReducer from './userDetailsReducer'
import deleteSingleRecordsReducer from './deleteSingleRecordsReducer'
import clubAdminReducer from './clubAdminReducer'
import getClubAdminReducer from './getClubAdminReducer'
import deleteAdminSingleUserReducer from './deleteAdminSingleUserReducer'
import getClubAdminUserDetailsReducer from './getClubAdminUserDetailsReducer'
import paymentReducer from './paymentReducer'
import apiReducer from './apiReducer';
import connectOnboardReducer from "./connectOnboardReducer";
import productReducer from "./productReducer";
import newsReducer from "./newsReducer";
export default combineReducers({
  loginReducer,
  ui,
  forgotpassword,
  setpassword,
  changePasswordReducer,
  signoutReducer,
  myProfileReducer,
  countryApiReducer,
  addClubReducer,
  clubManagementReducer,
  editClubReducer,
  userDetailsReducer,
  deleteSingleRecordsReducer,
  clubAdminReducer,
  getClubAdminReducer,
  deleteAdminSingleUserReducer,
  getClubAdminUserDetailsReducer,
  paymentReducer,
  apiReducer,
  connectOnboardReducer,
  productReducer,
  newsReducer
});
