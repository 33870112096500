import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import ValidationMessage from "../view/ValidationMessage";
import ErrorAlert from "../view/ErrorAlert";
import { forgotPasswordAction } from "../forgotpassword/action";
import ResetAction from '../../commonAction/ResetAction'
import { get } from "lodash";
import * as EmailValidator from 'email-validator';
import $ from "jquery";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: ""
      },
      submitted: false,
      error: {}
    };

    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.resetPasswordEvent = this.resetPasswordEvent.bind(this);
    this.props.forgotpassword.isForgotPasswordSuccess = false;
  }

  handleChangeEvent = event => {
    const { name, value } = event.target;
    const { user } = this.state;
    let error = {}
    this.setState({
      user: {
        ...user,
        [name]: value
      },
      error: error
    });

    error["email"] = ""
  };

  resetPasswordEvent = event => {
    event.preventDefault();
    let error = {}
    this.setState({
      submitted: true,
      error: error
    });

    const { user } = this.state;
    let emailIdFromate = EmailValidator.validate(user.email);
    if (emailIdFromate) {
      //forgot password api called
      this.props.dispatch(forgotPasswordAction(user.email));
    } else {
      error["email"] = "Please enter valid email address.";
    }
  };

  isShowView(isShow) {
    if (isShow) {
      return "d-inline-block";
    } else return "d-none";
  }

  handleThankuClick = () => {
    $('.modal').modal('hide')
    this.props.forgotpassword.isForgotPasswordSuccess = false;
    this.props.hideLayout()
  };

  render() {
    const { user, submitted, error } = this.state;
    const isForgotPasswordSuccess = get(
      this.props,
      "forgotpassword.isForgotPasswordSuccess",
      false
    );
    const has_error = get(this.props, "ui.has_error", false);
    const forgotPasswordErrorMessage = get(
      this.props,
      "forgotpassword.forgotPasswordError.response.data.message",
      null
    );
    const isLoading = get(this.props, "ui.is_loding", false);
    
    return (
      <div className="forgot-password-wrapper display-table">
        <div className="w-100 display-table-cell">
          <div className="login-page position-relative">
            <div className="text-center mb-4">
              <svg
                className={this.isShowView(!isForgotPasswordSuccess)}
                xmlns="http://www.w3.org/2000/svg"
                width="71"
                height="71"
                viewBox="0 0 71 71"
              >
                <g id="prefix__ic_lock" transform="translate(-151.5 -256.5)">
                  <g
                    data-name="Ellipse 28"
                    transform="translate(154 259)"
                    fill="#fff"
                    stroke="#2d3c25"
                    strokeWidth="2.5px"
                  >
                    <circle cx="33" cy="33" r="33" stroke="none" />
                    <circle cx="33" cy="33" r="34.25" fill="none" />
                  </g>
                  <g transform="translate(170.37 271)">
                    <path
                      id="prefix__Path_403"
                      d="M14.416 38.908a3.046 3.046 0 11-3.046 3.046 3.046 3.046 0 013.046-3.046zm6.459 3.046a3.046 3.046 0 103.046-3.046 3.046 3.046 0 00-3.046 3.046zm9.5 0a3.046 3.046 0 103.046-3.046 3.046 3.046 0 00-3.046 3.046zm9.491 0a3.046 3.046 0 103.046-3.046 3.046 3.046 0 00-3.041 3.046zm-6.848-27.489v-2.24a4.479 4.479 0 00-3.9-4.524 4.6 4.6 0 00-.52-.031 4.327 4.327 0 00-2.907 1.12 4.4 4.4 0 00-1.129 1.586 2.8 2.8 0 01-2.593 1.684 2.831 2.831 0 01-2.656-3.866A10.109 10.109 0 0128.673 2h.227a10.257 10.257 0 019.78 10.271v2.24h-.058a3.256 3.256 0 012.719 3.207v11.824a3.261 3.261 0 01-3.261 3.274H19.267a3.261 3.261 0 01-3.261-3.261V17.731a3.261 3.261 0 013.261-3.265zm-4.927 4.354a2.979 2.979 0 00-.99 5.451v2.688a1.572 1.572 0 001.572 1.559 1.572 1.572 0 001.572-1.572v-2.688a2.979 2.979 0 00-2.154-5.438z"
                      data-name="Path 403"
                      transform="translate(-11.37 -2)"
                      fill="#2d3c25"
                    />
                  </g>
                </g>
              </svg>
              <svg
                className={this.isShowView(isForgotPasswordSuccess)}
                xmlns="http://www.w3.org/2000/svg"
                width="71"
                height="71"
                viewBox="0 0 71 71"
              >
                <g id="prefix__ic_inbox" transform="translate(-151.5 -256.5)">
                  <g
                    data-name="Ellipse 28"
                    transform="translate(154 259)"
                    fill="#fff"
                    stroke="#2d3c25"
                    strokeWidth="2.5px"
                  >
                    <circle cx="33" cy="33" r="33" stroke="none" />
                    <circle cx="33" cy="33" r="34.25" fill="none" />
                  </g>
                  <g data-name="Path 4407" fill="#2d3c25">
                    <path
                      d="M42.34 999.217H8.66c-1.55 0-2.81-1.26-2.81-2.809v-20.387c0-1.549 1.26-2.809 2.81-2.809h33.68c1.55 0 2.81 1.26 2.81 2.81v20.386c0 1.549-1.26 2.809-2.81 2.809zm-32.82-2.073h31.96l-10.607-9.606-3.279 2.93c-.572.512-1.316.795-2.094.795-.778 0-1.522-.283-2.094-.796l-3.279-2.929-10.608 9.606zm22.907-10.998l10.65 9.647v-19.186l-10.65 9.539zm-24.504-9.54v19.187l10.65-9.647-10.65-9.54zm1.627-1.321l15.247 13.643c.15.135.413.215.703.215s.553-.08.703-.215l15.247-13.643H9.55z"
                      stroke="none"
                      transform="translate(162 -694.362)"
                    />
                    <path
                      d="M8.66 973.362c-1.459 0-2.66 1.202-2.66 2.66v20.386c0 1.458 1.201 2.659 2.66 2.659h33.68c1.459 0 2.66-1.201 2.66-2.659v-20.387c0-1.457-1.201-2.659-2.66-2.659H8.66m.498 1.773h32.684L26.303 989.04c-.188.169-.496.253-.803.253-.307 0-.615-.084-.803-.253l-15.54-13.905m-1.384 20.996v-19.86l11.024 9.874-11.024 9.986m35.454 0l-11.024-9.986 11.024-9.874v19.86M25.5 991.113c.715 0 1.43-.253 1.994-.757l3.38-3.02 10.996 9.958H9.13l10.996-9.957 3.38 3.019c.563.504 1.279.757 1.994.757m-16.84-18.05h33.68c1.633 0 2.96 1.327 2.96 2.958v20.387c0 1.631-1.327 2.959-2.96 2.959H8.66c-1.633 0-2.96-1.328-2.96-2.959v-20.387c0-1.631 1.327-2.959 2.96-2.959zm32.397 2.372H9.943l14.954 13.381c.122.11.353.177.603.177s.481-.067.603-.177l14.954-13.381zM18.35 986.147l-10.276-9.205v18.512l10.276-9.307zm24.578 9.307v-18.512l-10.276 9.205 10.276 9.307zM25.5 991.413c-.815 0-1.594-.296-2.194-.834l-3.178-2.839-10.22 9.254h31.184l-10.22-9.254-3.178 2.84c-.6.537-1.38.833-2.194.833z"
                      stroke="none"
                      fill="#2d3c25"
                      transform="translate(162 -694.362)"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <button type="button" className="close popup-close-btn" data-dismiss="modal" aria-label="Close"  onClick={this.props.hideLayout}>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                  <path d="M5.314 6.829l5.671 5.671-5.671 5.67a1.071 1.071 0 001.515 1.515l5.671-5.67 5.671 5.671a1.071 1.071 0 001.515-1.515L14.015 12.5l5.671-5.671a1.071 1.071 0 00-1.516-1.515l-5.67 5.671-5.671-5.671a1.071 1.071 0 00-1.515 1.515z" transform="translate(-5 -5)" fill="#666"/>
              </svg>
            </button>
            <form className="forgot-form" onSubmit={this.resetPasswordEvent} noValidate>
              <div className={this.isShowView(!isForgotPasswordSuccess)}>
                <h3 className="text-center mb-3">Forgot your Password?</h3>
                <p className="text-center">
                  Please enter your registered email address.You will get an email notification with the link to reset your password.
                </p>

                <div className="form-group position-relative">
                  <ErrorAlert
                    errorMessage={`${forgotPasswordErrorMessage}`}
                    isShow={has_error}
                  />
                  <label>Email address</label>
                  <div className="position-relative">
                    <input
                      type="email"
                      className={`form-control ${
                        submitted && !user.email ? "input-error" : submitted && user.email && error["email"] ? "input-error" : ""
                        }`}
                      name="email"
                      value={user.email}
                      onChange={this.handleChangeEvent}
                      placeholder="Enter email address"
                    />
                    {submitted && !user.email && (
                      <ValidationMessage message="Please enter email address" />
                    )}
                    {submitted && user.email && error["email"] && (
                      <ValidationMessage message="Please enter valid email address." />
                    )}
                  </div>
                </div>
                <div className="position-relative">
                  <div className={isLoading ? "loader" : null}></div>
                  <input
                    type="submit"
                    className={`btn login-btn w-100 ${
                      isLoading ? "pointer-event disabled " : null
                      }`}
                    value="Send Email"
                  />
                </div>
                <div className="text-center">
                  <span className="back-to-login cursor-pointer"  onClick={this.props.hideLayout}>
                    Back to Login
                  </span>
                </div>
              </div>
            </form>
            <div className={this.isShowView(isForgotPasswordSuccess)}>
              <h3 className="text-center mb-3">Check your inbox</h3>
              <p className="text-center">
                Please check your email (<strong>{user.email}</strong>) and click on the link to reset your password.

              </p>
              <div className="text-center">
                <button
                  className="btn login-btn pl-5 pr-5 m-0"
                  onClick={() => {
                    this.handleThankuClick();
                  }}
                >
                  OK, Thanks!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  componentWillUnmount() {
    this.props.dispatch(ResetAction());
  }
}

const mapStateToProps = state => ({
  forgotpassword: state.forgotpassword,
  ui: state.ui
});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ForgotPassword);
