import axios from "axios";
import constant from '../../constants/constant'
import UtilService from "../utils/utils";
// import history from '../utils/history';
import $ from 'jquery';
import { toast } from "react-toastify";
import { store } from '../../store';
import { UNAUTHORIZED_USER } from "../../constants/type";

class API {
  getRequest(url) {
    let internetError = checkNoInternet();
    if (internetError) {
      return Promise.reject({ ...internetError });
    }
    let authToken = UtilService.getInfoFromStorage('localStorage', constant.AUTH_TOKEN) //localStorage.getItem('authToken');
    const option = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": authToken ? "Bearer " + authToken : ''
      }
    };
    return axios.get(url, option).then(function (response) {
      return response.data;
    });
  }

  getRequestWithoutAuthToken(url) {
    let internetError = checkNoInternet();
    if (internetError) {
      return Promise.reject({ ...internetError });
    }
    const option = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    };
    return axios.get(url, option).then(function (response) {
      return response.data;
    })
      ;
  }
  async getRequestWithAsyn(url) {
    let internetError = checkNoInternet();
    if (internetError) {
      return internetError;
    }
    let authToken = UtilService.getInfoFromStorage('localStorage', constant.AUTH_TOKEN) //localStorage.getItem('authToken');
    const option = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": authToken ? "Bearer " + authToken : ''
      }
    };
    return await axios.get(url, option).then(function (response) {
      return response.data;
    });
  }

  postRequest(url, dataParams) {
    let internetError = checkNoInternet();
    if (internetError) {
      return Promise.reject({ ...internetError });
    }
    let authToken = UtilService.getInfoFromStorage('localStorage', constant.AUTH_TOKEN) //localStorage.getItem('authToken');
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": authToken ? "Bearer " + authToken : ''
      }
    };
    return axios.post(url, dataParams, config).then(function (response) {
      return response.data;
    });
  }

  postRequestWithMultiPart(url, dataParams) {
    let internetError = checkNoInternet();
    if (internetError) {
      return Promise.reject({ ...internetError });
    }
    let authToken = UtilService.getInfoFromStorage('localStorage', constant.AUTH_TOKEN);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Authorization": authToken ? "Bearer " + authToken : ''
      }
    };
    return axios.post(url, dataParams, config).then(function (response) {
      return response.data;
    });
  }

  putRequest(url, dataParams) {
    let internetError = checkNoInternet();
    if (internetError) {
      return Promise.reject({ ...internetError });
    }
    // let authToken = "";
    // if (url.indexOf('/user/resetPassword') > -1) {
    //   let path = window.location.search;
    //   authToken = path.split('?token=')[1];
    // } else {
    let authToken = UtilService.getInfoFromStorage('localStorage', constant.AUTH_TOKEN);
    // }
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": authToken ? "Bearer " + authToken : ''
      }
    };
    return axios.put(url, dataParams, config).then(function (response) {
      return response.data;
    });
  }

  putRequestWithoutAuth(url, dataParams) {
    let internetError = checkNoInternet();
    if (internetError) {
      return Promise.reject({ ...internetError });
    }
  
    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    return axios.put(url, dataParams, config).then(function (response) {
      return response.data;
    });
  }

  putRequestWithCustomAuth(url, dataParams, authToken) {
    let internetError = checkNoInternet();
    if (internetError) {
      return Promise.reject({ ...internetError });
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": authToken ? "Bearer " + authToken : ''
      }
    };
    return axios.put(url, dataParams, config).then(function (response) {
      return response.data;
    });
  }


  deleteRequest(url) {
    let internetError = checkNoInternet();
    if (internetError) {
      return Promise.reject({ ...internetError });
    }
    let authToken = UtilService.getInfoFromStorage('localStorage', constant.AUTH_TOKEN) //localStorage.getItem('authToken');
    const option = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": authToken ? "Bearer " + authToken : ''
      }
    };
    return axios.delete(url, option).then(function (response) {
      return response.data;
    });
  }

  deleteRequestWithNoResBody(url) {
    let internetError = checkNoInternet();
    if (internetError) {
      return Promise.reject({ ...internetError });
    }
    let authToken = UtilService.getInfoFromStorage('localStorage', constant.AUTH_TOKEN) //localStorage.getItem('authToken');
    const option = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": authToken ? "Bearer " + authToken : ''
      }
    };
    return axios.delete(url, option).then(function (response) {
      return response;
    });
  }



  deleteRequestWithBody(url, requestPayload) {
    let internetError = checkNoInternet();
    if (internetError) {
      return Promise.reject({ ...internetError });
    }
    let authToken = UtilService.getInfoFromStorage('localStorage', constant.AUTH_TOKEN) //localStorage.getItem('authToken');
    // const option = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": authToken ? "Bearer " + authToken : ''
    //   }
    // };
    //console.log("requestPayload Delete=" + JSON.stringify(requestPayload))
    return axios.delete(url, {
      headers: {
        Authorization: authToken ? "Bearer " + authToken : ''
      }, data: requestPayload
    }).then(function (response) {
      return response.data;
    });
  }

}


// use of Response interceptors for success and error 
axios.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)
const errorHandler = (error) => {
  //console.log('errorHandler=' + error)
  if (error.response) {
    const currentRoute = UtilService.getCurrentRouteName()    
    const authorizeRoute = ['/login', '/set-password', '/forgot-password', '/all-news']
    let isAuthorizeRoute = authorizeRoute.some(routeName => {
      return routeName === currentRoute
    })
    if(currentRoute.includes("/view-news") || currentRoute.includes("/all-news")|| currentRoute.includes("/club-table") ){
      isAuthorizeRoute = true;
    }
    //when login,setpassword,forgotpassword has occur 401 error then won't be redirect on login page
    let errMsg = error.response.data.message;
    if (!isAuthorizeRoute && errMsg.trim() !== "Invalid email id or password" && !errMsg.trim().includes("No User found with email")) {
      if (error.response.status === 401) {
        store.dispatch({ type: UNAUTHORIZED_USER })
        //console.log("----> API giving Error 401 for UNAUTHORIZED")
        $('#unAuthroizePopUp').modal('show')
      }
    }
  } else {
    toast.warn("We’re unable to connect to our services.")
  }
  return Promise.reject({ ...error })
}

const successHandler = (response) => {
  //console.log('successHandler=' + JSON.stringify(response.data))
  return response
}

const checkNoInternet = () => {
  let offline = !navigator.onLine;
  if (offline) {
    toast.warn("There is no internet connection at the moment, please check your network and try again.")
    return { response: { data: { message: "No Internet" } } };
  }
  return null;
}

const myApi = new API();
export default myApi;
